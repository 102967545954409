import React, { Component } from 'react';

class ZipIcon extends Component {
	render(){
		const fillA = { fill: "#fff" };
		const fillB = { fill: "#585858" };

		return (
			<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
				<g id="Layer_1" data-name="Layer 1">
					<path
						id="Shape_Copy_397-2"
						data-name="Shape Copy 397-2"
						style={fillA}
						d="M20.07,23.43H6.78a2.14,2.14,0,0,1-2.14-2.14V2.71A2.14,2.14,0,0,1,6.78.57h9.94L22.21,6V21.29A2.14,2.14,0,0,1,20.07,23.43Z"
					/>
					<path id="Shape_Copy_396" data-name="Shape Copy 396" style={fillB} d="M21.55,7H16.33V1.78Z" />
					<rect id="Rectangle_5_Copy_325" data-name="Rectangle 5 Copy 325" style={fillB} x="1.79" y="4.14" width="11.41" height="6.42" rx="2.14" />
					<path id="Shape" style={fillB} d="M12.89,16.18H14.4v.64H12.89Z" />
					<path id="Shape-2" style={fillB} d="M12.89,13.6H14.4v.64H12.89Z" />
					<path id="Shape-3" style={fillB} d="M12.89,11.45H14.4v.64H12.89Z" />
					<path id="Shape-4" style={fillB} d="M12,20.83c0-.68.75-1.7.75-2.72h1.51c0,1,.75,2,.75,2.72a1.43,1.43,0,0,1-1.5,1.36A1.44,1.44,0,0,1,12,20.83Z" />
					<g id="PDF-2" className="icon-text">
						<g>
							<path d="M4.81,8,6.1,6.44H4.84V6h2v.4L5.53,7.94H6.84v.48h-2Z" />
							<path d="M7.25,6h.53V8.42H7.25Z" />
							<path d="M8.26,6H9.37a.83.83,0,1,1,0,1.65H8.79v.8H8.26ZM9.31,7.14c.26,0,.44-.13.44-.35s-.18-.34-.44-.34H8.79v.69Z" />
						</g>
					</g>
				</g>
			</svg>
		);
	}
}
export default ZipIcon;
