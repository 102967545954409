import React, { Component } from 'react';
import "./ToolTip.scss";

class ToolTip extends Component {
	constructor(props) {
		super(props);
	}
	
	render() {
		let className = `${this.props.active ? "tooltip-container" : "tooltip-container-inactive"} ${this.props.extraClass ? " " + this.props.extraClass : ""}`
		let style;
		
		if (this.props.className) {
			className += " " + this.props.className;
		}
		if (this.props.heavyText) {
			// Makes the tooltip popup font heavy.
			style = {fontWeight: "600"};
		}
		if (this.props.primary) {
			// Makes the tooltip popup background colour the primary colour.
			className += " primary";
		}
		if (this.props.lighten) {
			// Makes the tooltip popup default background colour lighter.
			className += " lighten";
		}
		if (this.props.left) {
			className += " left-aligned";
		}
		if (this.props.leftCenter) {
			className += " left-center";
		}
		if (this.props.right) {
			className += " right-aligned";
		}
		if (this.props.rightCenter) {
			className += " right-center";
		}
		if (this.props.compact) {
			className += " compact";
		}
		if (this.props.wordWrap) {
			className += " word-wrap";
		}
		if (this.props.noWrap) {
			className += " no-wrap";
		}
		if (this.props.warning) {
			className += " warning-tooltip"
		} 
		if (this.props.disabled) {
			className += " tooltip-disabled"
		} 
		if (this.props.sidebarLink) {
			className += " sidebar-link"
		} 
		if (this.props.verified) {
			className += " verified-tt"
		} 
		this.props.tooltipPositionY ? style={bottom: 0} : style={}

		if (this.props.sidebarLinkRight) {
			className += " sidebar-link-right"
		}

		return (
			<div className={className} onClick={this.props.onClick} style={this.props.style}>
				{this.props.children}
				{this.props.active && <div style={style} className={`tooltip-popup${className ? ' ' + className : ''}`}>
					{this.props.message}
				</div> }
			</div>
		);
    }
}

export default ToolTip;