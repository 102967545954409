import React, {Component} from 'react';
import {withI18n} from "@threeskye/global";
import logo from './logo.png';

class MaintenanceMode extends Component {

    render() {
        const i = this.props.i18n.get;

        return <div id="master-container">
                <div id="header">
                    <div className="logo-container">
                        <img src={logo} alt="logo" className="logo" />
                    </div>
                </div>
                <div id="content" className="text-align-center">
                    <h1>{i("Maintenance_Mode")}</h1>
                    <p>{i("Maintenance_message")}:</p>
                    <p style={{marginTop: "25px", marginBottom: "25px"}}><em>{i(this.props.message)}</em></p>
                    <p><strong>{i("Maintenance_contact")}</strong></p>
                </div>
            </div>;
    }
}

export default withI18n(MaintenanceMode);