import React, { Component } from 'react';

class Modals extends Component {
    state = {
        modal: null,
        nothing: true
    }
    
    componentWillReceiveProps(props) {
        let { modals } = props;
        if (modals.active.length === 0) {
            this.setState({nothing: true});
            return;
		}

        import("../modals/" + modals.active[0].type.type)
            .then(module=>{
                this.setState({modal: module.default, nothing: false});
            });
    }

    render() {
        //This is breaking the rules - render is supposed to be pure(props,state)
        //BUT we need a render here to clear settings so that the replacement modal 
        //is instantiated, not a rerendering of the existing one.
        //There is probably a better way of doing this but would require more thought
        //and/or major rewrite so this will do for now
        if (this.props.modals.queued) {
            this.props.modals.actionQueue();
        }

        if (this.state.nothing || !(this.props.modals.active[0] && this.props.modals.active[0].data))
            return "";

        const Modal = this.state.modal;

        return Modal && <Modal initialisationData={this.props.modals.active[0].data} />
    }
}

export default Modals;