import { withThreeSkyeGlobal } from '@threeskye/global';
import LoadingComponent from '@threeskye/global/dist/LoadingComponent';
import React, { useEffect, useState } from 'react';
import { Redirect, withRouter } from 'react-router-dom';

const FedCredentialTransfer = props=>{
	const params = props.match.params;
	const { token } = params;
	const path = params[0];

	const remote = props.remote;
	const storage = props.storage;

	const [ redirect, setRedirect ] = useState(null);
 
	useEffect(()=>{

		//This will install creds
		remote.get("/direct/" + token + "/modules/crm/redirect").then(()=>{
			window.sessionStorage.setItem("3skye.loggedin", "true");
			storage.getOrFetch("/configuration")
				.then(config=> {
					console.log("Got config, re-writing history");
					window.history.replaceState(null, window.document.title, `/${config.defaultLocale}/${config.defaultApp}`);
					console.log("History rewritten to be ",`/${config.defaultLocale}/${config.defaultApp}`, window.location.href);
					console.log('got path', path);
					setRedirect(`/${config.defaultLocale}/${config.defaultApp}/${path}`)
				});
			})

	}, []);

	return redirect === null ? <LoadingComponent visible /> : <Redirect to={redirect} />
}

export default withRouter(withThreeSkyeGlobal(FedCredentialTransfer));