import _ from 'lodash';
import { addMonths, parseISO, isAfter, isBefore, isSameDay, isSameMonth, subDays } from "date-fns";
import AssetLogo from '../components/Orders/AssetLogo';

import { memoize, partialRight } from 'lodash';

/**
 * Custom memoize that uses a 1 minute TTL
 */
export const expiringMemo = partialRight(memoize, function memoResolver(...args) {
  const time = (new Date()).getMinutes();

  args.push({ time });

  const cacheKey = JSON.stringify(args);

  return cacheKey;
});


export function getAccountsList(storage) {
    const accountsKey = storage.get("accounts.list.key");
    const accounts = storage.get(accountsKey);
    const clientsKey = storage.get("clients.list.key");
    const clients = storage.get(clientsKey);

    if (accounts && clients)
        return Promise.resolve(accounts);

    const thePromise = storage.getOrFetch("/modules/crm/provider/provider-data")
        .then(data => {
            const list = data.accounts;
            const adviserMap = data.advisers;
            let clientMap = new Map();
            data.clients.forEach(client => {
                clientMap.set(client.uniqueId, client);
            });
            //put back everything that was sent by id
            list.forEach(acct => {
                //...advisers
                const adviserIds = acct.advisers;
                const advisers = [];
                acct.advisers = advisers;
                adviserIds.forEach(adv => {
                    advisers.push(adviserMap[adv]);
                });

                acct.associatedClients = [];
                ["holders", "authorisedPersons", "named"].forEach(field => {
                    const clientIds = acct[field];
                    const clients = []
                    acct[field] = clients;
                    clientIds.forEach(id => {
                        const client = clientMap.get(id);
                        clients.push(client);
                        acct.associatedClients.push(client);
                    });
                });
                acct.holdersList = (acct && acct.holders ? acct.holders : []).filter(c => !!c).map(client => client.name).filter(n => !!n).join(", ");
                acct.authorisedList = (acct && acct.authorisedPersons ? acct.authorisedPersons : []).filter(c => !!c).map(client => client.name).filter(n => !!n).join(", ");
                acct.namedList = (acct && acct.named ? acct.named : []).filter(c => !!c).map(client => client.name).filter(n => !!n).join(", ");
                if (acct.primary)
                    acct.primary = clientMap.get(acct.primary);
            });

            //Store it
            storage.put(accountsKey, list);
            storage.put(clientsKey, data.clients);
            return list;
        });

    //Also store the promise to avoid double calling
    storage.put(accountsKey, thePromise);
    //We must also store the clients promise or an asynchronous call will see null in test and re-query
    //TODO this is duplicated below and could be tidied up I think
    storage.put(clientsKey, thePromise.then(() => {
        return storage.get(clientsKey);
    }));
    return thePromise;

    // return storage.getOrFetch(storage.get("accounts.list.key"))
    // 	.then(list=>{

    // 		if (list.length === 0)
    // 			return list;

    // 		if (list[0].hasOwnProperty("associatedClients"))
    // 			return list;

    // 		list.forEach(account=>{
    // 			account.associatedClients = account.holders.concat(account.authorisedPersons).concat(account.named);
    // 			account.holdersList = account.holders.map(client=>client.name).join(", ");
    // 			account.authorisedList = account.authorisedPersons.map(client=>client.name).join(", ");
    // 			account.namedList = account.named.map(client=>client.name).join(", ");
    // 		});

    // 		return list;
    // 	})
}

//Side effect function - adds client to our stored list
export function addClientToCachedClientList(client, storage) {
    return getClientsList(storage).then(clients => {
        clients.push(client);
        return clients;
    }).then(() => getClientsList(storage).then(clients => console.log("After adding client:", clients)));
}

export function getClientsList(storage) {
    const clientsKey = storage.get("clients.list.key");
    const clients = storage.get(clientsKey);
    if (clients) {
        return Promise.resolve(clients);
    }

    return getAccountsList(storage)
        .then(() => {
            return storage.get(clientsKey);
        });
}

const getAccount = (number, storage) => {
    return getAccountsList(storage)
        .then(accounts => {
            return accounts.find(
                acct => acct.number === number
            );
        });
};

export const getAccountById = (id, storage) => {
    return getAccountsList(storage)
        .then(accounts => {
            //Deliberately == not ===.  Do not change
            return accounts.find(
                acct => acct.id == id				//eslint-disable-line eqeqeq
            );
        });
};

export const refreshAccount = (id, storage, remote) => {
    //Update summary
    return remote.get("/modules/crm/provider/accounts/" + id + "/summary")
        .then(account => {
            return getAccountById(id, storage)
                .then(existing => {
                    Object.assign(existing, account);
                    return existing;
                });
        });
}

const getClient = (email, storage) => {
    if (!email)
        return Promise.resolve(undefined);

    if (typeof email === "object")
        email = email.email;

    return getClientsList(storage)
        .then(clients => {
            return clients.find(
                client => client.email === email
            );
        });
};

export const refreshClient = (email, storage, remote) => {
    return getClient(email, storage)
        .then(existing => {
            //Update summary
            return remote.get("/modules/crm/provider/clients/" + existing.id + "/summary")
                .then(replacement => {
                    Object.assign(existing, replacement);
                    return existing;
                });
        });
}

export const watchClient = (email, storage, callback) => {
    return getClient(email, storage)
        .then(existing => {
            //Update summary
            return storage.watch("/modules/crm/provider/clients/" + existing.id + "/summary", callback)
        });
}

export const unwatchClient = (email, storage, callback) => {
    return getClient(email, storage)
        .then(existing => {
            //Update summary
            return storage.unwatch("/modules/crm/provider/clients/" + existing.id + "/summary", callback)
        });
}

const getClientById = (id, storage) => {
    return getClientsList(storage)
        .then(clients => {
            //Deliberately == not ===.  Do not change
            return clients.find(
                client => client.type === "PERSON" && client.id == id			//eslint-disable-line eqeqeq
            );
        });
};

export const refreshNonPersonClient = (id, storage, remote) => {
    //Update summary
    return remote.get("/modules/crm/provider/non-person-clients/" + id + "/summary")
        .then(replacement => {
            return getNonPersonClientById(id, storage)
                .then(existing => {
                    Object.assign(existing, replacement);
                    return existing;
                });
        });
}

const getNonPersonClientById = (id, storage) => {
    if (typeof id === "object")
        id = id.id;

    return getClientsList(storage)
        .then(clients => {
            //Deliberately == not ===.  Do not change
            return clients.find(
                client => client.type !== "PERSON" && client.id == id			//eslint-disable-line eqeqeq
            );
        });
};

//TODO i18n
const asDDMMYYYY = date => {
    if (!date)
        return "";
    return twoDigit(date.getDate()) + "/" + twoDigit(date.getMonth() + 1) + "/" + date.getFullYear();
}
const isoDate = date => {
    if (!date)
        return "";
    return date.getFullYear() + "-" + twoDigit(date.getMonth() + 1) + "-" + twoDigit(date.getDate());
}
const isoToDDMMYYYY = iso => {
    return iso.substring(8, 10) + "/" + iso.substring(5, 7) + "/" + iso.substring(0, 4);
}

const isoToHHMMSS = iso => {
    return iso.substring(11, 13) + ":" + iso.substring(14, 16) + ":" + iso.substring(17, 19);
}
const isoToHHMM = iso => {
    return iso.substring(11, 13) + ":" + iso.substring(14, 16);
}
const twoDigit = data => {
    data = "" + data;       //Force string
    if (data.length === 1)
        data = "0" + data;
    return data;
}

export const PDF_MIME_TYPE = "application/pdf";
export const PPTX_MIME_TYPE = "application/vnd.openxmlformats-officedocument.presentationml.presentation";
export const DOCX_MIME_TYPE = "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
export const PNG_MIME_TYPE = "image/png";
export const JPG_MIME_TYPE = "image/jpeg";


const getMimeTypeFromExtension = (name) => {
    if (name.lastIndexOf(".pdf") === name.length - 4) {
        return PDF_MIME_TYPE;
    } else if (name.lastIndexOf(".gif") === name.length - 4) {
        return "image/gif";
    } else if (name.lastIndexOf(".jpg") === name.length - 4 || name.lastIndexOf(".jpeg") === name.length - 5) {
        return "image/jpg";
    } else if (name.lastIndexOf(".png") === name.length - 4) {
        return "image/png";
    } else if (name.lastIndexOf(".docx") === name.length - 5) {
        return DOCX_MIME_TYPE;
    } else if (name.lastIndexOf(".pptx") === name.length - 5) {
        return PPTX_MIME_TYPE;
    }
}

const chars = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";

const randomId = (len) => {
    len = len !== undefined ? len : 8;
    let id = "";
    for (let i = 0; i < len; i++) {
        id += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    return id;
}

class SequentialIdProvider {
    constructor() {
        this.counters = {}
    }

    peekNext(type) {
        const key = type ? _.kebabCase(type) : "";
        var count = this.counters[key];
        if (count) {
            return (key ? key + "-" : "") + count + 1;
        }
        return key ? key + "-1" : "1";
    }

    next(type) {
        const key = type ? _.kebabCase(type) : "";
        if (this.counters[key]) {
            return (key ? key + "-" : "") + ++this.counters[key];
        } else {
            this.counters[key] = 1;
            return key ? key + "-1" : "1";
        }
    }
}

const ID_PROVIDER = new SequentialIdProvider();

const nextId = (type) => {
    return ID_PROVIDER.next(type);
};

const peekNextId = (type) => {
    return ID_PROVIDER.peekNext(type);
}

class KeyStore {
    constructor(keyLength) {
        this.keyLength = keyLength || 8;
        this.values = {};
        this.keys = {};
    }

    key(value, index, prop) {
        var path = prop === undefined ? [index] : _.toPath(prop).concat(index);
        var v = _.get(this.values, path);
        if (value === v && _.has(this.keys, path)) {
            return _.get(this.keys, path);
        } else {
            // the value is different we have to update the key and value
            var key = randomId(this.keyLength);
            _.set(this.values, path, value);
            _.set(this.keys, path, key);
            return key;
        }
    }

    clear() {
        this.values = {};
        this.keys = {};
    }
}

const keyStore = (keyLength) => {
    return new KeyStore(keyLength);
}

const getNotificationDescription = (notifications, i18n) => {
    if (!notifications)
        return "";
    let result = [];
    let nots = notifications.slice();
    notificationOptionsBefore.forEach(option => {
        let index = nots.indexOf(option.calc);
        if (index > -1) {
            result.push(i18n.get(option.name));
            nots.splice(index, 1);
        }
    });
    notificationOptionsAfter.forEach(option => {
        let index = nots.indexOf(option.calc);
        if (index > -1) {
            result.push(i18n.get(option.name));
            nots.splice(index, 1);
        }
    });
    return result.join(", ");
}
const notificationOptionsBefore = [
    {
        name: "None",
        calc: ""
    },
    {
        name: "OneWeekPrior",
        calc: "-7d"
    },
    {
        name: "OneDayPrior",
        calc: "-1d"
    },
    {
        name: "OnTheDay",
        calc: "0"
    }
];
const notificationOptionsAfter = [
    {
        name: "None",
        calc: ""
    },
    {
        name: "OnTheDay",
        calc: "0"
    },
    {
        name: "NextDay",
        calc: "+1d"
    },
    {
        name: "OneWeekLater",
        calc: "+7d"
    },
];

//TODO import from global 
const apiBase = "/api";

function getClientLink(client) {
    return "/clients/" + client.email;
}

export function getAccountLink(account) {
    return "/accounts/" + account.number;
}

export function getNonPersonLink(client) {
    return "/nonpersonal/" + client.id;
}


export function getClientIdLink(client) {
    return "/clients/" + client.id;
}

export function getAccountIdLink(account) {
    return "/accounts/" + account.id;
}

export const getNonPersonIdLink = getNonPersonLink;

const getPersonRelatedAccounts = (identifier, storage) => {
    return getClient(identifier, storage)
        .then(client => {
            const email = client.email;
            return getAccountsList(storage)
                .then(accounts => {
                    let my = [];
                    let authorised = [];
                    let named = [];

                    accounts.forEach(acct => {
                        if (acct.holders.findIndex(sec => sec.email === email) > -1) {
                            my.push(acct);
                        } else if (acct.authorisedPersons.findIndex(sec => sec.email === email) > -1) {
                            authorised.push(acct);
                        } else if (acct.named.findIndex(sec => sec.email === email) > -1) {
                            named.push(acct);
                        }
                    });

                    return [my, authorised, named];
                });
        });

}
const getNonPersonRelatedAccounts = (identifier, storage) => {
    return getNonPersonClientById(identifier, storage)
        .then(client => {
            const uniqueId = client.uniqueId;
            return getAccountsList(storage)
                .then(accounts => {
                    let my = [];
                    let authorised = [];
                    let named = [];

                    accounts.forEach(acct => {
                        if (acct.holders.findIndex(sec => sec.uniqueId === uniqueId) > -1) {
                            my.push(acct);
                        } else if (acct.authorisedPersons.findIndex(sec => sec.uniqueId === uniqueId) > -1) {
                            authorised.push(acct);
                        } else if (acct.named.findIndex(sec => sec.uniqueId === uniqueId) > -1) {
                            named.push(acct);
                        }
                    });

                    return [my, authorised, named];
                });
        });

}

export function headerAsId(header) {
    return header.toLowerCase().replace(/\s+/g, "-").replace(/[^a-zA-Z0-9-]/g, "");
}


export const getClassName = (classNameItems) => {
    return classNameItems.reduce((className, item) => (item.className && (!item.condition || item.condition()) ? (className ? `${className} ${item.className}` : item.className) : className), "");
}

export const removePlural = (type) => type.endsWith("s") ? type.substring(0, type.length - 1) : type;

export const breadCrumbPathsToPath = breadCrumbPaths => breadCrumbPaths.map(({ path }) => path + "/").join("");

export function isEmpty(value) {
    return value === null || value === undefined;
}

export function isEmptyString(value) {
    return value === null || value === undefined || value === "";
}

export function isBlank(value) {
    if (value) {
        if (_.isString(value)) {
            return value.length === 0 || value.trim().length === 0;
        } else if (value instanceof Object) {
            var values = Object.values(value);
            return values.length === 0 || values.every(isBlank);
        }
        return false;
    }
    return true;
}

export function isNotBlank(value) {
    return !isBlank(value);
}

export function capitalise(lower) {
    return lower.charAt(0).toUpperCase() + lower.substring(1);
}

export function uncapitalise(lower) {
    return lower.charAt(0).toLowerCase() + lower.substring(1);
}

export function displayCamelCase(camelCased) {
    return camelCased.replace(/([A-Z])/g, ' $1')
        // uppercase the first character
        .replace(/^./, function (str) { return str.toUpperCase(); })
}

export function displayAsTitleCase(str) {
		return str.split(' ')
        .map((word) => word[0].toUpperCase() + word.slice(1).toLowerCase())
        .join(' ');
}

export function nullSafeIsSame(oldVal, newVal) {
    return ((isEmpty(oldVal) || oldVal === "") && (isEmpty(newVal) || newVal === "")) || oldVal === newVal;
}

export function isTruthy(v) {
    return v ? true : false;
}

export function isFalsey(v) {
    return !v ? true : false;
}

export function isLooselyEqual(a, b) {
    return (!a && !b) || a == b || _.isEqual(a, b); //eslint-disable-line eqeqeq
}

// Returns an initail state object by setting the default values and overriding only existing properties in the default values object with the values provided.
export function getInitialState(defaultValues, values) {
    return Object.assign({}, defaultValues, _.pick(values, Object.keys(defaultValues)));
}

// Compares the values of props and state objects at the specified paths in the mappings and copies different values found from props and
// returns a new object that represents the derieved state.
export function diff(props, state, mappings) {
    var path, pv, sv, diff = {};
    for (var k in mappings) {
        path = mappings[k];
        pv = _.get(props, k);
        sv = _.get(state, path);
        if ((_.isFunction(pv) && pv !== sv) || !_.isEqual(pv, sv)) {
            _.set(diff, path, pv);
        }
    }
    return Object.keys(diff).length > 0 ? diff : null;
}

export function deepForEach(obj, callback) {
    return doDeepForEach(obj, callback, [], [obj]);
}

function doDeepForEach(obj, callback, nodePath, seen) {
    // to prevent endless traversal with circular objects, we do not traverse the same node more than once.
    var objs = [];
    for (var key in obj) {
        var value = obj[key];
        var propPath = nodePath.concat(key);
        callback.call(obj, value, key, obj, propPath.slice());
        if (value instanceof Object && seen.indexOf(value) < 0) {
            objs[objs.length] = value;
            doDeepForEach(value, callback, propPath, seen.concat(objs));
        }
    }
}

export const DATE_DISPLAY_FORMAT = "D MMMM YYYY";
export const DATE_SAVE_FORMAT = "YYYY-MM-DD";

export function isJSONArrayObject(json) {
    try {
        if (json === null) {
            return false;
        }
        return json.startsWith("[{") && typeof JSON.parse(json) === "object";
    } catch (e) {
        return false;
    }
}

export const getNextReview = (account) => {
    let { lastReview, reviewIntervalMonths } = account
    if (!lastReview) return null
    let prev = parseISO(lastReview)
    let next = addMonths(prev, reviewIntervalMonths || 3)
    return next
}

export const getNextAdviserReview = (account) => {
    let { lastAdviserReview, adviserIntervalMonths } = account
    if (!lastAdviserReview) return null
    let prev = parseISO(lastAdviserReview)
    let next = addMonths(prev, adviserIntervalMonths || 3)
    return next
}

export const isOverdue = (date) => {
    if (!date) return null
    let sixtyDaysAgo = subDays(new Date(), 60)
    if (isAfter(date, sixtyDaysAgo)) return false
    if (isSameDay(date, sixtyDaysAgo)) return false
    if (isBefore(date, sixtyDaysAgo)) return "Overdue"
}

export const isDueNow = (date) => {
    if (!date) return null
    let today = new Date()
    let sixtyDaysAgo = subDays(new Date(), 61)
    if (isAfter(date, today)) return false
    if (isSameDay(date, today)) return false
    if (isBefore(date, sixtyDaysAgo)) return false
    if (isBefore(date, today)) return "Due"
}

export const isDueThisMonth = (date) => {
    if (!date) return null
    let today = new Date()
    if (isSameDay(date, today)) return true
    else if (isBefore(date, today)) return false
    else if (isSameMonth(date, today)) return true
    else return false
}

export const isDueNextMonth = (date) => {
    if (!date) return null
    let today = new Date()
    let oneMonthFromToday = addMonths(today, 1)
    if (isSameMonth(date, oneMonthFromToday)) return true
    else return false
}

export const isMissingReview = (acc) => {
    if (acc && acc.managementBasis && acc.managementBasis.name === "Premium Portfolio" && !acc.lastReview) return true
    else return false
}

export const isMissingAdviserReview = (acc) => {
    if (acc && acc.managementBasis && acc.managementBasis.name === "Premium Portfolio" && !acc.lastAdviserReview) return true
    else return false
}

export const isMobile = () => {
    if (window.innerWidth < 768) return true
    else return false
}

export const personDisplayName = (person) => {
	if (!person) {
		return "Unknown person";
	}
	let first = person.givenName;
	if (person.preferredName) {
		if (/\S/.test(person.preferredName)) {
		// string is not empty and not just whitespace
			first = person.preferredName;
		}
	}
    if ((!first || !person.familyName) && person.name) {
        return person.name
    }
	return first + " " + person.familyName;
}

export const getAssetLogo = (order) => {
    if (order && order.logo === undefined) {
        return <><AssetLogo alt={order.ticker} size={20} />{order.ticker}</>;
    } else {
        return <><AssetLogo
            logo={order.logo}
            alt={order.ticker}
            size={20}
        />{order.ticker}</>
    }
}
export const statusOptions = [{ label: "Open", value: "OPEN", status: "OPEN", id: 0 }, { label: "InProgress", value: "IN_PROGRESS_MONITORING", status: "IN_PROGRESS", id: 1 }, { label: "Closed", value: "CLOSED", status: "CLOSED", id: 2 }]

export {
    apiBase, getClient, getClientLink, getAccount,
    getMimeTypeFromExtension,
    asDDMMYYYY, isoDate, isoToDDMMYYYY, isoToHHMMSS, isoToHHMM, randomId, nextId, peekNextId, keyStore, notificationOptionsBefore,
    notificationOptionsAfter, getNotificationDescription,
    getClientById, getNonPersonClientById, getNonPersonRelatedAccounts,
    getPersonRelatedAccounts
};
