import React, { useEffect } from 'react';
import ClientAccountLink from '../../../ClientAccount/ClientAccountLink';
import FlexWrapper from '../../../Shared/FlexWrapper';
import ExpandableNotification from './ExpandableNotification';
import NotificationMiniList, { MiniNotification } from './NotificationMiniList';
import ChangeRequestIcon from '../../../Shared/ChangeRequestIcon';
import "./NotificationList.scss";


export const ChangeNotification = ({ client, onDelete, data, onClick }) => {
    const state = data.text.includes("Change approved") ? "approved" : data.text.includes("Change rejected") ? "rejected" : "feedback"

    const getText = (str) => {
        const textArray = str.split(".")
        if (textArray.length > 1) {
            const subArray = textArray[0].split(":")
            return subArray[0] + ":" + subArray[1]
        } else return ""   
    }
    const pointer = ((data.modalParams && data.modalParams !== null ) || data.text && data.text.includes("Change for")) ? "pointer" : ""
    const icon = <ChangeRequestIcon state={state} cr={data.newText ? data.newText : getText(data.text)} nested />


    return (
        // <Tooltip label={text}>
        <MiniNotification onDelete={onDelete} data={data} onClick={onClick} pointer={pointer}>

            <FlexWrapper gap="s" fullWidth >
                {icon && <i className={"material-icons notification-type-icon change-min-Notification"}>{icon}</i>}
                {client ?
                    <ClientAccountLink size="small" clientAccount={client} />
                    : <span style={{ backgroundColor: "#3d4567", padding: 4, borderRadius: 4, fontSize: 12, opacity: 0.7 }}>Account Closed</span>
                }
            </FlexWrapper>
        </MiniNotification>
        /* </Tooltip>  */

    )
}

export const GroupedChangeNotifications = (props) => {
    const { notifications, timePeriod, onDelete, groupIcon, onClick } = props
    let triggeredDates = notifications.map(n => n.triggeredDate)
    let subTitleDate = triggeredDates.sort()[0]
    const title = notifications[0].text.includes("Change approved") 
    ? "Approved (" + notifications.length + ")" : notifications[0].text.includes("Change rejected") 
    ? "Rejected (" + notifications.length + ")" : notifications[0].text.includes("Change has first approval") 
    ? "1st approval complete (" + notifications.length + ")" : notifications[0].text.includes("Feedback requested") 
    ? "Feedback Requested (" + notifications.length + ")" : "Awaiting Approval (" + notifications.length + ")"

    useEffect(() => {
    }, [notifications])


    return (
        notifications && (
            <ExpandableNotification onDelete={onDelete} data={notifications} type="change" icon={groupIcon} title={title} expanded subtitle="" triggered={subTitleDate}>
                {/* List here... */}
                <NotificationMiniList >
                    {notifications.map((n, idx) => {
                        if (n.clientAccountToken) return (n && n.clientAccountToken && <ChangeNotification onClick={onClick} key={idx} client={n.clientAccountToken} triggered={n.triggeredDate} onDelete={onDelete} data={n} />)
                        else return (n && <ChangeNotification onClick={onClick} key={idx} triggered={n.triggeredDate} onDelete={onDelete} data={n} />)
                    })}
                </NotificationMiniList>
            </ExpandableNotification>)
    );
}

export default GroupedChangeNotifications;