import React, {Component} from 'react';
import { withI18n } from "@threeskye/global";
import './RecordAdviceChecklist.scss';
import Checkbox from '../../components/Shared/Checkbox';

class RecordAdviceChecklist extends Component{//not pure, children can change and cause updates
	render(){
		let i = this.props.i18n.get;
		let classNames = "record-advice-section";
		if (this.props.classNames) {
			classNames += " " + this.props.classNames;
		}

		if(this.props.recordAsAdviceSteps==null)
			return <div className="record-advice-section">
				<div className="record-advice-header">
					<div className="record-advice-header-warning">
						<i className="material-icons warning">warning</i>
						<p>{i("RecordOfAdvice")}</p>
					</div>
					<i className="material-icons" onClick={this.props.onCloseClick}>close</i>
				</div>
				<hr />
				<ul className="record-advice-body">{i("WarningMessageRecordAdviceMissing")}</ul>
			</div> 
		return <div className={classNames}>
			<div className="record-advice-header">
				<p>{i("RecordOfAdvice")}</p>
				<i className="material-icons" onClick={this.props.onCloseClick}>close</i>
			</div>
			{/* <div className="record-advice-subheader">
				Please ensure you have completed the following.
			</div> */}
			<hr />
			<ul className="record-advice-body">
				{this.props.recordAsAdviceSteps.map((advice,index)=>{
					return (this.props.isChecklist)?
						<label key={index} className="record-advice-list checkbox-field" htmlFor={"advice-index-"+index}>
							<input type="checkbox" id={"advice-index-"+index}/> {advice}							
						</label>
						:<li key={index} className="record-advice-list">{advice}</li>
					})}
			</ul>
			<hr />
			<div className="record-advice-footer">
				<Checkbox name="adviceCheckConfirmed" checked={this.props.checked} label={i("RecordOfAdviceConfirmation")} onChange={this.props.onChange} />
			</div>
		</div>
	}
}
export default withI18n(RecordAdviceChecklist);