/**
 * Returns true if the email testes a regular expression
 */
export function testEmail(email){
	return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/
		.test(email.trim().toUpperCase());
}
/**
 * Returns true if the number fits into the provided number format
 * 
 * From wikipedia
 * 
 * New Zealand landline phone numbers have a total of eight digits excluding the leading 0
 * 
 * a one-digit area code,
 * there are five regional area codes, 3, 4, 6, 7, and 9, that must be on the front of the number
 * these are sometimes noted as 0N (eg. 03, 04 etc.)
 * 
 * a seven digit phone number, beginning with a digit between 2 and 9
 * 
 * telephone numbers for mobile phones begin with 02, followed by seven to nine digits
 * 
 */
export function testNZNumber(number){
	return /^\+64((0?[2-9][0-9]{7})|(02[0-9]{7,9}))$/
		.test(number);
}
export function testInternationalNumber(number){
	return /^\+[0-9]{9,12}$/
		.test(number);
}