import React from 'react';
import TooltipIcon from '../../Shared/TooltipIcon';
import "./ExtensionActionIcon.scss";

const ExtensionActionIcon = ({active, icon, onClick, tooltip, tooltipDisabled, className}) => {
	return (
		<TooltipIcon delay={500} disabled={tooltipDisabled} tooltip={tooltip} onClick={onClick} className={className ? ' ' + className : ''} iconClassName={`sbe-icon${active ? " icon-active" : ""}`} icon={icon} />
   );
}

export const MaximiseIcon  = (props) => {
	return (
		<ExtensionActionIcon {...props}  icon="aspect_ratio" tooltip="Expand Sidebar" className="d-none-mobile" />
   );
}

export const MinimiseIcon  = (props) => {
	return (
		<ExtensionActionIcon {...props} icon="remove" tooltip="Minimise" />
   );
}

export const NewEventIcon  = (props) => {
	return (
		<ExtensionActionIcon {...props} icon="add" tooltip="New Event" />
   );
}

export const DeleteIcon  = (props) => {
	return (
		<ExtensionActionIcon {...props} icon="delete_forever" tooltip="Delete" />
   );
}

export const CloseIcon  = (props) => {
	return (
		<ExtensionActionIcon {...props} icon="close" tooltipDisabled />
   );
}

export default ExtensionActionIcon;