import React, { Component } from 'react';
import "./Icons.scss";

class FileIcon extends Component {
	render(){
		const fillA = { fill: "#6B7FB2" };
		const fillB = { fill: "#fff", opacity: "0.4" };

		return (
			<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
				<g id="Layer_1" data-name="Layer 1">
					<path
						id="Shape_Copy_33"
						data-name="Shape Copy 33"
						style={fillA}
						d="M18.64,23.43H5.35a2.14,2.14,0,0,1-2.14-2.14V2.71A2.14,2.14,0,0,1,5.35.57h9.94L20.79,6V21.29A2.14,2.14,0,0,1,18.64,23.43Z"
					/>
					<path id="Shape_Copy_28" data-name="Shape Copy 28" style={fillB} d="M20,6.6H14.82V1.43Z" />
					<path id="_" data-name=" " style={fillB} d="M14.74,15.63H7.51V14h7.23v1.61Zm2.42-3.23H7.51V10.81h9.65V12.4Z" />
				</g>
			</svg>
		);
	}
}
export default FileIcon;
