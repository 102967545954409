import React, {Component} from 'react';
import './TimeField.scss';

class TimeField extends Component {

    delays = [400, 400, 300, 300, 300, 200, 200, 200, 200];

    constructor(props) {
        super(props);

        this.state = {
            time: ""
         }

        this.currentBackspace = false;
        this.startPositiveIncrement = this.startPositiveIncrement.bind(this);
        this.startNegativeIncrement = this.startNegativeIncrement.bind(this);
        this.stopIncrement = this.stopIncrement.bind(this);
        this.increment = this.increment.bind(this);
    }

    componentWillReceiveProps(props) {
        if (props.isNow) {
            let date = new Date();

            let hours = "" + date.getHours();
            if (hours.length === 1) {
                hours = "0" + hours;
            }

            let mins = "" + date.getMinutes();
            if (mins.length === 1) {
                mins = "0" + mins;
            }
            this.setState({
                time: hours + ":" + mins
            })
        }
        if(this.state.time === "" && props.time) {
            this.setState({
                time: props.time
            })
        }
    }

    onKeyDown(e) {
        //onChange needs to know if we're deleting
        if (e.keyCode === 8) {
            this.currentBackspace = true;
        } else {
            this.currentBackspace = false;
        }
    }

    update(e) {
        let value = e.target.value;
        if (this.currentBackspace) {
            let current = this.state.time;
            if (current.lastIndexOf(":") === current.length-1 && value === current.substring(0, current.length-1)) {
                value = value.substring(0, value.length-1);
            }
        } else {
            if (value.match(/^\d\d$/))
                value += ":";
            else if (!value.match(/^\d\d:\d{0,2}$/) && !value.match(/^\d$/)) {
                if (this.props.onChange)
                    this.props.onChange(null);
                return;
            }
        }
        if (this.props.onChange)
            this.props.onChange(value);
        this.setState({time: value});        
    }

    startPositiveIncrement() {
        let nums = this.deriveNums(this.state.time);

        let div = nums[1] % 5;

        this.increment(5-div, 0);
    }

    startNegativeIncrement() {
        let nums = this.deriveNums(this.state.time);

        let div = nums[1] % 5;

        this.increment(div === 0 ? -5 : -div, 0);
    }

    increment(by, count) {
        let nums = this.deriveNums(this.state.time);

        nums[1] = nums[1] + by;

        if (nums[1] >= 60) {
            nums[1] = 0;
            nums[0]++;
        } else if (nums[1] < 0) {
            nums[1] = 60 + by;
            nums[0]--;
        }

        if (nums[0] >= 24) {
            nums[0] = 0;
        } else if (nums[0] < 0) {
            nums[0] = 23;
        }

        let time = "";
        if (nums[0] < 10)
            time += "0";
        time += nums[0] + ":";
        if (nums[1] < 10) 
            time += "0";
        time += nums[1];

        this.setState({time});

        let interval = count > this.delays.length ? 100 : this.delays[count];
        let newBy = by > 0 ? 5 : -5;

        this.timeout = setTimeout(()=>this.increment(newBy, ++count), interval);
    }

    stopIncrement() {
        clearTimeout(this.timeout);

        let value = this.state.time;
        if (this.props.onChange)
            if (!value.match(/^\d\d:\d{0,2}$/) && !value.match(/^\d$/)) {
                this.props.onChange(null);
            } else {
                this.props.onChange(value);
            }

    }

    deriveNums(time) {
        if (time.match(/^\d\d:\d\d$/)) {
            return [parseInt(time.substring(0, 2)), parseInt(time.substring(3,5))];
        } else {
            let now = new Date();
            return [now.getHours(), now.getMinutes()];
        }
    }

    render() {
        return <div className="time-field-outer">
            <i className="material-icons">schedule</i>
            <input 
                className="time-field-field" 
                value={this.state.time} 
                onChange={this.update.bind(this)}
                onKeyDown={this.onKeyDown.bind(this)}
                placeholder="00:00"
            />
            <div className="time-arrows-container">
                <i className="material-icons time-arrow-up" onMouseDown={this.startPositiveIncrement} onMouseUp={this.stopIncrement}>arrow_drop_up</i>
                <i className="material-icons time-arrow-down" onMouseDown={this.startNegativeIncrement} onMouseUp={this.stopIncrement}>arrow_drop_down</i>
            </div>
       </div>;
    }
}

export default TimeField;