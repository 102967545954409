import React from "react";
import Tooltip from "../Popups/Tooltip";
import { withI18n } from '@threeskye/global'

const TooltipIcon = ({ icon, tooltip, i18n, iconClassName, onClick, disabled, delay, className }) => {

	let i = i18n.get

	return (
		<div style={{ cursor: "pointer", display: "flex", alignItems: "center" }} onClick={onClick} className={className ? ' ' + className : ''}>
			<Tooltip delay={delay} disabled={disabled} label={tooltip} >
				<i className={`material-icons ${iconClassName ? iconClassName : ""}`} style={{ fontSize: 18 }}>
					{icon}
				</i>
			</Tooltip>
		</div>
	);

}

export default withI18n(TooltipIcon);