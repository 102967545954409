import React, { useEffect, useState, useRef } from 'react';
import logo from './3S.png';
import Tooltip from '../Popups/Tooltip';
import "./AdviserToken.scss";

export const AdviserInfo = ({ phone, email, location }) => {
	// Onclick wiring required
	const items = [
		{ value: phone, icon: 'phone', className: 'info-phone', onClick: () => window.location.href = "tel:+" + phone },
		{ value: email, icon: 'email', className: 'info-email', onClick: () => window.location.href = "mailto:" + email },
		{ value: location, icon: 'place', className: 'info-location', onClick: '' },
	];

	return (
		<div className='adviser-info'>
			{items && items.map((item, idx) => {
				return item.value && (
					<div key={idx} className={`adviser-info-item ${item.className}`} onClick={item.onClick}>
						<i className="material-icons">{item.icon}</i>
						{item.value}
					</div>
				);
			})}
		</div>
	)
}

const AdviserToken = ({ name, onClick, phone, email, location, showInfo, icon, ellipse, nameMaxWidth, small }) => {
	//token on ellipsed text 
	const [isOverflown, setIsOverflown] = useState(false);
	const textElementRef = useRef();

	const compareSize = () => {
		const compare = textElementRef.current.scrollWidth > textElementRef.current.clientWidth;
		setIsOverflown(compare);
	};

	useEffect(() => {
		compareSize();
		textElementRef.current.addEventListener('mouseover', compareSize());
		window.addEventListener('mouseover', compareSize);
	}, [name, isOverflown, textElementRef]);

	useEffect(() => () => {
		textElementRef.current.removeEventListener('mouseover', compareSize());
		window.removeEventListener('mouseover', compareSize);
	}, [name, isOverflown, textElementRef]);

	return (
		// <Tooltip disabled={!showInfo} delay={500} label={<AdviserInfo phone={phone} email={email} location={location} />} popupElementClass="adviser-info-tooltip" padding="xs">
		<div className={`adviser-token-wrapper${ellipse ? ' ellipse-token' : ''}`} onClick={onClick}>
			<div className="token-logo" style={{width: 24}}>
				<img src={logo} alt="logo" />
			</div>
			<div className={`token-name-value ${small ? "small" : ""}`}>
				<Tooltip placement="top" offsetY={8} label={name} disabled={!isOverflown}>
					<span style={nameMaxWidth ? {maxWidth: nameMaxWidth} : null} ref={textElementRef}>{name}</span>
				</Tooltip>
				{icon && <div style={{width: "1rem", marginLeft: "0.5rem", display: "flex", justifyContent: "center"}}> {icon} </div>}
			</div>
		</div>
		// </Tooltip>
	);
}

export default AdviserToken;