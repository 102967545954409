import React, { Component } from 'react'
import AsyncCreatableSelect from 'react-select/lib/AsyncCreatable';
import { components } from 'react-select';
import "./TokenField.scss"
import SelectClearIcon from './SelectClearIcon';
import ClientAccountToken, { ClientAccountTokenSizes } from '../ClientAccount/ClientAccountToken';
import ToolTip from '../Popups/Tooltip'

class CreatableClientOrAccountTokenField extends Component {

	//For this version, we are going directly for a component that handles both
	//It will also handle the suggestion list, if applicable
	constructor(props) {
		super(props)
		this.state = {
			isEmpty: true
		}
		this.handleInputChange = this.handleInputChange.bind(this);
		this.onChange = this.onChange.bind(this);
		this.findMatches = this.findMatches.bind(this);
		this.optionLabel = this.optionLabel.bind(this);
		this.renderSelect = this.renderSelect.bind(this);
		this.renderSuggestions = this.renderSuggestions.bind(this);
		this.checkForUniqueSuggestions = this.checkForUniqueSuggestions.bind(this)
		this.handleCreateOption = this.handleCreateOption.bind(this)

		this.lastFoundMatches = [];
		this.lastFoundSearchCounter = 0;
	}

	isValidNewOption(input, select, options) {
		if (input.includes("@") && options.length === 0) {
			return true;
		}
		return false;
	}

	createSuggestion(text, label) {
		let email = {
			autoFlowOn: 0,
			automatic: 0,
			manual: true,
			searchCounter: 6,
			listObjectType: "email",
			data: {
				email: text
			}
		}
		return email;
		// return {
		// 	text: text,
		// };
	}

	//Overwriting this behavior, keeps the menu closed when the user has erased text
	//The default behavior of this will leave all of the options visible if a user does this
	handleInputChange(input, actionInfo) {
		// string, action required One of "set-value","input-change","input-blur","menu-close"
		let isEmpty = input == null || input.length < 1;
		this.setState({ isEmpty });
	}
	//Due to an issue with updates conflicting with each other
	//we need to detect which of the lists was changed.
	onChange(value, { action, removedValue }) {
		let clients = [];
		let accounts = [];
		let emails = []

		value.forEach(item => {
			if (item.listObjectType === "client") {
				clients.push(item);
			} else if (item.listObjectType === "email") {
				emails.push(item);
			} else {
				accounts.push(item);
			}
		})
		switch (action) {
			case 'remove-value':
			case 'pop-value':
				if (removedValue) {
					if (!this.props.isRemovable(removedValue)) {
						return;
					}
					if (removedValue.listObjectType === "client")
						this.props.onClientUpdate(clients, removedValue);
					else if (removedValue.listObjectType === "email")
						this.props.onEmailUpdate(emails, removedValue);
					else
						this.props.onAccountUpdate(accounts, removedValue);
				}
				break;
			default:
				//At this step we need to check which list needs updating
				//final index is always what we want
				if (value[value.length - 1].listObjectType === "client")
					this.props.onClientUpdate(clients, null);
				else if (value[value.length - 1].listObjectType === "email")
					this.props.onEmailUpdate(emails, null);
				else
					this.props.onAccountUpdate(accounts, null);
		}

	}

	handleCreateOption(value, values) {
		let email = {
			autoFlowOn: 0,
			automatic: 0,
			manual: true,
			searchCounter: 6,
			listObjectType: "email",
			data: {
				email: value.inputValue
			}
		}

		this.onChange([...values, email], { action: "create-option" })
	}

	//Look for matches in both
	findMatches(input) {
		return Promise.all([
			Promise.resolve(this.props.matchClients(input)),
			Promise.resolve(this.props.matchAccounts(input))
		]).then(values => {
			let accounts = this.props.accountsWithEmailsOnly
				? values[1].filter(acc => acc.data.primary && acc.data.primary.email && acc.data.primary.email.includes("@"))
				: values[1]
			let clients = this.props.accountsWithEmailsOnly
				? values[0].filter(cli => {return cli.data && (cli.data.email || cli.data.contactEmail) && ((cli.data.email && cli.data.email.includes("@")) || (cli.data.contactEmail && cli.data.contactEmail.includes("@")))}   )
				: values[0]
				
			let merge = [];
			for (let i = 0; i < clients.length; i++) {
				merge.push(Object.assign({
					listObjectType: "client"
				}, clients[i]))
			}
			if (!this.props.isEmailTemplate) {
				for (let i = 0; i < accounts.length; i++) {
					merge.push(Object.assign({
						listObjectType: "account"
					}, accounts[i]))
				}
			}
			if (merge.length > 0 && merge[0].searchCounter && merge[0].searchCounter > this.lastFoundSearchCounter) {
				this.lastFoundSearchCounter = merge[0].searchCounter;
				this.lastFoundMatches = merge;
			} else if (merge.length > 0 && merge[0].searchCounter && merge[0].searchCounter < this.lastFoundSearchCounter) {
				return this.lastFoundMatches;
			}
			return merge;
		});
	}

	optionLabel(option) {
		if (option.listObjectType === "client")
			return this.props.displayClient(option);
		if (option.listObjectType === "email")
			return "Send email to " + option.data.email
		if (option.listObjectType === 'account')
			return this.props.displayAccount(option);
		return 'Unknown option ' + option.listObjectType
	}
	getOptionValue(option) {
		if (option.listObjectType)
			return option.listObjectType.concat(option?.data?.email).concat(option?.data?.id)
		return JSON.stringify(option);
	}

	render() {
		//split out the suggestions from the core

		return (
			<div className="token-field-container client-and-account-tag-field">
				{this.renderSelect()}
				{this.renderSuggestions()}
			</div>
		)
	}
	checkForUniqueSuggestions(arr, target) {
		return target.every(v => arr.includes(v))
	};
	renderSuggestions() {
		const { accountSuggestions, clientSuggestions, accountList, clientList } = this.props;
		if ((accountSuggestions && accountSuggestions.length > 0) || (clientSuggestions && clientSuggestions.length > 0)) {

			let checkAccounts = this.checkForUniqueSuggestions(accountSuggestions, accountList)
			let checkClients = this.checkForUniqueSuggestions(clientSuggestions, clientList)
			if (checkAccounts || checkClients) {
				return (
					<div className="token-field-suggestions">
						<div className="suggestion-label-container">
							<span className="suggestion-label">Suggestions: </span>
						</div>

						{accountSuggestions.map((suggest, index) => {

							if (accountList.some(e => e.data.id === suggest.data.id)) return null
							return (
								<ToolTip popupElementClass='token-field-suggestions-popup' label={<><span className="account-type" >{suggest.data.accountType.name + " "} </span><span >{suggest.data.number}</span></>}>
									<ClientAccountToken margin="small" onClick={() => this.props.acceptSuggestion(index, "account")} key={index} clientAccount={suggest.data} size={ClientAccountTokenSizes.SMALL} />
								</ToolTip>
							)
						})}
						{clientSuggestions.map((suggest, index) => {
							if (clientList.some(e => e.data.id === suggest.data.id)) return null
							return (
								<ToolTip popupElementClass='token-field-suggestions-popup' label={suggest.data.userId}>
									<ClientAccountToken margin="small" onClick={() => this.props.acceptSuggestion(index, "client")} key={index} clientAccount={suggest.data} size={ClientAccountTokenSizes.SMALL} />
								</ToolTip>
							)
						})}
					</div>
				)
			}
		}
		else return null;
	}
	renderSelect() {
		let values = [];
		for (let i = 0; i < this.props.accountList.length; i++) {
			values.push(Object.assign({
				listObjectType: "account"
			}, this.props.accountList[i]));
		}
		for (let i = 0; i < this.props.clientList.length; i++) {
			values.push(Object.assign({
				listObjectType: "client"
			}, this.props.clientList[i]))
		}
		for (let i = 0; i < this.props.emailList.length; i++) {
			values.push(Object.assign({
				listObjectType: "email"
			}, this.props.emailList[i]))
		}
		let styles = {
			multiValue: base => { return { ...base, margin: "1px 1px" } },
			placeholder: base => { return { ...base, marginLeft: "0.5em" } },//When removing the margin from the input, add it back to the placeholder
			input: base => { return { ...base, paddingBottom: 0, paddingTop: 0, margin: 0 } },//TODO CM the internal input field uses this for styling
			multiValueRemove: (base, state) => { return this.props.isRemovable(state.data) ? base : { ...base, display: "none" } }
		}

		return (
			<AsyncCreatableSelect
				{...this.props}
				//fixed values
				isMulti
				cacheOptions
				isClearable={false}
				isValidNewOption={this.isValidNewOption}
				getNewOptionData={this.createSuggestion}
				className="token-field cat-token-field"
				classNamePrefix="token-field"
				placeholder={this.props.placeholder ? this.props.placeholder : "Tag Account or Client"}
				noOptionsMessage={() => "No matches found"}
				//menu control
				menuIsOpen={!this.state.isEmpty}
				openMenuOnClick={false}
				//bound methods
				onInputChange={this.handleInputChange}
				onChange={this.onChange}
				loadOptions={this.findMatches}
				getOptionLabel={this.optionLabel}
				//This MUST stay. used to compare the value currently selected to a new input
				getOptionValue={this.getOptionValue}
				//prop methods/values
				value={values}
				formatCreateLabel={(inputValue) => "Use " + inputValue}
				//component overrides
				styles={styles}
				// onCreateOption={this.handleCreateOption}
				components={
					{
						DropdownIndicator: () => null,
						IndicatorSeparator: () => null,
						// we will need custom rendering for the components
						MultiValueContainer: (innerprops) => {
							return (
								<>
									<div ref={innerprops.innerRef} style={{ minWidth: 0 }}>
										<components.MultiValueContainer {...innerprops} />
									</div>
								</>
							);
						},
						MultiValueLabel: (innerprops) => {
							return innerprops.data.listObjectType === "email"
								? (<div className="token-no-icon"><p>{innerprops.data.data.email}</p></div>)
								: (<div style={{ minWidth: 0 }}>
									<ClientAccountToken clientAccount={innerprops.data.data} size={ClientAccountTokenSizes.SMALL} />
								</div>
								);
						},
						MultiValueRemove: (innerprops) => {
							return <components.MultiValueRemove {...innerprops}><SelectClearIcon /></components.MultiValueRemove>
						},
					}
				}

			/>
		);
	}
}
export default CreatableClientOrAccountTokenField;
