import React, {Component} from 'react';
import {withI18n, withRemote, withStorage} from "@threeskye/global";
import Button from "@threeskye/buttons";
import { testInternationalNumber, testEmail } from './ValidationUtils';
import './ForgotPasswordPage.scss';
import './LoginForm.scss';

import logo from './logo.png';
import CRMErrorMessage from '../Shared/Validation/CRMErrorMessage';

class LoginForm extends Component {

	constructor(props) {
		super(props);

		this.state = {
			username: "",
			cellRecoveryNumber: "",
			invalidCell: false,
			invalidUserName: false
		}

		this.onChangeUsername = this.onChangeUsername.bind(this);
		this.resetByEmail = this.resetByEmail.bind(this);
		this.handleKeyPress = this.handleKeyPress.bind(this);
		this.onAccountRecovery = this.onAccountRecovery.bind(this);
		this.onChangeCellRecovery = this.onChangeCellRecovery.bind(this);
	}


	onChangeCellRecovery(val) {
		this.setState({cellRecoveryNumber: val});
	}

	onChangeUsername(val) {
		this.setState({username: val});
	}

	resetByEmail() {
	  this.props.switchPage("password-reset-email/");
	}
	
	goBackHome() {
		this.props.switchPage("");
	}

	handleKeyPress(e, field) {
	  if (e.key === 'Enter') {
		e.preventDefault();
		if (field === 'loginUsername') {
		  this.refs.loginPassword.focus();
		} else if (field === 'loginPassword') {
		  if (this.state.username.length > 0 && this.state.password.length > 0) {
			this.onLogin();
		  } else if (this.state.username.length === 0) {
			this.refs.loginUsername.focus();
		  }
		} else if (field === 'forgottenUsername') {
		  this.refs.forgottenCellNumber.focus();
		} else if (field === 'forgottenCellNumber') {
		  if (this.state.username.length > 0 && this.state.cellRecoveryNumber.length > 0) {
			this.onAccountRecovery();
		  } else if (this.state.username.length === 0) {
			this.refs.forgottenUsername.focus();
		  }
		}
	  }
	}

	onAccountRecovery() {
		if(this.state.username === '' || !testEmail(this.state.username)){
			this.setState({invalidUserName:true})
			return
		}else{this.setState({invalidUserName:false})}
	if (
		  this.state.cellRecoveryNumber === '' || !testInternationalNumber(this.state.cellRecoveryNumber)//less strict than testNZ
		//   !this.state.cellRecoveryNumber.startsWith("+")) //this needed a seconds step
		 ){
		this.setState({invalidCell:true});
		return;
	  } else {
		this.setState({invalidCell:false});
	  }
	  const data = {
		username: this.state.username,
			phone: this.state.cellRecoveryNumber
		  }
			this.props.storage.put("username", this.state.username);

		 this.props.remote.post("/um/password-recovery-code", data)
		.then(
		  x => {
			this.props.storage.put("password_change_key", x.key);

			this.props.switchPage("password-code/");
		  },
		  x => {console.log("Something went wrong that should not!")}
		);
	}

	render() {
		const i = this.props.i18n.get;
		const { invalidCell, invalidUserName } = this.state;

		return <div id="master-container">
			<div id="header">
				<div className="logo-container">
					<img src={logo} alt="logo" className="logo" />
				</div>
			</div>
			<div id="content">
				<div id="welcome-message" className="content-wrapper">
					<h1 className="mb-1">Reset password</h1>
					<p>Enter the email address and phone number associated with your account and we&rsquo;ll send you a validation code.</p>
				</div>
				<div className="form modal-form">
					<div className="form-row">
						<div className="form-col-grow">
							<CRMErrorMessage isValid={!invalidUserName} shouldShow message="Please enter a valid email address">
								<label htmlFor="user-name">Email Address</label>
								<input ref="forgottenUsername" name="user-name" type="text" placeholder={i("Username")} className="input-field" required onKeyPress={e => this.handleKeyPress(e, "forgottenUsername")} onChange={e => this.onChangeUsername(e.target.value)} />
							</CRMErrorMessage>
						</div>
					</div>
					<div className="form-row">
						<div className="form-col-grow">
							<CRMErrorMessage isValid={!invalidCell} shouldShow message="Please enter a valid phone number with no spaces (Must include country code).">
								<label htmlFor="phone-number">Mobile Number</label>
								<input name="phone-number" ref="forgottenCellNumber" type="text" placeholder={"+64...  "} value={this.state.cellRecoveryNumber} className="input-field" required onKeyPress={e=>this.handleKeyPress(e, "forgottenCellNumber")} onChange={e=>this.onChangeCellRecovery(e.target.value)} />
							</CRMErrorMessage>
						</div>
					</div>
					<div className="form-row mt-3">
						<div className="form-col-grow">
							<Button fullWidth type="secondary" outlined icon="arrow_back" onClick={e=>this.goBackHome()}>Back to login</Button>
						</div>
						<div className="form-col-grow">
							<Button fullWidth onClick={this.onAccountRecovery} icon="sms">Send Code</Button>
						</div>
					</div>
					<div className="form-row">
						<div className="form-col-grow">
							<div onClick={e=>this.resetByEmail()} className="pseudo-link">Reset password via email</div>
						</div>
					</div>
				</div>
			</div>
		</div>;

	}
}

export default withStorage(withRemote(withI18n(LoginForm)));
