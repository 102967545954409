import React, {Component} from 'react';
import {withI18n} from "@threeskye/global";
import logo from './logo.png';
import './UnauthorisedPage.scss';

class UnauthorisedPage extends Component {

    render() {
        const i = this.props.i18n.get;

        return (
        <div id="master-container">
            <div id="header">
                <div className="logo-container">
                    <img src={logo} alt="logo" className="logo" />
                </div>
            </div>
            <div id="content" className="text-align-center">
                <h1>{i("Unauthorised")}</h1>
                <a className='in-page-link' href="mailto: info@3skye.com">
                    <p>{i("PleaseContact3SKYEEmail")}<span className='visible-link'>{i("3SKYEContactEmail")}</span></p>
                </a>
            </div>
        </div>
        );
    }
}

export default withI18n(UnauthorisedPage);