import { withThreeSkyeGlobal } from "@threeskye/global";
import React, { useEffect, useRef, useState } from "react";
import SummaryValue from "../Shared/SummaryValue";
import ToolTip from "../Popups/Tooltip";
import "./ClientAccountToken.scss";
import ClientAccountTypes, { getClientAccountType } from "./getClientAccountType";
import { withData } from '../../crm/ThreeSkyeCRM';
import StatusTag from "../Shared/StatusTag";
import { format, add, isBefore } from "date-fns";
import Tooltip from "../Popups/Tooltip";
import { personDisplayName } from "../../utils/Utils";


export const ClientAccountTokenSizes = { SMALL: "small", MEDIUM: "medium", LARGE: "large" };
const iconNames = { [ClientAccountTypes.ACCOUNT]: "account_balance", [ClientAccountTypes.CLIENT]: "person", [ClientAccountTypes.NON_PERSON]: "business" };
const ClientAccountToken = ({ customIcons, removable, showLocationLink, size, onClick, clientAccount, subLabel, customIcon, isPrimary, margin, i18n, getClientById, getNonPersonClientById, storage, adviserGroupToken, deleteWorkingGroupAccount, bookmarkClassNames, stylingId, accountNumber, tdWidth, stopClickEfect }) => {

	// ensure you update ClientAccountLink if you add props to this component

	const [isOverflown, setIsOverflown] = useState(false);
	const textElementRef = useRef();

	const compareSize = () => {
		const element = textElementRef.current;
		if (!element) {
			return;
		}
		const compare = element.scrollWidth > element.clientWidth;
		setIsOverflown(compare);
	};

	// compare once and add resize listener
	useEffect(() => {
		compareSize();
		textElementRef.current && textElementRef.current.addEventListener('mouseover', compareSize());
		window.addEventListener('mouseover', compareSize);
	}, [clientAccount, isOverflown, textElementRef]);

	// remove resize listener
	useEffect(() => () => {
		textElementRef.current && textElementRef.current.removeEventListener('mouseover', compareSize());
		window.removeEventListener('mouseover', compareSize);
	}, [clientAccount, isOverflown, textElementRef]);


	const onEmailClick = (e) => {
		e.stopPropagation();
		window.location.href = `mailto:${clientAccount.contactEmail}`;
	}

	const onMobileClick = (e) => {
		e.stopPropagation();
		window.location.href = `tel:${getContactPhone()}`;
	}

	const getLocationLink = () => {
		if (!clientAccount || !clientAccount.addresses || clientAccount.addresses.length < 1) {
			return false;
		}
		let physicals = clientAccount.addresses.filter(a => a.name === "Physical")
		let primary = physicals.find((address) => address.primary)
		if (primary && primary.content && primary.content.fullAddress) return primary.content.fullAddress
		let first = physicals.find(a => a.content.fullAddress)
		if (first && first.content && first.content.fullAddress) return first.content.fullAddress
		return false
	}

	const getContactPhone = () => {

		return clientAccount.contactPhone && `+${clientAccount.contactPhone.idd}${clientAccount.contactPhone.number}${clientAccount.contactPhone.extension || ""}`;
	}

	const nextReview = clientAccount && clientAccount.lastReview && clientAccount.reviewIntervalMonths && clientAccount.reviewIntervalMonths !== 0
		? add(
			new Date(clientAccount.lastReview),
			{
				years: 0,
				months: clientAccount.reviewIntervalMonths,
				weeks: 0,
				days: 0,
				hours: 0,
				minutes: 0,
				seconds: 0
			})
		: null

	const nextAdviserReview = clientAccount && clientAccount.lastAdviserReview && clientAccount.adviserIntervalMonths && clientAccount.adviserIntervalMonths !== 0
		? add(
			new Date(clientAccount.lastAdviserReview),
			{
				years: 0,
				months: clientAccount.adviserIntervalMonths,
				weeks: 0,
				days: 0,
				hours: 0,
				minutes: 0,
				seconds: 0
			})
		: null

	const inFourWeeks = add(
		new Date(),
		{
			years: 0,
			months: 0,
			weeks: 4,
			days: 0,
			hours: 0,
			minutes: 0,
			seconds: 0
		})
	const nextReviewIndicator = clientAccount && clientAccount.lastReview && clientAccount.reviewIntervalMonths && clientAccount.reviewIntervalMonths !== 0 ? isBefore(nextReview, new Date()) ? "indicator-red" : isBefore(nextReview, inFourWeeks) ? "indicator-gold" : "indicator-green" : null
	const nextReviewContainerClass = nextReview ? "" : "no-date"
	const nextARIndicator = clientAccount && clientAccount.lastAdviserReview && clientAccount.adviserIntervalMonths && clientAccount.adviserIntervalMonths !== 0 ? isBefore(nextAdviserReview, new Date()) ? "indicator-red" : isBefore(nextAdviserReview, inFourWeeks) ? "indicator-gold" : "indicator-green" : null
	const nextARContainerClass = nextAdviserReview ? "" : "no-date"
	if (!clientAccount) {
		return <div>Client account token has no clientAccount</div>
	}
	const type = getClientAccountType(clientAccount, adviserGroupToken);
	const i = i18n.get;
	const identifier = adviserGroupToken ? i(clientAccount?.type) + " " + clientAccount?.number : type === ClientAccountTypes.ACCOUNT ? `${clientAccount?.accountType?.name} ${clientAccount?.number}` : type === ClientAccountTypes.NON_PERSON ? i(clientAccount.type) : false;
	const locationLink = getLocationLink()

	const clientAccountDisplayName = type === ClientAccountTypes.CLIENT ? personDisplayName(clientAccount) : clientAccount.name;

	return (
		<div id={stylingId ? stylingId : ""} className={`ca-token-outer ca-token-${type} ca-token-${size}${margin ? " margin-" + margin : ""}`} onClick={onClick} >
			{/* Icon/indicator on right hand side */}
			<div className="ca-type-indicator">
				<i className="material-icons">{iconNames[type]}</i>
			</div>
			<div className={`ca-token-content${adviserGroupToken ? " adviser-group-token" : ""}`}>
				{/* Token Name(First column next to icon) */}
				<div className="ca-token-value">
					{identifier && <div className="ca-token-value-label">{identifier}</div>}
					{/* <ToolTip placement="top-start" offsetY={8} label={clientAccount.name} disabled={!isOverflown}>
						<span className="ca-name-span" ref={textElementRef}>{clientAccount.name}</span>
					</ToolTip> */}
					{
						 !isOverflown
							? <ToolTip placement="top-start" offsetY={8} label={accountNumber} disabled={!accountNumber} stopClickEfect={stopClickEfect}>
								<span style={{maxWidth: tdWidth ? tdWidth : ""}} className="ca-name-span" ref={textElementRef}>{clientAccountDisplayName}</span>
							</ToolTip>
							: <ToolTip placement="top-start" offsetY={8} label={accountNumber ? accountNumber + " | " + clientAccount.name : clientAccount.name} disabled={!isOverflown} stopClickEfect={stopClickEfect}>
								<span style={{maxWidth: tdWidth ? tdWidth : ""}} className="ca-name-span" ref={textElementRef}>{clientAccountDisplayName}</span>
							</ToolTip>
						
					}

					{/* Icons */}
					{size === "large" && type === "client" && (
						<div className="ca-token-icons">
							{adviserGroupToken ? (
								<div className="ca-token-icon" onClick={() => deleteWorkingGroupAccount(clientAccount)}>
									<i className="material-icons delete">delete_forever</i>
								</div>
							) : (
								<>
									{clientAccount.contactEmail ? (
										<div className="ca-token-icon">
											<Tooltip label={`Email to ${clientAccount.contactEmail}`}>
												<i className="material-icons email" onClick={onEmailClick}>
													email
												</i>
											</Tooltip>
										</div>
									) : (
										<div className="ca-token-icon" style={{ opacity: 0.3, pointerEvents: "none" }}>
											<i className="material-icons email" style={{ color: "#9898cb" }}>
												email
											</i>
										</div>
									)}
									{clientAccount.contactPhone ? (
										<div className="ca-token-icon">
											<Tooltip label={`Call ${getContactPhone()}`}>
												<i className="material-icons phone" onClick={onMobileClick}>
													phone
												</i>
											</Tooltip>
										</div>
									) : (
										<div className="ca-token-icon" style={{ opacity: 0.3, pointerEvents: "none" }}>
											<i className="material-icons phone" style={{ color: "#9898cb" }}>
												phone
											</i>
										</div>
									)}
									{showLocationLink && (locationLink ? (
										<div className="ca-token-icon">
											<a target="_blank" href={`https://www.google.com/maps/place/${locationLink.split(" ").join("+")}`} onClick={(e) => e.stopPropagation()} >
												<Tooltip label={`Address: ${locationLink}`}>
													<i className="material-icons" style={{ color: "#0d8efd" }} >
														place
													</i>
												</Tooltip>
											</a>
										</div>
									) : (
										<div className="ca-token-icon" style={{ opacity: 0.3, pointerEvents: "none" }}>
											<i className="material-icons" style={{ color: "#9898cb" }}>
												place
											</i>
										</div>
									))}
								</>
							)}
						</div>
					)}
					{subLabel && <div className="ca-token-sub-value">{subLabel}</div>}
					{customIcon && customIcon}
				</div>
				{/* Large size content */}
				{size === "large" && (
					<>
						{/* {(type === ClientAccountTypes.CLIENT || type === ClientAccountTypes.NON_PERSON) && (
								<div className="ca-token-value right-align">
									<div className="ca-token-value-label">{i("Influence")}</div>
									<SummaryValue value={clientAccount.influence} zero="n/a" />
								</div>
							)} */}
						{!adviserGroupToken && (
							<>
								{(type === ClientAccountTypes.CLIENT || type === ClientAccountTypes.NON_PERSON) && (
									// <div className="ca-token-value right-align">
									<div className={`ca-token-value ${bookmarkClassNames ? bookmarkClassNames : "right-align"}`}>
										<div className="ca-token-value-label">Accounts</div>
										<span>{clientAccount.numberOfAccounts || "-"}</span>
									</div>
								)}
								{type === ClientAccountTypes.ACCOUNT && (
									<>
										{clientAccount.status && (
											<div className="acc-li-status">
												<div className="list-item-label">{i("Status")}</div>
												<StatusTag status={clientAccount.status} />
											</div>
										)}
										<div className="ca-token-value">
											<div className="ca-token-value-label">Service</div>
											{!(clientAccount.managementBasis == null) ? clientAccount.managementBasis.name : "None"}
										</div>
										<div className="ca-token-value">
											<div>
												<div className="ca-token-value-label">Next Review</div>
												<div className={"ca-token-next-review-container " + nextReviewContainerClass}>
													<div className={nextReviewIndicator}></div>
													{!(clientAccount.lastReview == null) &&
														!(clientAccount.reviewIntervalMonths === null || clientAccount.reviewIntervalMonths === 0)
														? format(nextReview, "dd/MM/yy")
														: "-"}
												</div>
											</div>
										</div>
										<div className="ca-token-value">
											<div>
												<div className="ca-token-value-label">Next Review</div>
												<div className={"ca-token-next-review-container " + nextARContainerClass}>
													<div className={nextARIndicator}></div>
													{!(clientAccount.lastAdviserReview == null) &&
														!(clientAccount.adviserIntervalMonths === null || clientAccount.adviserIntervalMonths === 0)
														? format(nextAdviserReview, "dd/MM/yy")
														: "-"}
												</div>
											</div>
										</div>
										<div className="ca-token-value">
											<div className="ca-token-value-label">Model</div>
											{!(clientAccount.modelName == null) ? clientAccount.modelName : "None"}
										</div>
									</>
								)}
								<div className="ca-token-value right-align">
									<div className="ca-token-value-label">{i("CashBalance")}</div>
									<SummaryValue value={clientAccount.cashBalance} />
								</div>
							</>
						)}
						<div className="ca-token-value right-align">
							<div className="ca-token-value-label">Value</div>
							<SummaryValue value={clientAccount.value} />
						</div>
					</>
				)}
				{customIcons && <div className="ca-token-custom-icons">{customIcons}</div>}
				{/* Icons */}
				{(size === "medium" || size === "small" || adviserGroupToken) && (
					<div className="ca-token-icons">
						{adviserGroupToken ? (
							<div className="ca-token-icon" onClick={() => deleteWorkingGroupAccount(clientAccount)}>
								<i className="material-icons delete">delete_forever</i>
							</div>
						) : (
							<>
								{isPrimary && (
									<div className="ca-token-icon ca-token-icon-medium">
										<ToolTip label="Primary Account Holder">
											<i className="material-icons primary-badge">person_pin</i>
										</ToolTip>
									</div>
								)}
							</>
						)}
					</div>
				)}
			</div>
		</div>
	);
}

export default withData(withThreeSkyeGlobal(ClientAccountToken));
