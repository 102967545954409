import React, { Component } from "react";
import { withI18n, withStorage, withRemote } from "@threeskye/global";
import LocationField from "../../../components/Shared/LocationField";
import DateTimeField from '../../../components/Shared/DateTimeField';
import Button from "@threeskye/buttons";
import { ModalTypes } from '../../../utils/ModalUtils';

import "./CreateEventExtension.scss";
import {
	saveEvent, deleteEvent, eventTypes, clientAsText, renderAccount, matchClients,
	renderClient, matchAccounts, renderAccountEmail, renderClientEmail, renderOrder,
	linkEventToOrderFunction, orderedEventTypes
} from "../../../utils/EventUtils";
import EventNoteEditor from "../../../components/Shared/EventNoteEditor";
import { DropTarget } from "react-dnd";
import { NativeTypes } from "react-dnd-html5-backend";
import UploadList from "../../../modals/Shared/UploadList";
import { withToaster, withData } from "../../../crm/ThreeSkyeCRM";
import { getMimeTypeFromExtension, getAccountsList, getClientsList } from "../../../utils/Utils";
import RecordAdviceChecklist from "../../../modals/Shared/RecordAdviceChecklist";
import ClientOrAccountTokenField from "../../../components/Shared/ClientOrAccountTokenField";
import Checkbox from "../../../components/Shared/Checkbox";
import CRMErrorMessage from "../../../components/Shared/Validation/CRMErrorMessage";
import EventModalTitleField from "../../../components/Events/EventModalTitleField";
import EventModalTemplateField from "../../../components/Events/EventModalTemplateField";
import EventTypeSelect from "../../../components/Events/EventTypeSelect"
import { debounce } from "throttle-debounce";
import EmailTaggingField from "../../Shared/Inputs/EmailTaggingField";
import SlateAttachmentButton from "../../Events/SlateAttachmentButton";
import SlateAddOrderOrAssetButton from "../../Events/SlateAddOrderOrAssetButton"
import { MarkButton } from "../../Shared/Slate/RichTextEditor";
import Tooltip from '../../Popups/Tooltip';


const eventDocsTarget = {
	drop(props, monitor, component) {
		component.fileAttached(monitor.getItem().files);
	}
}

function collect(connect, monitor) {
	return {
		connectDropTarget: connect.dropTarget(),
		isOver: monitor.isOver(),
		isOverCurrent: monitor.isOver({ shallow: false }),
		canDrop: monitor.canDrop(),
		itemType: monitor.getItemType()
	}
}

class CreateEventExtension extends Component {
	constructor(props) {
		super(props);

		this.searchCounter = 0;
		//Initialisation

		/**
		 * default event type = meeting
		 */
		let eventType = props.eventTypeToOpen ? props.eventTypeToOpen : eventTypes[2];
		let init = props.initialisationData;
		if (init) {
			if (!init.initType || init.initType === "blank") {
				if (init.eventType) {
					eventType = eventTypes.find(e => e.name === init.eventType);
				}
				//Update the stored state so that this is no longer considered blank
				init.initType = "unfinished";
				const state = this.createState(eventType, init);
				init.state = this.state = state;

				this.initialise.bind(this)();
			} else if (init.initType && init.initType === "draft") {
				const state = this.createState(eventType, init);
				init.state = this.state = state;

				// this.initialise.bind(this)();
				this.initialiseDraft.bind(this)();
				init.initType = "unfinished";
			} else if (init.initType) {
				if (eventType) {
					this.updateState({ eventType: eventType }, true)
				}
				this.state = init.state;
				// newState = false;
				if (init.requiresInit) {
					this.initialise.bind(this)();
				}
			}
			this.props.storage.getOrFetch("/users/current-user/role").then((fetchedRole) => {
				this.setState({ userRole: fetchedRole.roleCode })
			})
			this.props.clearInitialisationData()
		}

		this.autoSelectClient = this.autoSelectClient.bind(this);
		this.autoRemoveClient = this.autoRemoveClient.bind(this);
		this.autoSelectAccount = this.autoSelectAccount.bind(this);
		this.autoRemoveAccount = this.autoRemoveAccount.bind(this);
		this.addAssociatedClients = this.addAssociatedClients.bind(this);
		this.addAssociatedAccounts = this.addAssociatedAccounts.bind(this);
		this.removeSingleAccount = this.removeSingleAccount.bind(this)

		this.onAccountUpdate = this.onAccountUpdate.bind(this);
		this.onClientUpdate = this.onClientUpdate.bind(this);

		this.onToAdded = this.onToAdded.bind(this);
		this.onCcAdded = this.onCcAdded.bind(this);
		this.onBccAdded = this.onBccAdded.bind(this);

		this.acceptSuggestion = this.acceptSuggestion.bind(this);
		this.onTextChange = debounce(500, this.onTextChange.bind(this));

		this.saveDraftEvent = this.saveDraftEvent.bind(this);
		this.deleteDraftEvent = this.deleteDraftEvent.bind(this);
		this.finishEvent = this.finishEvent.bind(this);
		this.save = this.save.bind(this);
		this.debouncedSave = debounce(500, this.save.bind(this))
		// this.autoSave = this.autoSave.bind(this)

		this.updateState = this.updateState.bind(this);
		// this.eventStarted = this.eventStarted.bind(this);
		this.onInputChange = this.onInputChange.bind(this);
		this.removeFile = this.removeFile.bind(this);
		this.fileAttached = this.fileAttached.bind(this);
		this.templateFileAttachment = this.templateFileAttachment.bind(this);
		this.populateTemplate = this.populateTemplate.bind(this);

		this.linkOrderFunction = this.linkOrderFunction.bind(this)
		this.updateOrdersToBeLinked = this.updateOrdersToBeLinked.bind(this)

		this.titleRef = React.createRef();

		//Always do email check 
		this.props.storage.getOrFetch("/modules/crm/configuration")
			.then(conf => this.setState({ canShare: conf.emailAvailable }));
		//TODO CM comment these two lines out if you want to test the "warning" message
		this.props.storage.getOrFetch("/modules/crm/compliance/record-of-advice-steps")
			.then(message => this.setState({ recordAsAdviceSteps: message }));
	}

	createState(eventType, init) {
		let state = {
			id: null,
			created: new Date(),
			clients: [],
			accounts: [],
			location: null,
			date: null,
			time: null,
			isNow: false,
			selectedAccounts: [],
			selectedClients: [],
			associatedAccounts: [],
			associatedClients: [],
			eventType,
			helpText: "",
			text: [{
				type: 'paragraph',
				children: [{ text: '' }],
			}],
			header: "",
			attachments: [],
			sendAsEmail: false,
			locationFieldValid: true,
			dateTimeFieldValid: true,
			accClientFieldValid: true,
			noteFieldValid: true,
			recipientFieldValid: true,
			//The steps that need to be gone through to issue advice
			recordAsAdviceSteps: null,
			adviceCheckActive: false,
			adviceCheckConfirmed: false,
			//emails
			toClientList: [],
			toEmailList: [],
			toAccountList: [],
			ccClientList: [],
			ccEmailList: [],
			ccAccountList: [],
			bccClientList: [],
			bccEmailList: [],
			bccAccountList: [],
			//email template
			template: {},
			emailTemplate: false,
			//Linking orders
			ordersToBeLinked: [],
			linkedOrders: [],
		};
		if (init && init.preload) {
			Object.assign(state, init.preload);
		}
		return state;
	}

	/**
	 * Updates the stored state as well as current state
	 */
	updateState(data, stillInitialising) {
		if (!stillInitialising && this.state.initialState === undefined) {
			let newState = this.cloneState(this.state);
			if (data.selectedAccounts) newState.selectedAccounts = data.selectedAccounts;
			if (data.selectedClients) newState.selectedClients = data.selectedClients;
			data.initialState = newState;
		}
		this.props.initialisationData.state
			? Object.assign(this.props.initialisationData.state, data)
			: Object.assign(data)
		this.setState(data);
		!stillInitialising && this.debouncedSave(true)
	}

	cloneState(st) {
		let accounts = [];
		let attachments = [];
		let clients = [];
		let selectedAccounts = [];
		let selectedClients = [];
		let associatedAccounts = [];
		let associatedClients = [];

		for (let i = 0; i < st.accounts.length; i++) {
			accounts.push(Object.assign({}, st.accounts[i]));
		}
		for (let i = 0; i < st.attachments.length; i++) {
			attachments.push(Object.assign({}, st.attachments[i]));
		}
		for (let i = 0; i < st.clients.length; i++) {
			clients.push(Object.assign({}, st.clients[i]));
		}
		for (let i = 0; i < st.selectedAccounts.length; i++) {
			selectedAccounts.push(Object.assign({}, st.selectedAccounts[i]));
		}
		for (let i = 0; i < st.selectedClients.length; i++) {
			selectedClients.push(Object.assign({}, st.selectedClients[i]));
		}
		for (let i = 0; i < st.associatedAccounts.length; i++) {
			associatedAccounts.push(Object.assign({}, st.associatedAccounts[i]));
		}
		for (let i = 0; i < st.associatedClients.length; i++) {
			associatedClients.push(Object.assign({}, st.associatedClients[i]));
		}

		let newState = {
			id: st.id,
			date: st.date,
			header: st.header,
			helpText: st.helpText,
			location: st.location === null ? null : Object.assign({}, st.location),
			text: st.text,
			time: st.time,
			isNow: st.isNow,
			accounts: accounts,
			attachments: attachments,
			clients: clients,
			created: st.created,
			eventType: Object.assign({}, st.eventType),
			selectedAccounts: selectedAccounts,
			selectedClients: selectedClients,
			associatedAccounts: associatedAccounts,
			associatedClients: associatedClients,
			toClientList: st.toClientList,
			toEmailList: st.toEmailList,
			toAccountList: st.toAccountList,
			ccClientList: st.ccClientList,
			ccEmailList: st.ccEmailList,
			ccAccountList: st.ccAccountList,
			bccClientList: st.bccClientList,
			bccEmailList: st.bccEmailList,
			bccAccountList: st.bccAccountList,
			template: st.template,
			emailTemplate: st.emailTemplate,
			ordersToBeLinked: st.ordersToBeLinked,
			linkedOrders: st.linkedOrders,
			adviceCheckConfirmed: st.adviceCheckConfirmed
		}
		return newState;
	}

	initialise() {
		let autoAccount = null, autoPersonClient = null, autoNonPersonClient = null;

		let auto = this.props.storage.get("crm.event.defaults");
		if (auto) {
			if (auto.type === undefined) {
				//It's a account
				autoAccount = auto.id;
			} else if (auto.type === "PERSON") {
				// It's a person client
				autoPersonClient = auto.id;
			} else {
				// It's a non person client
				autoNonPersonClient = auto.id;
			}
		}

		getClientsList(this.props.storage)
			.then(clients => {
				getAccountsList(this.props.storage)
					.then(accounts => {
						//Process clients
						let selectedClient = [];
						clients = clients.map(client => {
							let data = {
								manual: false,
								autoFlowOn: 0,
								automatic: 0,
								data: client
							};

							/**
							 * Pre-selected clients from drafts
							 */
							if (client.id === (client.type === "PERSON" ? autoPersonClient : autoNonPersonClient)) {
								data.manual = true;
							}
							if (data.manual)
								selectedClient.push(data);

							return data;
						});
						//Deliberately sync access, NOT using updateState because we need it downstream
						this.state.clients = clients; 		// eslint-disable-line react/no-direct-mutation-state

						//Process accounts
						let selectedAccount = [];
						accounts = accounts.map(account => {
							let data = {
								manual: false,
								autoFlowOn: 0,
								automatic: 0,
								data: account
							};

							/**
							 * pre-selected accunts from drafts
							 */
							// if (this.state.draftAccounts?.find((draft) => account.id === draft)) {
							// 	data.manual = true;
							// } else 
							if (autoAccount === account.id) {
								data.manual = true;
							}

							if (data.manual)
								selectedAccount.push(data);

							return data;
						});

						//Deliberately sync access, NOT using updateState because we need it downstream
						this.state.accounts = accounts; 		// eslint-disable-line react/no-direct-mutation-state

						//Look for clients with the same name
						for (let i = clients.length - 1; i >= 0; i--) {
							let name = clientAsText(clients[i]);
							let other = clients.findIndex(client => clientAsText(client) === name);
							if (other !== i) {
								clients[i].expandRequired = true;
								clients[other].expandRequired = true;
							}
						}
						let { selectedAccounts, selectedClients } = this.state;
						if (selectedClient?.length > 0) {
							selectedClient.forEach((client) => {
								selectedClients.push(client);
								this.addAssociatedAccounts(client, selectedAccounts);
							});
							this.updateState({ clients, selectedClients, selectedAccounts }, true);
						} else {
							this.updateState({ clients }, true);
						}
						if (selectedAccount?.length > 0) {
							selectedAccount.forEach((account) => {
								selectedAccounts.push(account);
								this.addAssociatedClients(account, selectedClients);
							});
							this.updateState({ accounts, selectedClients, selectedAccounts }, true);
						} else {
							this.updateState({ accounts }, true);
						}


					});
			});
	}

	initialiseDraft() {
		/**
		 * Clients from draft
		 */
		if (this.state.draftClients?.length > 0) { //&& selectedClient?.length === 0) {
			let { clients, selectedClients } = this.state;
			this.state.draftClients.forEach((draftClientId) => {
				this.props.getClientById(draftClientId, this.props.storage).then((response) => {
					clients.push(response)
					let data = {
						manual: true,
						autoFlowOn: 0,
						automatic: 0,
						data: response
					};
					selectedClients.push(data);
					this.updateState({ clients, selectedClients }, true);
				})
			});
		}
		/**
		 * NP-Clients from draft
		 */
		if (this.state.draftNonPersonClients?.length > 0) { //&& (selectedClient?.length === 0 || (selectedClient?.length === this.state.draftClients?.length))) {
			let { clients, selectedClients } = this.state;
			this.state.draftNonPersonClients.forEach((draftClientId) => {
				this.props.getNonPersonClientById(draftClientId, this.props.storage).then((response) => {
					clients.push(response)
					let data = {
						manual: true,
						autoFlowOn: 0,
						automatic: 0,
						data: response
					};
					selectedClients.push(data);
					this.updateState({ clients, selectedClients }, true);
				})
			});
		}
		/**
		 * Accounts from draft
		 */
		if (this.state.draftAccounts?.length > 0) { //&& selectedClient?.length === 0) {
			let { accounts, selectedAccounts } = this.state;
			this.state.draftAccounts.forEach((draftAccountId) => {
				this.props.getAccountById(draftAccountId, this.props.storage).then((response) => {
					accounts.push(response)
					let data = {
						manual: true,
						autoFlowOn: 0,
						automatic: 0,
						data: response,
					};
					selectedAccounts.push(data);
					this.updateState({ accounts, selectedAccounts }, true);
				})
			});
		}

		if (this.state.emails && this.state.emails[0]) {
			let { emails } = this.state;

			let to = emails[0].to ? JSON.parse(emails[0].to) : {}
			let cc = emails[0].cc ? JSON.parse(emails[0].cc) : {}
			let bcc = emails[0].bcc ? JSON.parse(emails[0].bcc) : {}
				("Create event extensions has ", { to, cc, bcc })

			let toClientList = [];
			let toAccountList = [];
			let toEmailList = [];

			const promise1 = to.clients.map((client) => {
				return this.props.getClientById(client.id, this.props.storage)
					.then((response) => {
						let data = {
							manual: true,
							autoFlowOn: 0,
							automatic: 0,
							data: response,
							listObjectType: "client"
						};
						toClientList.push(data);
					})
					.catch(error => console.error("Error in getClientById:", error));
			});

			const promise2 = to.nonPersonClients.map((client) => {
				return this.props.getNonPersonClientById(client.id, this.props.storage)
					.then((response) => {
						let data = {
							manual: true,
							autoFlowOn: 0,
							automatic: 0,
							data: response,
							listObjectType: "client"
						};
						toClientList.push(data);
					})
					.catch(error => console.error("Error in getNonPersonClientById:", error));
			})

			Promise.all([...promise1, ...promise2])
				.then(() => {
					this.updateState({ toClientList }, true);
				})
				.catch((error) => {
					console.error("Error in Promise.all:", error);
				});

			to.accounts.forEach((account) => {
				this.props.getAccountById(account.id, this.props.storage).then((response) => {
					let data = {
						manual: true,
						autoFlowOn: 0,
						automatic: 0,
						data: response,
						listObjectType: "account"
					};
					toClientList.push(data);
					this.updateState({ toAccountList }, true);
				})
			})
			to.emails.forEach((email) => {
				let data = {
					manual: true,
					autoFlowOn: 0,
					automatic: 0,
					data: { email: email.email },
					listObjectType: "email"

				};
				toEmailList.push(data)
			})
			this.updateState({ toEmailList }, true)



			let ccClientList = [];
			let ccAccountList = [];
			let ccEmailList = [];
			cc.clients.forEach((client) => {
				this.props.getClientById(client.id, this.props.storage).then((response) => {
					let data = {
						manual: true,
						autoFlowOn: 0,
						automatic: 0,
						data: response,
						listObjectType: "client"
					};
					ccClientList.push(data);
					this.updateState({ ccClientList }, true);
				})
			})
			cc.nonPersonClients.forEach((client) => {
				this.props.getNonPersonClientById(client.id, this.props.storage).then((response) => {
					let data = {
						manual: true,
						autoFlowOn: 0,
						automatic: 0,
						data: response,
						listObjectType: "client"
					};
					ccClientList.push(data);
					this.updateState({ ccClientList }, true);
				})
			})
			cc.accounts.forEach((account) => {
				this.props.getAccountById(account.id, this.props.storage).then((response) => {
					let data = {
						manual: true,
						autoFlowOn: 0,
						automatic: 0,
						data: response,
						listObjectType: "account"
					};
					ccClientList.push(data);
					this.updateState({ ccAccountList }, true);
				})
			})
			cc.emails.forEach((email) => {
				let data = {
					manual: true,
					autoFlowOn: 0,
					automatic: 0,
					data: { email: email.email },
					listObjectType: "email"
				};
				ccEmailList.push(data)
			})
			this.updateState({ ccEmailList }, true)


			let bccClientList = [];
			let bccAccountList = [];
			let bccEmailList = [];
			bcc.clients.forEach((client) => {
				this.props.getClientById(client.id, this.props.storage).then((response) => {
					let data = {
						manual: true,
						autoFlowOn: 0,
						automatic: 0,
						data: response,
						listObjectType: "client"
					};
					bccClientList.push(data);
					this.updateState({ bccClientList }, true);
				})
			})
			bcc.nonPersonClients.forEach((client) => {
				this.props.getNonPersonClientById(client.id, this.props.storage).then((response) => {
					let data = {
						manual: true,
						autoFlowOn: 0,
						automatic: 0,
						data: response,
						listObjectType: "client"
					};
					bccClientList.push(data);
					this.updateState({ bccClientList }, true);
				})
			})
			bcc.accounts.forEach((account) => {
				this.props.getAccountById(account.id, this.props.storage).then((response) => {
					let data = {
						manual: true,
						autoFlowOn: 0,
						automatic: 0,
						data: response,
						listObjectType: "account"
					};
					bccClientList.push(data);
					this.updateState({ bccAccountList }, true);
				})
			})
			bcc.emails.forEach((email) => {
				let data = {
					manual: true,
					autoFlowOn: 0,
					automatic: 0,
					data: { email: email.email },
					listObjectType: "email"
				};
				bccEmailList.push(data)
			})
			this.updateState({ bccEmailList }, true)
		}
	}

	autoSelectClient(client) {
		//Autoselect uses a cut down object
		let fullClientPromise = null;
		if (this.state.clients.length > 0) {
			let fullClient = this.state.clients.find(clnt => clnt.data.id === client.id);
			++fullClient.automatic;
			fullClientPromise = Promise.resolve(fullClient);
		} else {
			let clientLookup = this.props.getClientById(client.id, this.props.storage);
			fullClientPromise = Promise.resolve(clientLookup.then(client => {
				return {
					manual: true,
					autoFlowOn: 0,
					automatic: 1,
					data: client
				}
			}));
		}
		fullClientPromise.then(fullClient => {
			let match = clnt => clnt.data.id === fullClient.data.id;

			let { selectedAccounts, selectedClients } = this.state;

			if (!selectedClients.some(match)) {
				selectedClients.push(fullClient);
				this.addAssociatedAccounts(fullClient, selectedAccounts);
			}

			this.updateState({ selectedAccounts, selectedClients });
		});
	};

	addAssociatedAccounts(client) {
		let suggestions = this.state.associatedAccounts//using suggestion lists, we add each value if not present
		this.state.accounts.forEach(account => {
			if (account && account.data && account.data.primary && account.data.primary.id === client.data.id) {
				++account.autoFlowOn;
				if (!suggestions.some(acc => acc.data.id === account.data.id)) {
					suggestions.push(account)
				}
			}
		});
		this.updateState({ associatedAccounts: suggestions })
	}

	removeAssociatedAccounts(client) {
		let suggestions = this.state.associatedAccounts
		let i = 0;
		while (i < suggestions.length) {
			let account = suggestions[i];
			if (account.data.primary && account.data.primary.id === client.data.id) {
				if (account.autoFlowOn > 0)
					--account.autoFlowOn;
				if (account.autoFlowOn === 0)
					suggestions.splice(i, 1);
				else ++i;
			} else ++i;
		}
		this.updateState({ associatedAccounts: suggestions })
	}

	autoRemoveClient(client) {
		let { selectedClients, selectedAccounts } = this.state;
		let match = clnt => clnt.data.id === client.id;
		let index = selectedClients.findIndex(match);

		if (index > -1) {
			let fullClient = selectedClients[index];
			if (fullClient.automatic > 0)
				--fullClient.automatic;
			if (!fullClient.manual && fullClient.autoFlowOn === 0 && fullClient.automatic === 0) {
				selectedClients.splice(index, 1);
				this.removeAssociatedAccounts(fullClient, selectedAccounts);
			}
		}
		this.updateState({ selectedClients, selectedAccounts });
	}
	autoSelectAccount(account) {
		//Autoselect uses a cut down object
		let fullAccountPromise = null;
		if (this.state.accounts.length > 0) {
			let fullAccount = this.state.accounts.find(acct => acct.data.number === account.number);
			++fullAccount.automatic;
			fullAccountPromise = Promise.resolve(fullAccount);
		} else {
			let accountLookup = this.props.getAccountById(account.id, this.props.storage);
			fullAccountPromise = Promise.resolve(accountLookup.then(account => {
				return {
					manual: true,
					autoFlowOn: 0,
					automatic: 1,
					data: account
				}
			}));
		}
		fullAccountPromise.then(fullAccount => {
			let match = acct => acct.data.number === fullAccount.data.number;

			let { selectedAccounts, selectedClients } = this.state;

			if (!selectedAccounts.some(match)) {
				selectedAccounts.push(fullAccount);
				this.addAssociatedClients(fullAccount, selectedClients);
			}

			this.updateState({ selectedAccounts, selectedClients });
		});
	};
	addAssociatedClients(account) {
		let suggestions = this.state.associatedClients//using suggestion lists, we add each value if not present

		let primaryLookup = this.props.getClientById(account.data.primary.id, this.props.storage);
		//which client does this account have as a primary
		let primaryPromise = Promise.resolve(primaryLookup.then(client => {
			return {
				manual: true,
				autoFlowOn: 1,
				automatic: 0,
				data: client
			}
		}));

		primaryPromise.then(primary => {
			if (!suggestions.some(acc => acc.data.id === primary.data.id))
				suggestions.push(primary)
			this.updateState({ associatedClients: suggestions })
		});
	}
	removeAssociatedClients(account) {
		let suggestions = this.state.associatedClients
		let i = 0;
		while (i < suggestions.length) {
			let client = suggestions[i];
			if (account.data.primary && account.data.primary.id === client.data.id) {
				if (client.autoFlowOn > 0)
					--client.autoFlowOn;
				if (client.autoFlowOn === 0)
					suggestions.splice(i, 1);
				else ++i;
			} else ++i;
		}
		this.updateState({ associatedClients: suggestions })
	}
	autoRemoveAccount(account) {
		let { selectedAccounts, selectedClients } = this.state;
		let match = acct => acct.data.id === account.id;
		let index = selectedAccounts.findIndex(match);

		if (index > -1) {
			let fullAccount = selectedAccounts[index];
			if (fullAccount.automatic > 0)
				--fullAccount.automatic;
			if (!fullAccount.manual && fullAccount.autoFlowOn === 0 && fullAccount.automatic === 0) {
				selectedAccounts.splice(index, 1);
				this.removeAssociatedClients(fullAccount, selectedClients);
			}
		}
		this.updateState({ selectedClients, selectedAccounts });
	}

	removeSingleAccount(e) {
		if (e) e.preventDefault()
		this.setState({ selectedAccounts: [], template: {}, header: "", text: [], attachments: [], toClientList: [], recipientFieldValid: true })
	}

	populateTemplate(data, textLength, emtyTextBox) {
		if (textLength === 0 || emtyTextBox) {
			const template = data
			const accountNameAndNumber = this.state.selectedAccounts ? this.state.selectedAccounts[0] && this.state.selectedAccounts[0].data.name + " " + this.state.selectedAccounts[0].data.number + " - " : ""
			const subject = accountNameAndNumber + template.templateName
			const body = JSON.parse(template.body)
			this.updateState({
				template: template,
				header: subject,
				text: body
			});

			const decodedString = atob(template.attachment);
			const unicodeArray = Uint8Array.from(decodedString, (m) => m.codePointAt(0));
			const file = new File([unicodeArray], template.attachmentName, { type: "application/pdf" })
			const files = [file];
			this.fileAttached(files);
		} else {
			const template = data
			this.updateState({
				template: template,
			});
		}

	}

	templateFileAttachment(files) {
		const attachments = this.state.attachments
		// TODO: modify this function if the template has more than 1 file
		if (attachments.length > 0) {
			const repittedDoc = attachments.find(doc => doc.name === files[0].name)
			if (repittedDoc) return
			else this.fileAttached(files)
		} else this.fileAttached(files)
	}

	uniqueFileName(fileName, suffix = 0) {
		const attachments = this.state.attachments
		let prefix = fileName.split('.')[0]
		let fileExtension = fileName.split('.')[1]
		const proposedName = suffix > 0 ? prefix + " (" + suffix + ")." + fileExtension : fileName

		let repeatedDoc = attachments.find(doc => doc.name === proposedName)
		if (repeatedDoc) {
			const nextSuffix = suffix + 1
			return this.uniqueFileName(fileName, nextSuffix)
		}

		return proposedName
	}

	fileAttached(files) {
		for (var i = 0; i < files.length; i++) {
			let file = files[i];
			let originalName = file.name;
			let name = this.uniqueFileName(originalName)
			if (originalName !== name) {
				file = new File([file], file.name, file.options)
			}


			let listener = {
				setStatus: st => {
					this.setState({ update: new Date() });
				},
				setProgress: p => {
					this.setState({ update: new Date() });
				},
				finished: () => {
					this.setState({ update: new Date() });
				},
				cancel: me => {
					let { attachments } = this.state;
					let attachment = attachments.findIndex(att => att.id === me.id);
					if (attachment > -1) {
						attachments.splice(attachment, 1);
						this.setState({ update: new Date() });
					}
				}
			}
			let theUpload = this.props.storage.get("crm.progress").create(name, listener);

			//Also add it to our on modal progress widget
			let { attachments } = this.state;
			attachments.push(theUpload);
			this.setState({ attachments });

			let url = "/modules/crm/provider/event-upload/" + name;

			theUpload.setStatus("Initialising ...");
			this.props.remote.get(url)
				.then(data => {
					theUpload.s3Key = data.data[1];
					let xhr = new XMLHttpRequest();
					// xhr.open('post', "/api/modules/crm/provider/" + this.props.location);
					xhr.open('put', data.data[0], true);
					xhr.addEventListener('load', e => {
						if (xhr.status >= 200 && xhr.status < 300) {
							this.props.remote.get("/modules/crm/provider/event-uploaded/" + theUpload.s3Key)
								.then(data => {
									theUpload.uploadComplete = true;
									theUpload.setStatus("");
									theUpload.finished();
									if (data.message && data.message.length > 0) {
										this.props.toaster.toast(data.message);
									}
									if (data.refresh) {
										data.refresh.forEach(refresh => this.props.storage.refresh(refresh));
									}
								});
						} else {
							console.log("Error");
							console.log(xhr);
						}
					});
					xhr.upload.addEventListener('progress', e => {
						if (e.lengthComputable) {
							let complete = (e.loaded / e.total * 100 | 0);
							if (complete === 100) {
								theUpload.setProgress(complete);
								theUpload.setStatus("Processing ...");
							} else {
								theUpload.setProgress(complete);
							}
						}
					});

					// let formData = new FormData();
					// formData.append("file", file);
					// xhr.send(formData);
					//Type
					xhr.setRequestHeader("Content-Type", file.type || getMimeTypeFromExtension(name));
					xhr.send(file);
				});
		}
	}

	isClearable(data) {
		return data.automatic === 0;
	}

	onToAdded(type, list, removed) {
		if (type === 'client') {
			this.updateState({ toClientList: list, selectedClients: list, recipientFieldValid: true });
		} else if (type === 'email') {
			this.updateState({ toEmailList: list, recipientFieldValid: true });
		} else {
			this.updateState({ toAccountList: list });
		}
	}

	onCcAdded(type, list, removed) {
		if (type === 'client') {
			this.updateState({ ccClientList: list });
		} else if (type === 'email') {
			this.updateState({ ccEmailList: list });
		} else {
			this.updateState({ ccAccountList: list });
		}
	}

	onBccAdded(type, list, removed) {
		if (type === 'client') {
			this.updateState({ bccClientList: list });
		} else if (type === 'email') {
			this.updateState({ bccEmailList: list });
		} else {
			this.updateState({ bccAccountList: list });
		}
	}


	onClientUpdate(value, removedClient) {
		let selectedAccounts = this.state.selectedAccounts;

		if (value.length > this.state.selectedClients.length) {
			//Can only add one at a time, so the new one will always be the last one
			let client = value[value.length - 1];
			client.manual = true;
			client.isNew = true;
			this.addAssociatedAccounts(client, selectedAccounts);
		}
		if (removedClient) {
			this.removeAssociatedAccounts(removedClient, selectedAccounts);
		}

		this.updateState({ selectedClients: value, selectedAccounts });
	}

	onAccountUpdate(value, removedAccount, forceToClient) {
		let selectedClients = this.state.selectedClients;
		let newValue = value

		if ((this.state.eventType.name === "email-template" || forceToClient) && value.length > 0) {
			if (value.length > 1) newValue = [value[1]]
			// temporary fix to update email header
			const accountDetails = newValue[0].data.name + " " + newValue[0].data.number
			const templateName = this.state.header ? this.state.header.split("- ")[1] : ""
			const holders = newValue[0] && newValue[0].data && newValue[0].data.holders

			let newClientList = []

			const clientsList = holders.filter(h => h.type === "PERSON")
			const npeList = holders.filter(h => h.npeType)

			const promise1 = clientsList && clientsList.length > 0 && clientsList.map((client) => {
				return this.props.getClientById(client.id, this.props.storage)
					.then((response) => {
						let data = {
							manual: true,
							autoFlowOn: 0,
							automatic: 0,
							data: response,
							listObjectType: "client"

						};
						newClientList.push(data);
					})
					.catch(error => console.error("Error in getClientById:", error));
			});

			const promise2 = npeList && npeList.length > 0 && npeList.map((client) => {
				return this.props.getNonPersonClientById(client.id, this.props.storage)
					.then((response) => {
						let data = {
							manual: true,
							autoFlowOn: 0,
							automatic: 0,
							data: response,
							listObjectType: "client"

						};
						newClientList.push(data);
					})
					.catch(error => console.error("Error in getClientById:", error));
			});

			let megaPromise = []
			if (promise1.length > 0) {
				megaPromise = megaPromise.concat(promise1)
			}
			if (promise2.length > 0) {
				megaPromise = megaPromise.concat(promise2)
			}

			Promise.all(megaPromise)
				.then(() => {
					this.updateState({ header: accountDetails + " - " + templateName, toClientList: newClientList, selectedClients: newClientList, toAccountList: [] }, true);
				})
				.catch((error) => {
					console.error("Error in Promise.all:", error);
				});


		} else if (newValue.length > this.state.selectedAccounts.length) {
			//Can only add one at a time, so the new one will always be the last one
			let account = newValue[newValue.length - 1];
			account.manual = true;
			account.isNew = true;
			this.addAssociatedClients(account, selectedClients);
		}
		if (removedAccount) {
			if (this.state.eventType.name === "email-template") {
				this.removeSingleAccount()
			} else this.removeAssociatedClients(removedAccount, selectedClients);
		}
		this.updateState({ selectedAccounts: newValue, selectedClients });
	}

	onLocationUpdate(value) {
		this.updateState({ location: value });
	}

	acceptSuggestion(index, suggestionType) {//now it needs to move lists
		if (suggestionType === "client") {
			let { selectedClients, associatedClients } = this.state;
			let client = associatedClients.splice(index, 1)[0];
			client.manual = true;
			selectedClients.push(client);
			this.updateState({ selectedClients, associatedClients });
		} else {
			let { selectedAccounts, associatedAccounts } = this.state;
			let account = associatedAccounts.splice(index, 1)[0];
			account.manual = true;
			selectedAccounts.push(account)
			this.updateState({ selectedAccounts, associatedAccounts });
		}
		// let { selectedAccounts, selectedClients } = this.state;
		// this.updateState({ selectedClients, selectedAccounts });
	}

	onTextChange(editorState) {
		let data = editorState;
		this.updateState({ text: data });
	}

	finishEvent() {
		this.save(false);
	}

	saveDraftEvent() {
		this.save(true);
	}

	deleteDraftEvent() {
		deleteEvent(this.state.id, this.props.remote, this.props.storage);
	}

	componentWillUnmount() {
		const state = this.createState(eventTypes.find(t => t.name === "file-note"), ModalTypes.CreateEvent)
		ModalTypes.CreateEvent.state = state;
	}

	save(draft, closeExtension = true) {
		if (draft) {
			if (this.stateAltered()) { // || this?.state?.text?.blocks?.length > 1 || (this?.state?.text?.blocks?.length === 1 && this?.state?.text?.blocks[0]?.text !== '')) { // || this?.state?.text !== '' ) {
				saveEvent(this.state, this.props.remote, this.props.storage, draft, (id, workspaceNoteId) => { this.setState({ id, workspaceNoteId }) });
			}
		} else if (this.validateSave() && (this.stateAltered() || this.state.id || this.state.workspaceNoteId)) {

			if (this.state.eventType.type === "workspace-note") {
				saveEvent(this.state, this.props.remote, this.props.storage, draft, (id, workspaceNoteId) => { this.setState({ id, workspaceNoteId }) }, () => this.props.toaster.toast(`Note created`), true)
				saveEvent(this.state, this.props.remote, this.props.storage, draft)
			} else if (this.state.eventType.type === "email") {
				saveEvent(this.state, this.props.remote, this.props.storage, draft, () => null, () => this.props.toaster.toast(`Email sent...`), true)
			} else if (this.state.eventType.type === "file-note") {
				saveEvent(this.state, this.props.remote, this.props.storage, draft, () => null, () => this.props.toaster.toast(`Event - File Note created`), true, (eventId, emailId) => this.linkOrderFunction(eventId, emailId))
			} else if (this.state.eventType.type === "phone-call") {
				saveEvent(this.state, this.props.remote, this.props.storage, draft, () => null, () => this.props.toaster.toast(`Event - Phone Call created`), true, (eventId, emailId) => this.linkOrderFunction(eventId, emailId))
			} else if (this.state.eventType.type === "meeting") {
				saveEvent(this.state, this.props.remote, this.props.storage, draft, () => null, () => this.props.toaster.toast(`Event - Meeting created`), true, (eventId, emailId) => this.linkOrderFunction(eventId, emailId))
			} else {
				saveEvent(this.state, this.props.remote, this.props.storage, draft, () => null, () => this.props.toaster.toast(`Event - ${this.state.eventType.type} created`), null, true)
			}
			if (closeExtension && this.state.eventType.type === "workspace-note") {
				this.props.setActiveExtension("notes")
			} else if (closeExtension && this.props.previousActiveExtension) {
				this.props.setActiveExtension(this.props.previousActiveExtension)
			}
		}
	}

	stateAltered() {
		let init = this.state.initialState;
		let state = this.state;
		if (!init) {
			//Nothing's been entered, so no state has been created
			return false;
		}
		// if (init.eventType.name !== state.eventType.name){
		// 	return true
		// }
		if (init.ordersToBeLinked !== state.ordersToBeLinked) {
			return true
		}
		if (init.emailTemplate !== state.emailTemplate) {
			return true
		}
		if (init.toAccountList?.length !== state.toAccountList?.length || init.toClientList?.length !== state.toClientList?.length || init.toEmailList?.length !== state.toEmailList?.length) {
			return true
		}
		if (init.ccAccountList?.length !== state.ccAccountList?.length || init.ccClientList?.length !== state.ccClientList?.length || init.ccEmailList?.length !== state.ccEmailList?.length) {
			return true
		}
		if (init.bccAccountList?.length !== state.bccAccountList?.length || init.bccClientList?.length !== state.bccClientList?.length || init.bccEmailList?.length !== state.bccEmailList?.length) {
			return true
		}
		if (init.date !== state.date) {
			return true;
		} else if (init.header !== state.header) {
			return true;
		} else if (init.helpText !== state.helpText) {
			return true;
		} else if (init.location?.text !== state.location?.text) {
			return true;
		} else if ((init.text === '' && state.text !== '' && (state?.text?.length > 1))) {
			return true;
			/**
			 * compare JSON objects(content of the event)
			 */
		} else if (init.text !== '' && JSON.stringify(init?.text) !== JSON.stringify(state?.text)) {
			return true;
		} else if (init.time !== state.time) {
			return true;
		}
		if (init.selectedAccounts.length !== state.selectedAccounts.length || init.selectedClients.length !== state.selectedClients.length
			|| init.attachments.length !== state.attachments.length) {
			return true;
		}
		/**
		 * compare with draft cleints/accounts/attachments
		 */
		const clientLength = (state.draftClients ? state.draftClients.length : 0) + (state.draftNonPersonClients ? state.draftNonPersonClients.length : 0);
		if (state.draftAccounts?.length !== state.selectedAccounts.length || clientLength !== state.selectedClients.length) {
			return true;
		}
		for (let i = 0; i < init.selectedAccounts.length; i++) {
			if (init.selectedAccounts[i].manual !== state.selectedAccounts[i].manual || state.selectedAccounts[i]?.isNew) {
				return true;
			}
		}
		for (let i = 0; i < init.selectedClients.length; i++) {
			if (init.selectedClients[i].manual !== state.selectedClients[i].manual || state.selectedClients[i]?.isNew) {
				return true;
			}
		}
		//TODO: Check
		for (let i = 0; i < init.attachments.length; i++) {
			if (init.attachments[i] !== state.attachments[i]) {
				return true;
			}
		}
		return false;
	}

	validateSave() {
		let st = this.state;
		let type = this.state.eventType;
		var eventValid = true;
		let locationFieldValid;
		let dateTimeFieldValid;
		let accClientFieldValid;
		let recipientFieldValid;

		//RECIPIENT
		if (type.type === "email" && (
			!(st.toAccountList && st.toAccountList.length > 0)
			&& !(st.toClientList && st.toClientList.length > 0)
			&& !(st.toEmailList && st.toEmailList.length > 0)
			&& !(st.ccAccountList && st.ccAccountList.length > 0)
			&& !(st.ccClientList && st.ccClientList.length > 0)
			&& !(st.ccEmailList && st.ccEmailList.length > 0)
			&& !(st.bccAccountList && st.bccAccountList.length > 0)
			&& !(st.bccClientList && st.bccClientList.length > 0)
			&& !(st.bccEmailList && st.bccEmailList.length > 0)
		)) {
			recipientFieldValid = false
			eventValid = false;
		} else {
			recipientFieldValid = true
		}

		// LOCATION
		if (type.location && !st.location) {
			locationFieldValid = false;
			eventValid = false;
		} else {
			locationFieldValid = true;
		}

		// DATE AND TIME
		if (type.datetime && (!st.date || !st.time)) {
			dateTimeFieldValid = false;
			eventValid = false;
		} else {
			dateTimeFieldValid = true;
		}

		// ACCOUNT & CLIENT
		if (type.clientaccount && (st.selectedClients.length === 0 && st.selectedAccounts.length === 0)) {
			accClientFieldValid = false;
			eventValid = false;
		} else {
			accClientFieldValid = true;
		}

		// NOTES
		let noteFieldValid;
		if (!st.text || st.text.length < 1) {
			noteFieldValid = false;
			eventValid = false;
		} else {
			noteFieldValid = true;
		}
		this.setState({
			locationFieldValid, dateTimeFieldValid, accClientFieldValid, noteFieldValid, recipientFieldValid
		});
		return eventValid;
	}

	// eventStarted() {
	// 	let changed = this.stateAltered();

	// 	if (changed) {
	// 		Object.assign(this.props.initialisationData.state, this.state);
	// 		return true;
	// 	}

	// 	/**
	// 	 * if it is a saved draft
	// 	 */
	// 	if (this.state.id) {
	// 		return true;
	// 	}

	// 	return false;
	// }

	onInputChange(event, stateName) {
		const target = event.currentTarget;
		const value = target.type === "checkbox" ? target.checked : target.value;
		this.setState({ [stateName]: value });
	}

	removeFile(file) {
		this.updateState({ attachments: this.state.attachments.filter(existing => existing.key !== file.key) }, true);
	}

	taggingMatchAccounts = (str) => matchAccounts(str, this.searchCounter++, this.props.remote);
	taggingMatchTemplatesAccounts = (str) => matchAccounts(str, this.searchCounter++, this.props.remote, true);
	taggingDisplayAccount = (e) => renderAccountEmail(e, true);
	taggingMatchClients = (str) => matchClients(str, this.searchCounter++, this.props.remote);
	taggingDisplayClient = (e) => renderClientEmail(e, true)
	taggingRenderAccount = (e) => renderAccount(e, true)
	taggingRenderClient = (e) => renderClient(e, true);


	linkOrderFunction(eventId, emailId) {
		if (this.state.ordersToBeLinked && this.state.ordersToBeLinked.length > 0) {

			return this.state.ordersToBeLinked.map((order) => {
				const orderId = order.orderId || order.id
				// const isPendingOrder = !order.status
				linkEventToOrderFunction(orderId, eventId, emailId, false, this.props.remote, this.props.toaster, this.props.storage)
			})
		}
	}

	updateOrdersToBeLinked(array, order) {
		if (array) {
			this.updateState({ ordersToBeLinked: array, adviceCheckConfirmed: array.length > 0 })
		} else {
			if (order.orderId) {
				const newArray = this.state.ordersToBeLinked.filter(ord => ord.orderId !== order.orderId)
				this.updateState({ ordersToBeLinked: newArray, adviceCheckConfirmed: newArray.length > 0  })
			} else {
				const newArray = this.state.ordersToBeLinked.filter(ord => ord.id !== order.id)
				this.updateState({ ordersToBeLinked: newArray, adviceCheckConfirmed: newArray.length > 0  })
			}
		}
	}

	render() {
		const i = this.props.i18n.get;
		let eventType = this.state.eventType;
		const { connectDropTarget } = this.props;

		// Validation messages.
		const locationErrorMessage = "Please supply a location";
		const dateTimeErrorMessage = "Please supply a date and time";
		const accClientErrorMessage = "Please attach at least one client or account";
		const noteErrorMessage = "Please supply a description of this event";
		const recipientErrorMessage = "Please add an email recipient";

		const { locationFieldValid, accClientFieldValid, noteFieldValid, dateTimeFieldValid, recipientFieldValid, selectedAccounts, selectedClients } = this.state;
		const isEmailEvent = (eventType.name === "email");
		const isEmailTemplate = (eventType.name === "email-template")

		const getFocused = (boolean) => {
			this.setState({ focused: boolean })
		}

		const orders = this.state.ordersToBeLinked || []
		const canTagAccounts = eventType.type !== "workspace-note" && eventType.type !== "reminder" && eventType.type !== "email"
		const isDisabled = (selectedAccounts.length === 0 || selectedClients.length === 0) && orders.length > 0 && canTagAccounts
		

		return (
			<div id="event-form">
				{!isEmailTemplate && <div className="form-row">
					<div className="form-col-grow">
						<EventModalTitleField
							titleRef={this.titleRef}
							value={this.state && this.state.header ? this.state.header : ""}
							eventTypes={eventTypes}
							currentEventType={eventType}
							updateEventType={(newEventType) => {
								if (newEventType.name === "email-template" && this.state.selectedAccounts.length > 0) {
									this.onAccountUpdate(this.state.selectedAccounts, null, true)
								}
								this.setState({ eventType: newEventType, recipientFieldValid: true, emailTemplate: newEventType.name === "email-template" }, this.titleRef.current.focus())
							}}
							onChange={(e) => {
								this.updateState({ header: e.target.value });
							}}
							placeholder={isEmailEvent ? "Subject" : eventType.text + " Title"}
							editable={!isEmailTemplate}
						/>
					</div>
				</div>}

				{isEmailTemplate ?
					this.props.isMobile ? <>
						<div className="form-row">
							<div className="event-modal-email-template-tag-and-select-container" style={{ backgroundColor: this.state.focused ? "#fff" : "rgb(48, 57, 90)" }}>
								<ClientOrAccountTokenField
									matchAccounts={this.taggingMatchTemplatesAccounts}
									accountList={this.state.selectedAccounts}
									accountSuggestions={this.state.associatedAccounts}
									displayAccount={this.taggingRenderAccount}
									onAccountUpdate={this.onAccountUpdate}
									matchClients={() => []}
									isRemovable={this.isClearable}
									acceptSuggestion={this.acceptSuggestion}
									isEmailTemplate={true}
									width="100%"
									minWidth="calc(100% - 50px)"
									isTemplate={true}
									removeSingleAccount={this.removeSingleAccount}
									rightExtExpandedFull={this.props.rightExtExpandedFull}
									getFocused={getFocused}
									placeholder="Tag Account"
								/>
								<EventTypeSelect
									type="email-template"
									updateEventType={(newEventType) => {
										this.setState({ eventType: newEventType, recipientFieldValid: true, emailTemplate: newEventType.name === "email-template" })
									}}
									eventTypes={eventTypes}
									currentEventType={eventType}
									freePosition={true}
									setSidebarExpanded={this.props.setSidebarExpanded}
									setIsEmailTemplate={this.props.setIsEmailTemplate}
									propsType={"email-template"} />
							</div>
						</div>
						<div className="form-row">
							<EventModalTemplateField
								value={this.state.template && this.state.template.id ? { id: this.state.template.id, name: this.state.template.templateName } : {}}
								accountId={this.state.selectedAccounts && this.state.selectedAccounts[0] && this.state.selectedAccounts[0].data.id}
								rightExtExpandedFull={this.props.rightExtExpandedFull}
								populateTemplate={this.populateTemplate}
								text={this.state.text}
								isMobile={true}
							/>
						</div> </> :
						<div className="form-row">
							<div className="event-modal-email-template-tag-and-select-container" style={{ backgroundColor: this.state.focused ? "#fff" : "rgb(48, 57, 90)" }}>
								<ClientOrAccountTokenField
									matchAccounts={this.taggingMatchTemplatesAccounts}
									accountList={this.state.selectedAccounts}
									accountSuggestions={this.state.associatedAccounts}
									displayAccount={this.taggingRenderAccount}
									onAccountUpdate={this.onAccountUpdate}
									matchClients={() => []}
									isRemovable={this.isClearable}
									acceptSuggestion={this.acceptSuggestion}
									isEmailTemplate={true}
									width="100%"
									minWidth={this.state.template && this.state.template.id ? 180 : 240}
									isTemplate={true}
									removeSingleAccount={this.removeSingleAccount}
									rightExtExpandedFull={this.props.rightExtExpandedFull}
									getFocused={getFocused}
									placeholder="Tag Account"
								/>
								<EventModalTemplateField
									value={this.state.template && this.state.template.id ? { id: this.state.template.id, name: this.state.template.templateName } : {}}
									accountId={this.state.selectedAccounts && this.state.selectedAccounts[0] && this.state.selectedAccounts[0].data.id}
									rightExtExpandedFull={this.props.rightExtExpandedFull}
									populateTemplate={this.populateTemplate}
									text={this.state.text}
								/>
								<EventTypeSelect
									type="email-template"
									updateEventType={(newEventType) => {
										this.setState({ eventType: newEventType, recipientFieldValid: true, emailTemplate: newEventType.name === "email-template" })
									}}
									eventTypes={eventTypes}
									currentEventType={eventType}
									freePosition={true}
									setSidebarExpanded={this.props.setSidebarExpanded}
									setIsEmailTemplate={this.props.setIsEmailTemplate}
									propsType={"email-template"} />
							</div>
						</div> : ""
				}

				{/* New email event recipient */}
				{(isEmailEvent || (isEmailTemplate && this.state.selectedAccounts.length > 0)) && (
					<CRMErrorMessage message={recipientErrorMessage} isValid={recipientFieldValid} shouldShow>
						<EmailTaggingField
							matchAccounts={this.taggingMatchAccounts}
							displayAccount={this.taggingDisplayAccount}
							onAccountUpdate={this.onAccountUpdate}
							matchClients={this.taggingMatchClients}
							toClientList={this.state.toClientList}
							ccClientList={this.state.ccClientList}
							bccClientList={this.state.bccClientList}
							toEmailList={this.state.toEmailList}
							ccEmailList={this.state.ccEmailList}
							bccEmailList={this.state.bccEmailList}
							toAccountList={this.state.toAccountList}
							ccAccountList={this.state.ccAccountList}
							bccAccountList={this.state.bccAccountList}
							onToAdded={this.onToAdded}
							onCcAdded={this.onCcAdded}
							onBccAdded={this.onBccAdded}
							displayClient={this.taggingDisplayClient}
							onClientUpdate={this.onClientUpdate}
							isRemovable={this.isClearable}
							accountsWithEmailsOnly
							isEmailTemplate={isEmailTemplate}
						/>
					</CRMErrorMessage>
				)}
				{/* Temp quick fix to disable drop zone ability on reminder events   */}
				{(eventType.name !== "reminder" ? connectDropTarget : (comp) => comp)(
					<div className="form modal-form">
						{eventType.location ? (
							<div className="form-row">
								<div className="form-col-grow">
									<CRMErrorMessage message={locationErrorMessage} isValid={locationFieldValid} shouldShow>
										<LocationField
											isClearable
											placeholder={i("Location")}
											value={this.state.location && this.state.location.text ? this.state.location : null}
											onUpdate={this.onLocationUpdate.bind(this)}
											hideLocatable={false}
										/>
									</CRMErrorMessage>
								</div>
							</div>
						) : (
							""
						)}
						{eventType.datetime ? (
							<div className="form-row">
								<div className="form-col-grow">
									<CRMErrorMessage message={dateTimeErrorMessage} isValid={dateTimeFieldValid} shouldShow>
										<DateTimeField
											date={this.state.date}
											time={this.state.time}
											isNow={this.state.isNow}
											onDateChange={(date) => this.updateState({ date })}
											onTimeChange={(time) => this.updateState({ time })}
											onNowClick={(isNow) => this.updateState({ isNow })}
											nowButton={true}
										/>
									</CRMErrorMessage>
								</div>
							</div>
						) : (
							""
						)}
						{eventType.clientaccount ? (
							<React.Fragment>
								<div className="form-row">
									<div className="form-col-grow">
										<CRMErrorMessage message={accClientErrorMessage} isValid={accClientFieldValid} shouldShow>
											<ClientOrAccountTokenField
												matchAccounts={this.taggingMatchAccounts}
												accountList={this.state.selectedAccounts}
												accountSuggestions={this.state.associatedAccounts}
												displayAccount={this.taggingRenderAccount}
												onAccountUpdate={this.onAccountUpdate}
												matchClients={this.taggingMatchClients}
												clientList={this.state.selectedClients}
												clientSuggestions={this.state.associatedClients}
												displayClient={this.taggingRenderClient}
												onClientUpdate={this.onClientUpdate}
												isRemovable={this.isClearable}
												acceptSuggestion={this.acceptSuggestion}
												taggedOrder={this.state.ordersToBeLinked && this.state.ordersToBeLinked.length > 0}
												turnOffCashOptions
											/>
										</CRMErrorMessage>
									</div>
								</div>
							</React.Fragment>
						) : (
							""
						)}
						<div className="form-row" style={this.state.attachments.length === 0 && isEmailTemplate ? { paddingBottom: 35 } : null}>
							<div className="form-col-grow">
								<CRMErrorMessage message={noteErrorMessage} isValid={noteFieldValid} shouldShow>
									<div className={`event-notes ${this.state.emailTemplate ? this.props.isMobile ? "email-template-editor mobile-view" : "email-template-editor" : ""}`} name="event-notes">
										{/* TODO: enable autofocus for better user experience */}
										<EventNoteEditor
											onTextChange={this.onTextChange}
											content={this.state.text}
											onClientSelect={this.autoSelectClient}
											onClientRemove={this.autoRemoveClient}
											clients={this.state.clients}
											onAccountSelect={this.autoSelectAccount}
											onAccountRemove={this.autoRemoveAccount}
											accounts={this.state.accounts}
											showSignatureButton={isEmailEvent || isEmailTemplate}
											extraButtons={
												<>
													{this.state.eventType.attachment && (
														<SlateAttachmentButton
															onLocalFileSelect={this.fileAttached}
															on3SkyeFileSelect={() =>
																this.props.storage.get("crm.modals").show(ModalTypes.FileAttachment, {
																	eventType: this.state.eventType.type,
																	initType: "unfinished",
																	state: {
																		...this.state,
																	},
																})
															}
														/>
													)}
													{(isEmailEvent || isEmailTemplate) && (
														<Tooltip label="Highlight" delay={500}>
															<MarkButton format="highlight" icon="format_ink_highlighter" symbolIcon />
														</Tooltip>
													)}
													{(this.state.userRole === "ADVS" || this.state.userRole === "ASST") && canTagAccounts && <SlateAddOrderOrAssetButton
														updateOrdersToBeLinked={this.updateOrdersToBeLinked}
														ordersTobeLinked={this.state.ordersToBeLinked}
														disabled={this.state.selectedAccounts.length !== 1 || this.state.selectedClients.length === 0}
														disabledMessage={this.state.selectedAccounts.length === 0 && this.state.selectedClients.length === 0 ? "Please, tag an account and a client first" : this.state.selectedAccounts.length > 1 ? "Only 1 Account can be tagged to an Order" : this.state.selectedClients.length === 0 ? "Please tag a client first" : this.state.selectedAccounts.length === 0 ? "Please tag an account first" : null}
														size={"small-medium"}
														accountId={this.state.selectedAccounts && this.state.selectedAccounts.length > 0 && this.state.selectedAccounts[0].data.id}
													/>}
												</>
											}
										/>
									</div>
								</CRMErrorMessage>
							</div>
						</div>
						{this.state.attachments.length > 0 && <UploadList data={this.state.attachments} removeFile={this.removeFile} isTemplate={this.state.emailTemplate} />}
						{eventType.type === "workspace-note" || eventType.type === "reminder" ? (
							""
						) : (
							<>
								<div className="form-row" style={{ display: "none" }}>
									{/* <div className="form-col-grow">	
							<label className="checkbox-field">
							<input disabled={!this.state.canShare} title={this.state.canShare ? null : "Unable to email as email integration has not been activated"} type="checkbox" checked={this.state.sendAsEmail} onChange={e=>this.updateState({sendAsEmail: e.target.checked})} />{i("SendAsEmail")}</label>
						</div> */}
									{eventType.adviceable && (
										<div className="form-col-nogrow">
											<div className="modal-field event-modal-advice">
												<Checkbox
													label={i("Advice")}
													checked={this.state.adviceCheckActive}
													onChange={(e) => this.setState({ adviceCheckActive: !this.state.adviceCheckActive })}
												/>
											</div>
										</div>
									)}
								</div>
								<div className="form-row" style={{ display: "none" }}>
									<RecordAdviceChecklist
										recordAsAdviceSteps={(this.state.recordAsAdviceSteps && this.state.recordAsAdviceSteps.recordSteps) || null}
										isChecklist={(this.state.recordAsAdviceSteps && this.state.recordAsAdviceSteps.isChecklist) || null}
										onCloseClick={(e) => this.setState({ adviceCheckActive: !this.state.adviceCheckActive })}
										onChange={(e) => this.setState({ adviceCheckConfirmed: e })}
										checked={this.state.adviceCheckConfirmed}
										classNames={"record-advice-mobile record-advice-content" + (this.state.adviceCheckActive ? " record-advice-active" : "")}
									/>
								</div>
							</>
						)}

						{canTagAccounts && orders && orders.length > 0 && <div>{orders.map((order, index) => renderOrder(order, index, this.updateOrdersToBeLinked, "small", true))}</div>}

						{canTagAccounts && <div style={{ marginBottom: "2px" }}>
							<Tooltip label={this.state.adviceCheckConfirmed ? "Remove advice flag" : "Flag as advice"}>
								<div className={`link-order-email-advice-icon ${this.state.adviceCheckConfirmed ? "advice-active" : "advice-inactive"}`} onClick={() => this.setState({ adviceCheckConfirmed: !this.state.adviceCheckConfirmed })}>
									<i className="material-icons">{this.state.adviceCheckConfirmed ? "how_to_reg" : "person"}</i>
									<p>Advice</p>
								</div>
							</Tooltip>
						</div>}
						{/* } */}

						<div className={this.state.emailTemplate ? this.props.isMobile ? "event-draft-fixed-buttons mobile-mode " : "event-draft-fixed-buttons" : ""}>
							<Button id="modal-finish-btn" icon={isEmailEvent || isEmailTemplate ? "mail" : "done"} onClick={this.finishEvent} classNames="mr-1" disabled={(this.state.eventType.name === "email-template" && this.state.selectedAccounts.length === 0) || isDisabled}>
								{isEmailEvent || isEmailTemplate ? "Send" : eventType.type === "workspace-note" ? i("Close") : i("Finish")}
							</Button>
							{eventType.draftable ? (
								<Button icon="save" type="secondary" onClick={this.saveDraftEvent} classNames="mr-3">
									{i("Save")}
								</Button>
							) : null}
							{/* {this.state.eventType.attachment ? (
							<FilePicker onChange={this.fileAttached} >
								<Button
									transparent
									icon="attachment"
									classNames="ml-auto"
									buttonMobile="small"
									onClick={e => null}
								>{i("Attachment")}</Button>
							</FilePicker>
						) : null} */}
						</div>
					</div>
				)}
			</div>
		);
	}
}
export default withData(withToaster(withRemote(withStorage(withI18n(DropTarget(NativeTypes.FILE, eventDocsTarget, collect)(CreateEventExtension))))));
