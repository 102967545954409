import React from 'react';
import { withI18n } from "@threeskye/global";
import "./StatusTag.scss";

function StatusTag(props) {
	const { status, className, children, label, type } = props;
	const i = props.i18n.get;

	return (
		<div className={`status-tag ${type ? type : ""} ${status && status.name ? status.name.toLowerCase() : ""}${className ? " " + className : ""} ${status && status.id ? (status.id === 4 ? "prospect" : "") : ""}`}>
			{status && status.id ? (status.id === 3 ? i('Closed') : status.id === 2 ? i('Inactive') : status.id === 1 ? i('Open') : status.id === 4 && i('Prospect')) : (type || children)}
		</div>
	)
}

export default withI18n(StatusTag);