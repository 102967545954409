import React, { Component } from 'react';

class Minimiseds extends Component {
    constructor(props) {
        super(props);
        this.state = {
            child: null,
        }
        this.load = this.load.bind(this);
        this.load();
        this._isMounted = false;
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.component === prevState.component)
            return null;

        return ({component: nextProps.component, child: null});
    }

    componentDidMount() {
        this._isMounted = true;
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.component !== this.props.component) {
            this.load();
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
     }
 
    load() {
        import("../minimised/" + this.props.component)
            .then(module=>{
                this._isMounted && this.setState({child: module.default});
            });
    }
    render() {
        const Child = this.state.child;

        return Child && <Child { ... this.props } />
    }
}

export default Minimiseds;