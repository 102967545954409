import React, { Component } from 'react';
import "./Icons.scss";

class EmlIcon extends Component {
	render(){
		const fillA = { fill: "#7F52A4" };
		const fillB = { fill: "#A06BCA" };
		const fillC = { fill: "#53326D" };

		return (
			<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
				<g id="Layer_1" data-name="Layer 1">
					<path
						id="Shape_Copy_188"
						data-name="Shape Copy 188"
						style={fillA}
						d="M20.15,23.43H6.86a2.14,2.14,0,0,1-2.14-2.14V2.71A2.14,2.14,0,0,1,6.86.57H16.8L22.29,6V21.29A2.14,2.14,0,0,1,20.15,23.43Z"
					/>
					<path id="Shape_Copy_187" data-name="Shape Copy 187" style={fillB} d="M21.54,6.6H16.32V1.43Z" />
					<rect id="Rectangle_5_Copy_156" data-name="Rectangle 5 Copy 156" style={fillC} x="1.71" y="4.01" width="11.82" height="6.02" rx="2.14" />
					<g id="EML" className="icon-text">
						<g className="cls-4">
							<path d="M4.42,6v.49H5.65v.47H4.42v.54H5.78v.48H3.89V5.49H5.78V6Z" />
							<path d="M6.19,5.49h.53l.76,1.38.76-1.38h.52V7.94H8.24V6.41L7.48,7.8,6.72,6.41V7.94H6.19Z" />
							<path d="M11,7.46v.48H9.25V5.49h.53v2Z" />
						</g>
					</g>
					<path
						id="_copy"
						data-name=" copy"
						style={fillB}
						d="M17,20.5H10.09a.85.85,0,0,1-.86-.86v-4.3a.92.92,0,0,1,.11-.43.89.89,0,0,1,.31-.3l3.88-2.43,3.87,2.43a.89.89,0,0,1,.31.3.82.82,0,0,1,.12.43v4.3a.86.86,0,0,1-.86.86Zm-6.88-4.15v3.29H17V16.35l-3.44,2.16Zm3.44-3.15-3.44,2.14,3.44,2.15L17,15.34Z"
					/>
				</g>
			</svg>
		);
	}
}
export default EmlIcon;
