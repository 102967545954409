import React, { Component } from 'react';
import { withStorage } from '@threeskye/global';
import { unimpersonate } from '../utils/AdminUtils';
import ViewSelector from '../ViewSelector';

class ImpersonationWrapper extends Component {

    logout(storage) {
        unimpersonate(storage);
    }

    render() {
        let { logout, ...others} = this.props;

        //Intercept logout
        return <ViewSelector {...others} logout={()=>this.logout(this.props.storage)} />
    }
}

export default withStorage(ImpersonationWrapper);