import React, { useEffect, useState } from 'react';
import SingleNotification from '../../Dashboard/DashboardModules/NotificationList/SingleNotification';
import { SideNavSection } from '../SideNavExtension';
import { withI18n, withRemote, withStorage } from '@threeskye/global';
import { ModalTypes } from '../../../utils/ModalUtils';
import { eventTypes } from '../../../utils/EventUtils';
import ExtensionEmptyMessage from './ExtensionEmptyMessage';
import LoadingIcon from '@threeskye/loading-icon';
import FlexWrapper from '../../Shared/FlexWrapper';

const DraftsExtension = (props) => {

    const [loading, setLoading] = useState(true)

    useEffect(() => {
        updateDraftList();
    }, [])

    const updateDraftList = () => {
        props.remote.get("/modules/crm/provider/draft-events").then(results => {
            props.storage.put("/modules/crm/provider/draft-events", results);
            getDraftList(results)
            setLoading(false)
        });
    }

    /**
     * @author tommy
     * @param {*} results
     * get the list of drafts and convert into modals
     * then put them into the modals.inactive[] 
     */
    const getDraftList = (results) => {
        props.modals.clearInactive();
        if (results && results.length > 0) {
            results.map((draft, index) => {
                const draftWithFixedEntities = { ...draft, accounts: draft.accounts.map(e => e.entityId), clients: draft.clients.map(e => e.entityId), nonPersonClients: draft.nonPersonClients.map(e => e.entityId) }
                const modal = convertDraftIntoModal(draftWithFixedEntities)
                props.modals.addInactive(ModalTypes.CreateEvent, modal);
            });
        }
    }

    const convertDraftIntoModal = (draft) => {
        const attachments = [];
        draft.attachments.forEach(attachment => {
            const { filename, s3key } = attachment;
            attachments.push({ name: filename, s3Key: s3key, uploadComplete: true, progress: 100, status: '' });
        });

        const modal = {
            initType: "draft",
            type: {
                minimisedComponent: "MinimisedEvent"
            },
            preload: {
                id: draft.id,
                created: new Date(),
                date: draft.dateString,
                time: (draft.dateString && draft.hasTime) || (!draft.dateString && draft.timeString) ? draft.timeString : null,
                location: {
                    text: draft.location,
                    source: "draft"
                },
                text: JSON.parse(draft.content),
                header: draft.heading,
                sendAsEmail: draft.sendAsEmail,
                draftClients: draft.clients,
                draftNonPersonClients: draft.nonPersonClients,
                draftAccounts: draft.accounts,
                attachments: attachments,
                adviceCheckConfirmed: draft.advice,
                emails: draft.emails,
                /**
                 * Event type conversion.
                 * phonecall[1] ==> phone-call[0]
                 * meeting[2] ==> meeting[1]
                 * Note[3] ==> file-note[2]
                 * 
                 */
                eventType: draft.emailTemplate ? eventTypes[7] : eventTypes[draft.type.id - 1],
                emailTemplate: draft.emailTemplate,
                linkedOrders: draft.linkedOrders,
                ordersToBeLinked: draft.linkedOrders,
                adviceCheckConfirmed: draft.linkedOrders.length > 0
            },
            state: {
                id: draft.id,
                eventType: draft.emailTemplate ? eventTypes[7] : eventTypes[draft.type.id - 1],
                header: draft.heading,
                date: draft.dateStringForSidebar,
                time: (draft.dateString && draft.hasTime) || (!draft.dateString && draft.timeString) ? draft.timeString : null,
                emailTemplate: draft.emailTemplate,
            }
        };
        if (draft && draft.emails) {
            modal.preload.emails = draft.emails
        }
        return modal;
    }

    const onItemClick = (modal) => {
        props.openUnfinishedEventInExtension(modal, "drafts")
    }

    const onItemDelete = (index, event) => {
        event.stopPropagation();
        const eventId = props.modals.inactive[index].data.state.id;
        props.remote.delete("/modules/crm/provider/draft-events/" + eventId).then(() => {
            updateDraftList();
        });
    }

    const deleteAll = () => {
        const numItems = props.modals.inactive.length;
        const eventIds = []
        for (let i = 0; i < numItems; i++) {
            eventIds.push(props.modals.inactive[i].data.state.id);
        }
        props.remote.post("/modules/crm/provider/draft-events", eventIds).then(() => {
            updateDraftList();
        });
    }

    const getIcon = (name) => {
        switch (name) {
            case "meeting": return "date_range";
            case "workspace-note": return "book";
            // case "": return "book";
            case "file-note": return "book";
            case "phone-call": return "phone";
            case "email-template":
            case "email": return "email";


            default:
                console.error("Could not find an icon for ", name);
                return "";
        }
    }

    const getTypeClass = (name) => {
        switch (name) {
            case "meeting": return "meeting";
            case "workspace-note": return "note";
            // case "": return "book";
            case "file-note": return "file-note";
            case "phone-call": return "phone";
            case "email": return "email";
            case "email-template": return "email-template"


            default:
                console.error("Could not find a type for ", name);
                return "";
        }
    }
    if (loading) {
        return (
            <div className="side-nav-wrapper">
                <FlexWrapper fullWidth justify="center"><LoadingIcon size={40} /></FlexWrapper>
            </div>
        )
    }


    return (
        <div className="side-nav-wrapper">
            <SideNavSection header="Today">
                {props.modals.inactive && props.modals.inactive.length > 0
                    ? props.modals.inactive.map((modal, idx) => {
                        let title = modal?.data?.state?.header || ""
                        //    let dateArray = modal && modal.data && modal.data.state && modal.data.state.date && modal.data.state.date.split("/") 
                        //    let timeArray = modal && modal.data && modal.data.state && modal.data.state.time && modal?.data?.state?.time.split(":") 
                        //    let date = timeArray && dateArray ? formatDistance(new Date, set(new Date(), {day: dateArray[0], month: dateArray[1]-1, year: dateArray[2], hours: timeArray[0], minutes: timeArray[1]})) : ""
                        let date = `${modal && modal.data && modal.data.state && modal.data.state.date ? modal.data.state.date : ""} ${modal && modal.data && modal.data.state && modal.data.state.time ? modal.data.state.time : ""}`
                        return (
                            <SingleNotification
                                key={idx}
                                icon={getIcon(modal.data.state.eventType.name)}
                                title={title}
                                // date={date}
                                data={"notification"}
                                subtitle={date}
                                type={getTypeClass(modal.data.state.eventType.name)}
                                onClick={() => onItemClick(modal)}
                                onDelete={(event) => onItemDelete(idx, event)}
                            />
                        )
                    })
                    : <ExtensionEmptyMessage header="You have no event drafts" clickableMessage="Try creating an event now" onMessageClick={() => props.createEvent()} />
                }
            </SideNavSection>
        </div>
    );
}

export default withStorage(withRemote(withI18n(DraftsExtension)));