import React, {Component} from 'react';
import { withRemote } from '@threeskye/global';

class ProfileImage extends Component {

    render() {
		const user = this.props.user;
		let token = this.props.remote.createTokenHeader().headers["X-3Skye-Session"];

		var profileImageStyle = user.hasPhoto ?
			{
				backgroundImage: "url(/api/configuration/current-user/" + token + "/" + user.userId + "/photo.png)"
			} :
			{};
		var profileImageClass = user.hasPhoto
			? "profile-img-wrapper"
			: "profile-img-wrapper no-image";
		var profileImageContent = user.hasPhoto
			? ""
			: (user.preferredName ? user.preferredName.substring(0, 1) : user.givenName.substring(0,1)) + user.familyName.substring(0, 1);

        return <div className={profileImageClass} style={profileImageStyle}>
            {profileImageContent}
        </div>;
    }
}

export default withRemote(ProfileImage);