import React, { Component } from 'react';
import "./AddableFieldWrapper.scss";

class AddableFieldWrapper extends Component {
	state = {  }
	render() { 
		return ( 
			<div className="addable-fields-wrapper" style={{marginTop: this.props.noLabel ? "" : "12px"}}>
				{this.props.children.map((input, idx) =>
					<div key={idx} className="single-field-wrapper">
						{input}
					</div>
				)}
			</div>
		 );
	}
}

export default AddableFieldWrapper;