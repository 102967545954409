import React from 'react';
import "./FlexWrapper.scss";

const FlexWrapper = ({ children, gap, columnGap, rowGap, direction, wrap, justify, align, className, onClick, fullWidth, style, width, id, forwardRef }) => {
	let wrapperClass = "";

	if (className) {
		wrapperClass += (className + " ");
	}
	
	if (gap) {
		wrapperClass += "flex-wrapper-gap-" + gap;
	}
	if (columnGap) {
		wrapperClass += "flex-wrapper-column-gap-" + columnGap;
	}
	if (rowGap) {
		wrapperClass += "flex-wrapper-row-gap-" + rowGap;
	}
	return (
		<div
			onClick={onClick}
			style={{ ...style, display: "flex", flexDirection: direction, flexWrap: wrap && "wrap", justifyContent: justify, alignItems: align ? align : "center", width: fullWidth ? "100%" : width}}
			className={wrapperClass}
			id={id}
			ref={forwardRef}
		>
			{children}
		</div>
	);
};

export default FlexWrapper;