import React, { Component } from 'react';
import "./Icons.scss";

class JpgIcon extends Component {
	render(){
		const fillA = { fill: "#4EBDC7" };
		const fillC = { fill: "#1EAEBA" };
		const fillB = { fill: "#68D7E0" };

		return (
			<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
				<g id="Layer_1" data-name="Layer 1">
					<path
						id="Shape_Copy_138"
						data-name="Shape Copy 138"
						style={fillA}
						d="M20.15,23.43H6.86a2.14,2.14,0,0,1-2.14-2.14V2.71A2.14,2.14,0,0,1,6.86.57H16.8L22.29,6V21.29A2.14,2.14,0,0,1,20.15,23.43Z"
					/>
					<path id="Shape_Copy_137" data-name="Shape Copy 137" style={fillB} d="M21.54,6.6H16.32V1.43Z" />
					<rect id="Rectangle_5_Copy_110" data-name="Rectangle 5 Copy 110" style={fillC} x="1.71" y="4.01" width="11.82" height="6.02" rx="2.14" />
					<g id="PNG" className="cls-4">
						<g className="icon-text">
							<path d="M4.25,7.79l.34-.34A.5.5,0,0,0,5,7.72a.37.37,0,0,0,.37-.41V5.7h.53V7.35a.81.81,0,0,1-.84.85A1,1,0,0,1,4.25,7.79Z" />
							<path d="M6.39,5.7h1.1a.84.84,0,1,1,0,1.66H6.91v.79H6.39Zm1,1.17c.27,0,.44-.13.44-.34s-.17-.35-.44-.35H6.91v.69Z" />
							<path
								d="M9.87,6.77H11v1a1.38,1.38,0,0,1-1,.42A1.32,1.32,0,0,1,8.65,6.92,1.31,1.31,0,0,1,10,5.66a1.37,1.37,0,0,1,1,.41l-.38.34A.84.84,0,0,0,10,6.14a.79.79,0,0,0-.77.78.8.8,0,0,0,.77.8.84.84,0,0,0,.48-.16V7.19H9.87Z"
							/>
						</g>
					</g>
					<path
						id="_copy_4"
						data-name=" copy 4"
						style={fillB}
						d="M16.53,20.34h-6a.82.82,0,0,1-.6-.25.83.83,0,0,1-.26-.61v-6a.84.84,0,0,1,.25-.6.79.79,0,0,1,.61-.25h6a.81.81,0,0,1,.6.24.83.83,0,0,1,.26.61v6a.87.87,0,0,1-.86.86ZM12,17.12h0l-1.5,1.93h6L14.6,16.47l-1.5,1.94L12,17.12Z"
					/>
				</g>
			</svg>
		);
	}
}
export default JpgIcon;
