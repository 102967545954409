import React, { useEffect, useState } from 'react';
import { withStorage } from '@threeskye/global';
import DropDown from '../Shared/DropDown';


const EventModalTemplateField = ({ value, storage, accountId, rightExtExpandedFull, populateTemplate, text, isMobile }) => {

	useEffect(() => {
		accountId && storage.getOrFetch("/modules/crm/email/templates/" + accountId).then(response => {
			// auto select template
			if (response.data && response.data.length > 0) {
				storage.getOrFetch(`/modules/crm/email/template/${response.data[0].id}`).then(resp => {
					if (resp && resp.data) {
						const emtyTextBox = text.length === 1 && text[0].children.length === 1 && text[0].children[0].text === ""
						populateTemplate(resp.data, text.length, emtyTextBox)
					}
				})
			}
		})
	}, [accountId])


	return (

		<div id="emtf-template-wrapper" style={{ minWidth: isMobile ? "none" : !value.name ? 125 : rightExtExpandedFull ? 310 : 180 }}>
			<div className='template-label'>
				{ value.name || "Template"}
			</div>
		</div>

	)
}

export default withStorage(EventModalTemplateField);