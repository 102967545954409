import React, { useEffect, useState } from 'react';
import SingleNotification from '../../Dashboard/DashboardModules/NotificationList/SingleNotification';
import { SideNavSection } from '../SideNavExtension';
import { withThreeSkyeGlobal } from "@threeskye/global";
import { withData } from "../../../crm/ThreeSkyeCRM";
import withRouteChange from "@threeskye/route-change";
import { isSameDay, parseISO, set, subDays, format, isBefore } from 'date-fns';
import { ModalTypes } from '../../../utils/ModalUtils';
import ExtensionEmptyMessage from './ExtensionEmptyMessage';
import LoadingIcon from '@threeskye/loading-icon';
import FlexWrapper from '../../Shared/FlexWrapper';
import AssembleNotifications from '../../Dashboard/DashboardModules/NotificationList/AssembleNotifications';
import AssembleRequestNotifications from '../../Dashboard/DashboardModules/NotificationList/AssembleRequestNotifications'
import ChangeRequestIcon from '../../Shared/ChangeRequestIcon';


const NotificationsExtension = (props) => {
	const [pinned, setPinned] = useState([])
	const [notificationsToday, setNotificationsToday] = useState([])
	const [notificationsYesterday, setNotificationsYesterday] = useState([])
	const [notificationsEarlier, setNotificationsEarlier] = useState([])
	const [attentionRequiredsToday, setAttentionRequiredsToday] = useState([])
	const [attentionRequiredsYesterday, setAttentionRequiredsYesterday] = useState([])
	const [attentionRequiredsEarlier, setAttentionRequiredsEarlier] = useState([])

	const [loading, setLoading] = useState(true)

	const noNotifications = (!notificationsToday || notificationsToday.length < 1) && (!notificationsYesterday || notificationsYesterday.length < 1) && (!notificationsEarlier || notificationsEarlier.length < 1) && (!attentionRequiredsToday || attentionRequiredsToday.length < 1) && (!attentionRequiredsYesterday || attentionRequiredsYesterday.length < 1) && (!attentionRequiredsEarlier || attentionRequiredsEarlier.length < 1)

	const arrangeNotifications = (list) => {
		const today = set(new Date(), { hours: 0, minutes: 0, seconds: 0, milliseconds: 0 })
		const yesterday = subDays(today, 1)
		if (list) {
			setNotificationsToday(list.filter((n) => isSameDay(parseISO(n.triggeredDate), today)))
			setNotificationsYesterday(list.filter((n) => isSameDay(parseISO(n.triggeredDate), yesterday)))
			setNotificationsEarlier(list.filter((n) => isBefore(parseISO(n.triggeredDate), yesterday)))
		}
	}
	const arrangeAttentionRequireds = (list) => {
		const today = set(new Date(), { hours: 0, minutes: 0, seconds: 0, milliseconds: 0 })
		const yesterday = subDays(today, 1)
		if (list) {
			setAttentionRequiredsToday(list.filter((n) => isSameDay(parseISO(n.triggeredDate), today)))
			setAttentionRequiredsYesterday(list.filter((n) => isSameDay(parseISO(n.triggeredDate), yesterday)))
			setAttentionRequiredsEarlier(list.filter((n) => isBefore(parseISO(n.triggeredDate), yesterday)))
		}
	}

	useEffect(() => {
		Promise.all([
			props.storage.getOrFetch("/modules/crm/provider/notifications"),
			props.storage.getOrFetch("/modules/crm/provider/attention-required")
		])
			.then(([n, ar]) => {
				arrangeNotifications(n)
				arrangeAttentionRequireds(ar)
				setLoading(false)
			})
		props.storage.watch("/modules/crm/provider/notifications", arrangeNotifications);
		props.storage.watch("/modules/crm/provider/attention-required", arrangeAttentionRequireds);
		return () => {
			props.storage.unwatch("/modules/crm/provider/notifications");
			props.storage.unwatch("/modules/crm/provider/attention-required");
		}
	}, [])

	const getIcon = (name, state, cr) => {
		switch (name) {
			case "birthday": return "cake";
			case "clientNote": return "book";
			case "phoneCall": return "phone";
			case "document": return "insert_drive_file";
			case "warning": return "warning";
			case "review": return "date_range";
			case "order": return "monetization_on";
			case "change": return <ChangeRequestIcon state='primary' cr={cr} />;
			case "changeGroup": return <ChangeRequestIcon state={state} cr={cr} />;
			case "contact": return "textsms";
			case "workflow": return "update";
			default:
				console.error("Could not find an icon for ", name);
				return "";
		}
	}

	const onClick = (notification, justDelete) => {
		//Delete it
		if (props.deleteOnClick) {
			props.remote.send("delete", "/modules/crm/provider/notifications" + "/" + notification.id).then(refresh);
		}

		if (notification.link && !justDelete) {
			const { getClientById, getAccountById, getNonPersonClientById, getClientLink, getAccountLink, getNonPersonLink } = props;
			if (notification.link.indexOf("account:") === 0) {
				const id = notification.link.substring(8, notification.link.indexOf(':', 8));
				getAccountById(id, props.storage).then(account => {
					const link = getAccountLink(account);
					const restOfLink = notification.link.substring(notification.link.indexOf(':', 8) + 1);
					props.changeRoute(link + restOfLink);
				});
			} else if (notification.link.indexOf("client:") === 0) {
				const id = notification.link.substring(7, notification.link.indexOf(':', 7));
				getClientById(id, props.storage).then(client => {
					const link = getClientLink(client);
					const restOfLink = notification.link.substring(notification.link.indexOf(':', 7) + 1);
					props.changeRoute(link + restOfLink);
				});
			} else if (notification.link.indexOf("nonpersonal:") === 0) {
				const id = notification.link.substring(12, notification.link.indexOf(':', 12));
				getNonPersonClientById(id, props.storage).then(client => {
					const link = getNonPersonLink(client);
					const restOfLink = notification.link.substring(notification.link.indexOf(':', 12) + 1);
					props.changeRoute(link + restOfLink);
				});
			} else if (notification.link.includes("/workflows/in-progress")) {
				const id = +notification.link.split("/")[3]

				props.storage.getOrFetch("/modules/crm/provider/workflows/" + id).then(params => {
					const { getClientById, getNonPersonClientById, getAccountById } = props;
					const modalParams = { ...params, notification, getClientById, getNonPersonClientById, getAccountById, onNotifsUpdate: refreshAttentionRequired };
					props.storage.get("crm.modals").show(
						ModalTypes["UpdateRequestModal"], modalParams)
				});

			} else {
				props.changeRoute(notification.link);
			}
		}

		if (notification.modal) {
			props.storage.getOrFetch(notification.modalParams).then(params => {
				const { getClientById, getNonPersonClientById, getAccountById } = props;
				const modalParams = { ...params, notification, getClientById, getNonPersonClientById, getAccountById, onNotifsUpdate: refreshAttentionRequired };
				props.storage.get("crm.modals").show(
					ModalTypes[notification.modal], modalParams);
				//for workflow, this is UpdateRequestModal, {changeList, attachments,changeNote}
			});
		}
	}

	const onDelete = (event, notification) => {
		event.stopPropagation()
		props.remote.send("delete", "/modules/crm/provider/notifications/" + notification.id).then(refresh);
	}

	const onDeleteAttentionRequired = (event, notification) => {
		event.stopPropagation()
		props.remote.send("delete", "/modules/crm/provider/attention-required/" + notification.id).then(refreshAttentionRequired);
	}

	const refresh = () => {
		props.storage.refresh("/modules/crm/provider/notifications")
	}

	const refreshAttentionRequired = () => {
		props.storage.refresh("/modules/crm/provider/attention-required")
	}


	if (loading) { return <FlexWrapper fullWidth justify="center"><LoadingIcon size={40} /></FlexWrapper> }

	return (
		<div className="side-nav-wrapper">
			{noNotifications && <ExtensionEmptyMessage icon="task_alt" iconColor="#30C9AC" header="You’re all up to date!" message="Please check again later" />}
			{pinned && pinned.length > 0 && (
				<SideNavSection header="Pinned" icon="push_pin">
					{pinned.map((notification, idx) => (
						<SingleNotification
							title={notification.text}
							subtitle={format(parseISO(notification.triggeredDate), "dd/MM/yyyy")}
							icon={getIcon(notification.type)}
							onClick={onClick}
							onDelete={onDelete}
							deleteOnClick={false}
							key={idx}
							date={notification.date}
							data={notification}
							type={notification.type}
							pinned
						/>
					))}
				</SideNavSection>
			)}
			{((notificationsToday && notificationsToday.length > 0) || (attentionRequiredsToday && attentionRequiredsToday.length > 0)) && (
				<SideNavSection header="Today">
					{notificationsToday && <AssembleNotifications
						list={notificationsToday}
						onClick={onClick}
						onDelete={onDelete}
						getIcon={getIcon}
						timePeriod={"Today"}
					/>}

					{attentionRequiredsToday && <AssembleRequestNotifications
						list={attentionRequiredsToday}
						getIcon={getIcon}
						onClick={onClick}
						onDelete={onDeleteAttentionRequired}
						timePeriod={"Today"}
					/>}
				</SideNavSection>
			)}
			{((notificationsYesterday && notificationsYesterday.length > 0) || (attentionRequiredsYesterday && attentionRequiredsYesterday.length > 0)) && (
				<SideNavSection header="Yesterday">
					{notificationsYesterday && <AssembleNotifications
						list={notificationsYesterday}
						onClick={onClick}
						onDelete={onDelete}
						getIcon={getIcon}
						timePeriod={"Yesterday"}
					/>}

					{attentionRequiredsYesterday && <AssembleRequestNotifications
						list={attentionRequiredsYesterday}
						geIicon={getIcon}
						onClick={onClick}
						onDelete={onDeleteAttentionRequired}
						timePeriod={"Yesterday"}
					/>}
				</SideNavSection>
			)}
			{((notificationsEarlier && notificationsEarlier.length > 0) || (attentionRequiredsEarlier && attentionRequiredsEarlier.length > 0)) && (
				<SideNavSection header="Earlier">
					{notificationsEarlier && <AssembleNotifications
						list={notificationsEarlier}
						onClick={onClick}
						onDelete={onDelete}
						getIcon={getIcon}
						timePeriod={"Earlier"}
					/>}

					{attentionRequiredsEarlier && <AssembleRequestNotifications
						list={attentionRequiredsEarlier}
						getIcon={getIcon}
						onClick={onClick}
						onDelete={onDeleteAttentionRequired}
						timePeriod={"Earlier"}
					/>}
				</SideNavSection>
			)}
		</div>
	);
}

export default withData(withThreeSkyeGlobal(withRouteChange(NotificationsExtension)));