import React, {Component} from 'react';
import {withI18n, withRemote, withStorage} from "@threeskye/global";
import Button from "@threeskye/buttons";

import './SetPasswordPage.scss';
import logo from './logo.png';

class InvalidCodePage extends Component {

	constructor(props) {
		super(props);

		this.state = {
		}

		this.onLogin = this.onLogin.bind(this);

	}

	onLogin() {
	  this.props.switchPage('');
	}


	render() {
		return (
		<div id="master-container">
			<div id="header">
						<div className="logo-container">
							<img src={logo} alt="logo" className="logo" />
						</div>
			</div>
			<div id="content">
				<div id="welcome-message" className="content-wrapper">
					<h1 className="mb-1">You've entered the wrong code too many times.</h1>
					<p>To prevent abuse, we've locked your account for 30 seconds. Please wait a bit then click the button below to return to the login page.</p>
				</div>
				<div id="login-form" className="form">
					<div className="form-row justify-center">
						<div className="form-col-nogrow">
							<Button icon="arrow_back" onClick={this.onLogin}>Return to the login page</Button>
						</div>
					</div>
				</div>
			</div>
		</div>
	  )
	}
}

export default withStorage(withRemote(withI18n(InvalidCodePage)));
