import React, { useEffect, useState } from 'react';
import SingleNotification from './SingleNotification';
import { withThreeSkyeGlobal } from "@threeskye/global";
import { withData } from '../../../../crm/ThreeSkyeCRM';
import withRouteChange from '@threeskye/route-change';
import { parseISO, format } from 'date-fns';
import { GroupedBirthdayNotification } from './GroupedBirthdayNotification'
import { GroupedChangeNotifications } from './GroupedChangeNotifications'
import ClientAccountLink from '../../../ClientAccount/ClientAccountLink';


const AssembleNotifications = (props) => {
    const { list, getClientById, storage, getAccountById, getNonPersonClientById, onDelete, onClick, getIcon, timePeriod } = props
    const [onlyBirthdays, setOnlyBirthdays] = useState([])
    const [addedList, setAddedList] = useState([])
    const [waiting2ndApproval, setWaiting2ndApproval] = useState([])
    const changes = list.filter(n => n.text.includes("Change approved") || n.text.includes("Change rejected") || n.text.includes("Change has first approval"))
    const waiting2nd = list.filter(n => n.text.includes("Change for"))
    const filteredList = list.filter(n => n.type !== "birthday" && !n.text.includes("Change approved") && !n.text.includes("Change rejected") && !n.text.includes("Change has first approval")  && !n.text.includes("Change for") )

    const substractText = (obj) => {
        const textArray = obj.text.split(".")
        const subArray = textArray[0].split(":")
        const text = subArray[0] + ":" + subArray[1]
        return text
    }

    let getNotificationClientId = (ntf) => {
        if (ntf && ntf.link.includes("client:")) {
            return +ntf.link.split(":")[1]

        } else if (ntf && ntf.link.includes("/clients")) {
            return +ntf.link.split("/")[2]
        }
    }

    let getNotificationAccountId = (ntf) => {
        if (ntf && ntf.link.includes("account:")) {
            return +ntf.link.split(":")[1]

        } else if (ntf && ntf.link.includes("/accounts")) {
            return +ntf.link.split("/")[2]
        }
    }
    let getNotificationNPEId = (ntf) => {
        if (ntf && ntf.link.includes("nonpersonal:")) {
            return +ntf.link.split(":")[1]

        } else if (ntf && ntf.link.includes("/non-person")) {
            return +ntf.link.split("/")[2]
        }
    }


    useEffect(() => {
        const onlyBDNotifications = list.filter(n => n.type === "birthday")

        Promise.all(onlyBDNotifications.map(notification => {
            let clientId = getNotificationClientId(notification)
            return getClientById(clientId, props.storage)
        }
        )).then((resp) => {
            let birthdaysWithClientData = onlyBDNotifications.map(n => {
                let clientData = resp.find(c => c.id === getNotificationClientId(n))
                return { ...n, clientData }
            })
            setOnlyBirthdays(birthdaysWithClientData)
        })

    }, [list])

    useEffect(() => {
        Promise.all(
            changes && changes.map(notification => {
                let clientId = getNotificationClientId(notification)
                let accountId = getNotificationAccountId(notification)
                let npeId = getNotificationNPEId(notification)

                if (notification.link.includes("account")) {
                    return getAccountById(accountId, props.storage)
                } else if (notification.link.includes("client")) {
                    return getClientById(clientId, props.storage)
                } else if (notification.link.includes("non")) {
                    return getNonPersonClientById(npeId, props.storage)
                }
            })
        ).then((resp) => {
            const noUndefinedResps = resp.filter(r => r !== undefined)
            const notificationWithExtraInfo = changes && changes.map(n => {
                if (n && n.link.includes("account:")) {
                    const accountNtf = noUndefinedResps.find(r => {   
                        if (n.link === "account:" + r.id + ":") {
                            return true
                        } 
                    })
                    return { ...n, clientAccountToken: accountNtf }

                } else if (n && n.link.includes("/accounts")) {
                    const accountNtf = noUndefinedResps.find(r => {
                        if (n.link === "/accounts/" + r.id) {
                            return true
                        }
                    })
                    return { ...n, clientAccountToken: accountNtf }

                } else if (n && n.link.includes("client:")) {
                    const clientNtf = noUndefinedResps.find(r => {
                       
                        if (n.link === "client:" + r.id + ":") {
                            return true
                        }
                    })
                    return { ...n, clientAccountToken: clientNtf }

                } else if (n && n.link.includes("/clients")) {
                    const clientNtf = noUndefinedResps.find(r => {
                        if (n.link === "/clients/" + r.id) {
                            return true
                        }
                    })
                    return { ...n, clientAccountToken: clientNtf }

                } else if (n && n.link.includes("nonpersonal:")) {
                    const npeNtf = noUndefinedResps.find(r => {
                        if (n.link === "nonpersonal:" + r.id + ":") {
                            return true
                        }
                    })
                    return { ...n, clientAccountToken: npeNtf }

                } else if (n && n.link.includes("/nonpersonal")) {
                    const npeNtf = noUndefinedResps.find(r => {
                        if (n.link === "/non-person/" + r.id) {
                            return true
                        }
                    })
                    return { ...n, clientAccountToken: npeNtf }
                }
            })
            setAddedList(notificationWithExtraInfo)
        })
    }, [list])

    useEffect(() => {

    }, [list])

    useEffect(() => {
        const newList = waiting2nd && waiting2nd.map(notification => {
            const changeId = +notification.link.split("/")[3]
            return storage.getOrFetch("/modules/crm/middle-office/workflows/" + changeId).then(params => {
                let change = params.workflow.changes[0]
                let accountPromise = null;
                let clientPromise = null;
                let nonPersonClientPromise = null;
                let changeDescription = "Change waiting 2nd appoval: " + (change.changeDescription[0].fieldName === "AccountName" ? "Account Name" : change.changeDescription[0].fieldName)

                if (change.changedAccount) {
                    accountPromise = getAccountById(change.changedAccount.id, props.storage);
                } else if (change.changedUser) {
                    clientPromise = getClientById(change.changedUser.id, props.storage);
                } else {
                    nonPersonClientPromise = getNonPersonClientById(change.changedEntity.id, props.storage);
                }

                return Promise.all([
                    accountPromise,
                    clientPromise,
                    nonPersonClientPromise

                ]).then((resp) => {
                    const notificationWithExtraInfo = change.changedAccount && change.changedAccount.id === resp[0].id ?
                        { ...notification, clientAccountToken: resp[0], newText: changeDescription } : change.changedUser && change.changedUser.id === resp[1].id ?
                            { ...notification, clientAccountToken: resp[1], newText: changeDescription } : change.changedEntity && change.changedEntity.id ?
                                { ...notification, clientAccountToken: resp[2], newText: changeDescription } : notification
                    return notificationWithExtraInfo
                })
            })
        })
        Promise.all(newList).then((listWithToken) => setWaiting2ndApproval(listWithToken))
    }, [list])

    const changeApproved = addedList.filter(n => n.text.includes("Change approved"))
    const changeRejected = addedList.filter(n => n.text.includes("Change rejected"))
    const change1stApproval = addedList.filter(n => n.text.includes("Change has first approval"))


    return (
        <>
            {
                onlyBirthdays && (
                    onlyBirthdays.length >= 3
                        ? <GroupedBirthdayNotification notifications={onlyBirthdays} onDelete={onDelete} timePeriod={timePeriod} deleteOnClick={false} />
                        : onlyBirthdays.map((notification, idx) => (
                            <SingleNotification
                                title={notification.text}
                                subtitle={format(parseISO(notification.triggeredDate), "dd/MM/yyyy")}
                                icon={getIcon(notification.type)}
                                onClick={onClick}
                                onDelete={onDelete}
                                deleteOnClick={false}
                                key={idx}
                                date={notification.date}
                                data={notification}
                                type={notification.type}
                            />
                        ))
                )
            }
            {
                changeApproved && (
                    changeApproved.length >= 3
                        ? <GroupedChangeNotifications notifications={changeApproved} onClick={onClick} onDelete={onDelete} timePeriod={timePeriod} deleteOnClick={false} groupIcon={getIcon("change")}/>
                        : changeApproved.map((notification, idx) => (
                            <SingleNotification
                                title="Approved"
                                subtitle={<ClientAccountLink size="small" clientAccount={notification.clientAccountToken} />}
                                icon={getIcon("change")}
                                onClick={onClick}
                                onDelete={onDelete}
                                deleteOnClick={false}
                                key={idx}
                                date={notification.date}
                                data={notification}
                                type={notification.type}
                                tooltip={substractText(notification)}
                            />
                        ))
                )
            }
            {
                changeRejected && (
                    changeRejected.length >= 3
                        ? <GroupedChangeNotifications notifications={changeRejected} onClick={onClick} onDelete={onDelete} timePeriod={timePeriod} deleteOnClick={false} groupIcon={getIcon("change")}/>
                        : changeRejected.map((notification, idx) => (
                            <SingleNotification
                                title="Rejected"
                                subtitle={<ClientAccountLink size="small" clientAccount={notification.clientAccountToken} />}
                                icon={getIcon("change")}
                                onClick={onClick}
                                onDelete={onDelete}
                                deleteOnClick={false}
                                key={idx}
                                date={notification.date}
                                data={notification}
                                type={notification.type}
                                tooltip={substractText(notification)}
                            />
                        ))
                )
            }
            {
                change1stApproval && (
                    change1stApproval.length >= 3
                        ? <GroupedChangeNotifications notifications={change1stApproval} onClick={onClick} onDelete={onDelete} timePeriod={timePeriod} deleteOnClick={false} groupIcon={getIcon("change")}/>
                        : change1stApproval.map((notification, idx) => (
                            <SingleNotification
                                title="1st approval complete"
                                subtitle={<ClientAccountLink size="small" clientAccount={notification.clientAccountToken} />}
                                icon={getIcon("change")}
                                onClick={onClick}
                                onDelete={onDelete}
                                deleteOnClick={false}
                                key={idx}
                                date={notification.date}
                                data={notification}
                                type={notification.type}
                                tooltip={substractText(notification)}
                            />
                        ))
                )
            }
             {
                waiting2ndApproval && (
                    waiting2ndApproval.length >= 3
                        ? <GroupedChangeNotifications notifications={waiting2ndApproval} onClick={onClick} onDelete={onDelete} timePeriod={timePeriod} deleteOnClick={false} groupIcon={getIcon("change")}/>
                        : waiting2ndApproval.map((notification, idx) => (
                            <SingleNotification
                                title="Awaiting Approval"
                                subtitle={<ClientAccountLink size="small" clientAccount={notification.clientAccountToken} />}
                                icon={getIcon("change")}
                                onClick={onClick}
                                onDelete={onDelete}
                                deleteOnClick={false}
                                key={idx}
                                date={notification.date}
                                data={notification}
                                type={notification.type}
                                tooltip={notification.newText}
                            />
                        ))
                )
            }
            {
                filteredList && filteredList.length > 0 && (
                    filteredList.map((notification, idx) => (
                        <SingleNotification
                            title={notification.type === "review" ? `${notification.text.split("by")[0]} by` : notification.text}
                            subtitle={notification.type === "review" ? `${notification.text.split("by")[1]}` : format(parseISO(notification.triggeredDate), "dd/MM/yyyy")}
                            icon={getIcon(notification.type)}
                            onClick={onClick}
                            onDelete={onDelete}
                            deleteOnClick={false}
                            key={idx}
                            date={notification.type === "review" ? format(parseISO(notification.triggeredDate), "dd/MM/yyyy") : notification.date }
                            data={notification}
                            type={notification.type}
                        />
                    )))
            }
        </>

    )
}

export default withData(withThreeSkyeGlobal(withRouteChange(AssembleNotifications)));


