import React, { Component } from 'react';

class SidebarItem extends Component {

    render() {
        return <li onClick={this.props.onClick}>
            {this.props.children}
        </li>;
    }
}

export default SidebarItem;