import React from 'react';

import './EventNoteEditor.scss';
import { withRemote } from '@threeskye/global';
import RichTextEditor from './Slate/RichTextEditor';

const EventNoteEditor = (props) => {
	const initialValue = [
		{
			type: 'paragraph',
			children: [{ text: '' }],
		},
	]

	// Render the Slate context.
	return <RichTextEditor 
		onChange={props.onTextChange} 
		content={props?.content?.length > 0 ? props.content : initialValue} 
		extraButtons={props.extraButtons && props.extraButtons} 
		showSignatureButton={props.showSignatureButton}
		controlled
		className={props.className || null}
		style={props.style || null}
	/>

}

export default withRemote(EventNoteEditor);