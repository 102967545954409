import React, { Component } from 'react';
import "./Icons.scss";

class CsvIcon extends Component {
	render(){
		const fillA = { fill: "#2BA985" };
		const fillB = { fill: "#6ECDB2" };
		const fillC = { fill: "#278C6F" };

		return (
			<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
				<g id="Layer_1" data-name="Layer 1">
					<path
						id="Shape_Copy_32"
						data-name="Shape Copy 32"
						style={fillA}
						d="M20.15,23.43H6.86a2.14,2.14,0,0,1-2.14-2.14V2.71A2.14,2.14,0,0,1,6.86.57H16.8L22.29,6V21.29A2.14,2.14,0,0,1,20.15,23.43Z"
					/>
					<path id="Shape_Copy_31" data-name="Shape Copy 31" style={fillB} d="M21.54,6.39H16.32V1.22Z" />
					<rect id="Rectangle_5_Copy_23" data-name="Rectangle 5 Copy 23" style={fillC} x="1.71" y="4.01" width="11.82" height="6.02" rx="2.14" />
					<path
						id="_copy_2"
						data-name=" copy 2"
						style={fillB}
						d="M17.52,12.82h-3v1.53h.75L13.7,15.63l-1.53-1.28h.77V12.82H9.88v1.53h.76l2.3,1.91-2.3,1.9H9.88V19.7h3.06V18.16h-.77l1.53-1.27,1.52,1.27h-.75V19.7h3V18.16h-.77l-2.28-1.9,2.28-1.91h.77Z"
					/>
					<g id="MSG-4" className="icon-text">
						<g className="cls-4">
							<path
								d="M3.79,6.93a1.3,1.3,0,0,1,1.3-1.27,1.29,1.29,0,0,1,1,.41l-.39.34a.76.76,0,0,0-.57-.27.79.79,0,0,0,0,1.58.74.74,0,0,0,.57-.28l.39.35a1.33,1.33,0,0,1-1,.41A1.31,1.31,0,0,1,3.79,6.93Z"
							/>
							<path
								d="M6.21,7.79l.3-.36a1.21,1.21,0,0,0,.81.32c.26,0,.4-.12.4-.28s-.14-.22-.52-.31-.88-.26-.88-.74a.8.8,0,0,1,.9-.76,1.39,1.39,0,0,1,1,.34l-.27.38a1.14,1.14,0,0,0-.7-.28c-.23,0-.36.12-.36.27s.14.23.52.32c.53.12.88.27.88.73s-.35.78-1,.78A1.58,1.58,0,0,1,6.21,7.79Z"
							/>
							<path d="M11,5.7,10,8.15H9.43l-1-2.45H9l.71,1.73.7-1.73Z" />
						</g>
					</g>
				</g>
			</svg>
		);
	}
}
export default CsvIcon;
