import React, { Component } from 'react';
import { svgElementFor } from '../../components/Documents/Icons/AllIcons';

import './UploadList.scss';

class UploadList extends Component {

    render() {
        let { data, removeFile } = this.props;

        return <div className={`modal-uploads-container mb-3 ${this.props.isTemplate ? "extra-padding-bottom" : ""}`}>
            <ul>
                { data.map((file,idx)=>{
                    let style = { "width": file.progress + "%" }
            		const extension = file.name ? file.name.substring(file.name.lastIndexOf(".") + 1) : "";

                    return <li key={idx} style={this.props.isTemplate ? {justifyContent: "left", maxWidth: "fit-content"} : null }>
				        <div className="file-icon-container">{svgElementFor(extension)}</div>
                        <div className="modal-upload-name" style={this.props.isTemplate ? {maxWidth: "100%", paddingRight: "1rem"} : null }>{file.name}</div>
                        {file.status !== "" && <div className="modal-upload-size ml-1">{file.status}</div>}
                        { !file.uploadComplete && <div className="modal-upload-bar-outer">
                            <div className="modal-upload-bar-inner" style={style} />
                        </div> }
                        <i className="material-icons modal-upload-cancel" onClick={() => file.cancel ? file.cancel(file) : removeFile(file) }>close</i>
                    </li>;
                })}
            </ul>
        </div>
    }
}

export default UploadList;