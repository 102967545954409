import React, { useEffect, useRef, useState } from "react";
import ToolTip from "../Popups/Tooltip";
import "./ClientAccountToken.scss";


export const ClientAccountTokenSizes = { SMALL: "small", MEDIUM: "medium", LARGE: "large" };
const iconNames = { account: "account_balance", client: "person", npe: "business" };

const SimplifiedClientAccountToken = ({ onClick, size, simplifiedClientAccount, margin, stylingId, accountNumber, tdWidth, icon }) => {

    const [isOverflown, setIsOverflown] = useState(false);
	const textElementRef = useRef();
    
	const compareSize = () => {
		const element = textElementRef.current;
		if (!element) {
			return;
		}
		const compare = element.scrollWidth > element.clientWidth;
		setIsOverflown(compare);
	};

	// compare once and add resize listener
	useEffect(() => {
		compareSize();
		textElementRef.current && textElementRef.current.addEventListener('mouseover', compareSize());
		window.addEventListener('mouseover', compareSize);
	}, [simplifiedClientAccount, isOverflown, textElementRef]);

	// remove resize listener
	useEffect(() => () => {
		textElementRef.current && textElementRef.current.removeEventListener('mouseover', compareSize());
		window.removeEventListener('mouseover', compareSize);
	}, [simplifiedClientAccount, isOverflown, textElementRef]);

	if (!simplifiedClientAccount) {
		return <div>Client account token has no simplifiedClientAccount</div>
	}
	const type = simplifiedClientAccount.type;
	const clientAccountDisplayName = simplifiedClientAccount.name

	return (
		<div id={stylingId ? stylingId : ""} className={`ca-token-outer ca-token-${type} ca-token-${size}${margin ? " margin-" + margin : ""}`} onClick={onClick}>
			{/* Icon/indicator on right hand side */}
			<div className="ca-type-indicator">
				<i className="material-icons">{iconNames[type]}</i>
			</div>
			<div className={`ca-token-content`}>
				<div className="ca-token-value">
					{
						 !isOverflown
							? <ToolTip placement="top-start" offsetY={8} label={accountNumber} disabled={!accountNumber}>
								<span style={{maxWidth: tdWidth ? tdWidth : ""}} className="ca-name-span" ref={textElementRef}>{clientAccountDisplayName}</span>
								{icon || ""}
							</ToolTip>
							: <ToolTip placement="top-start" offsetY={8} label={accountNumber ? accountNumber + " | " + simplifiedClientAccount.name : simplifiedClientAccount.name} disabled={!isOverflown}>
								<span style={{maxWidth: tdWidth ? tdWidth : ""}} className="ca-name-span" ref={textElementRef}>{clientAccountDisplayName}</span>
								{icon || ""}
							</ToolTip>
					}
				</div>				
			</div>
		</div>
	);
}

export default SimplifiedClientAccountToken;
