import React, { useState, useEffect } from 'react';
import SingleNotification from '../../Dashboard/DashboardModules/NotificationList/SingleNotification';
import { SideNavSection } from '../SideNavExtension';
import { withStorage, withI18n, withRemote } from "@threeskye/global";
import { stateFromNote } from "../../../utils/EventUtils";
import ExtensionEmptyMessage from './ExtensionEmptyMessage';
import LoadingIcon from '@threeskye/loading-icon';
import FlexWrapper from '../../Shared/FlexWrapper';
import { eventTypes } from '../../../utils/EventUtils';
import { formatDistanceToNow } from 'date-fns';


const NotesExtension = (props) => {

	const [pinned, setPinned] = useState([])
	const [notes, setNotes] = useState([])
	const [loading, setLoading] = useState(true)

	useEffect(() => {
		props.storage.getOrFetch("/modules/crm/notes")
			.then(notes => {
				setInitialNotes(notes)
				setLoading(false)
			});
		props.storage.watch("/modules/crm/notes", setInitialNotes);
		return () => props.storage.unwatch("/modules/crm/notes")
	}, [])

	const setInitialNotes = (notes) => {
		let noteList = notes.map((note) => { return { ...note, eventType: eventTypes[3], workspaceNoteId:note.id } })
		noteList = noteList.sort((a,b) => new Date(a.start).getTime() - new Date(b.start).getTime());
		setNotes(noteList);
	}

	const notesUpdated = (notes) => {
		setNotes(notes)
	}

	const notePeriod = (note) => {
		if (note.modifiedDateTime) {
			return formatDistanceToNow(new Date(note.modifiedDateTime)) + " ago";
		} else return ""
	}

	const editNote = (note) => {
		props.storage.put("/modules/crm/notes", notes);
		notesUpdated(notes);
		//There is a trick here that uses duplicate creation to mimic the intial state
		//This step is here so that the behavior of create->minimise->exit matches create->submit->edit->exit
		//Otherwise the modal assumes that the initial state was a filled modal, and the user looses the note on exit
		//this is a temporary hold.
		let state = stateFromNote(note);
		let initialState = stateFromNote(note);
		state["initialState"] = initialState;
		props.openUnfinishedEventInExtension({ data: { initType: "unfinished", requiresInit: true, eventType: eventTypes[3], state: {...state, id: note.id, workspaceNoteId: note.workspaceNoteId} } }, "notes")
	}

	const removeNote = (event, note) => {
		event.stopPropagation();
		props.remote.send("delete", "/modules/crm/notes/" + note.id)
			.then(notes => {
				props.storage.put("/modules/crm/notes", notes);
				notesUpdated(notes);
			});
	}
	if (loading) {
		return <div className="side-nav-wrapper">
			<FlexWrapper fullWidth justify="center"><LoadingIcon size={40} /></FlexWrapper>
		</div>
	}

	return (
		<div className="side-nav-wrapper">
			{(!pinned || pinned.length < 1) && (!notes || notes.length < 1) &&
				<ExtensionEmptyMessage header="You have no notes" clickableMessage="Try creating one now" onMessageClick={() => props.createEvent()} />
			}
			{pinned && pinned.length > 0 && (
				<SideNavSection header="Pinned" icon="push_pin">
					{pinned.map((notification, idx) => (
						<SingleNotification
							key={idx}
							title={notification.title}
							date={notification.date}
							data={notification}
							subtitle={notePeriod(notification)}
							unread={notification.unread}
							type={notification.type}
							pinned
							onClick={editNote}
							onDelete={removeNote}
						/>
					))}
				</SideNavSection>
			)}
			{notes && notes.length > 0 && (
				<SideNavSection header="Today">
					{notes &&
						notes.map((notification, idx) => {
							let title = notification.title;
							if (!title) {
								const body = JSON.parse(notification.content)[0];
								if (body && body.type && (body.type === 'paragraph' || body.type === 'heading-one' || body.type === 'heading-two')) {
									title = body.children[0].text;
								} else {
									title = '(no preview)';
								}
							}
							return (
								<SingleNotification
									icon={notification.type === "reminder" ? "event" : "class"}
									key={idx}
									title={title}
									date={notification.date}
									data={notification}
									subtitle={notePeriod(notification)}
									unread={notification.unread}
									type="workspace-note"
									onClick={editNote}
									onDelete={removeNote}
								/>
							)
						})}
				</SideNavSection>
			)}
		</div>
	);
}

export default withStorage(withI18n(withRemote(NotesExtension)));