import React, {Component} from 'react';
import {withI18n, withRemote, withStorage} from "@threeskye/global";
import Button from "@threeskye/buttons";

import './SetPasswordPage.scss';
import logo from './logo.png';

class PasswordChangedPage extends Component {

	constructor(props) {
		super(props);

		this.state = {
		}

		this.onLogin = this.onLogin.bind(this);

	}

	onLogin() {
	  this.props.switchPage('');
	}


	render() {
		return (
		<div id="master-container">
			<div id="header">
						<div className="logo-container">
							<img src={logo} alt="logo" className="logo" />
						</div>
			</div>
			<div id="content">
				<div id="welcome-message" className="content-wrapper">
					<h1 className="mb-1">Your password has been reset.</h1>
					<p>Click the button below to login with your new password.</p>
				</div>
				<div id="login-form" className="form">
					<div className="form-row justify-center">
						<div className="form-col-nogrow">
							<Button icon="arrow_back" onClick={this.onLogin}>Return to the login page</Button>
						</div>
					</div>
				</div>
			</div>
		</div>
	  )
	}
}

export default withStorage(withRemote(withI18n(PasswordChangedPage)));
