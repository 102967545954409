import React, { Component } from "react";

import { withStorage, withRemote } from "@threeskye/global";
import { BrowserRouter } from "react-router-dom";
import { hotkeys } from 'react-keyboard-shortcuts';

import OmniSearch from "../components/Search/OmniSearch.js";

import ApplicationWrapper from "@threeskye/global";
import ToastNotification from "../components/Shared/ToastNotification.js";

import { ModalTypes } from '../utils/ModalUtils';
//import { eventTypes } from "../utils/EventUtils.js";

import Modals from './Modals';
import LeftExtension from './LeftExtension';
import Progress from './Progress';
import Unfinisheds from "./Unfinisheds.js";
import { isMobile, randomId } from '../utils/Utils';
import { DragDropContext } from "react-dnd";
import HTML5Backend from "react-dnd-html5-backend";

import SockJsClient from 'react-stomp';

import './ThreeSkyeCRM.scss';
import "../layout/MainGrid.scss";
import ScrollToTop from "../utils/ScrollToTop.js";
import TopNav from "../layout/TopNav.js";
import SideNav from "../layout/SideNav.js";
//import SideNavExtension from "../components/SideNav/SideNavExtension.js";
//import NotificationsExtension from "../components/SideNav/Extensions/NotificationsExtension.js";
import SideNavRight from "../components/SideNav/SideNavRight.js";
//import NotesExtension from "../components/SideNav/Extensions/NotesExtension.js";
//import DraftsExtension from "../components/SideNav/Extensions/DraftsExtension.js";
//import BookmarksExtension from "../components/SideNav/Extensions/BookmarksExtension.js";
import MobileMenu from "../components/MobileMenu/MobileMenu.js";
//import CreateEventExtension from "../components/SideNav/Extensions/CreateEventExtension.js";
//import { MaximiseIcon, MinimiseIcon, NewEventIcon } from "../components/SideNav/ActionIcons/ExtensionActionIcons.js";
import DisplayExtensions from "../sidebarExtensions/DisplayExtensions.js";

const Toaster = React.createContext({});
const Data = React.createContext({});

class ThreeSkyeCRM extends Component {
	constructor(props) {
		super(props);

		this.props.storage.put("clients.list.key", this.props.clientsListKey);
		this.props.storage.put("accounts.list.key", this.props.accountsListKey);

		//Preload so it's available
		this.props.storage.getOrFetch("/modules/crm/configuration");
		this.toast = this.toast.bind(this);
		this.toastOff = this.toastOff.bind(this);
		this.handleSocketMessage = this.handleSocketMessage.bind(this);
		this.openUnfinishedEventInExtension = this.openUnfinishedEventInExtension.bind(this)
		this.clearUnfinishedEventInExtension = this.clearUnfinishedEventInExtension.bind(this)
		this.authoriseSocket = this.authoriseSocket.bind(this);
		this.childSetState = this.childSetState.bind(this)
		this.childOpenUnfinishedEventInExtension = this.childOpenUnfinishedEventInExtension.bind(this)
		this.childSetActiveExtension = this.childSetActiveExtension.bind(this)
		this.dataImplementation = props.dataImplementation;

		let crm = this;
		let modals = {
			active: [],
			inactive: [],
			queued: null,

			/**
			 * Shows a modal, if necessary minimising the current modal stack to do so.
			 * @param {string} type
			 * @param {*} data
			 */
			show: function (type, data) {
				if (this.active.length > 0) {
					//TODO check if minimising is valid
					//for now, only constraint is we can't minimise stacks (too complicated)
					if (this.active.length > 1)
						return;

					if (!this.active[0].type.minimisable)
						return;

					//deactivate, without setState
					let modal = this.active.splice(0, 1);
					this.inactive.push(modal[0]);
					this.queued = { type, data };
				} else {
					this.active.unshift({ type, data });
				}
				crm.setState({ modals: this });
				crm.props.remote.get("/users/ping");
			},

			actionQueue: function () {
				if (!this.queued)
					return;

				this.active.unshift(this.queued);
				this.queued = null;
				crm.setState({ modals: this });
			},

			/**
			 * Closes the topmost modal, resulting in any stacked modals showing instead
			 */
			close: function () {
				this.active.splice(0, 1);
				crm.setState({ modals: this });
				crm.props.remote.get("/users/ping");
			},

			/**
			 * Closes the entire stack
			 */
			clear: function () {
				this.active.length = 0;
				crm.setState({ modals: this });
			},

			/**
			 * remove all inactive modals
			 */
			clearInactive: function () {
				this.inactive.length = 0;
				crm.setState({ modals: this });
			},

			addInactive: function (type, data) {
				this.inactive.push({ type, data });
				crm.setState({ modals: this });
			},

			activate: function (index) {
				let modal = this.inactive.splice(index, 1);
				this.active.unshift(modal[0]);
				crm.setState({ modals: this });
			},

			remove: function (index) {
				this.inactive.splice(index, 1);
				crm.setState({ modals: this });
			},

			/**
			 * Deactivates the current showing modal
			 */
			deactivate: function () {
				let modal = this.active.splice(0, 1);
				this.inactive.push(modal[0]);
				crm.setState({ modals: this });
			},

			/**
			 * Creates a modal record but instead of showing it, immediately adds it to the unfinisheds
			 */
			createDeactivated: function (type, data) {
				this.inactive.push({ type, data });
			},

			showing: function () {
				return this.active.length > 0;
			},

			/**
			 * Adds a new modal to the stack WITHOUT minimising the existing one
			 *
			 * Stacked modals MUST share the same initialisationData.  Implementation
			 * is responsible for making this work.
			 *
			 * @param {string} type
			 * @param {*} data
			 */
			stack: function (type, additionalData) {
				additionalData && Object.assign(this.active[0].data, additionalData);
				this.active.unshift({ type, data: this.active[0].data });
				crm.setState({ modals: this });
			}
		};

		let progress = {
			uploads: [],
			create: function (name, listener) {
				let random = randomId();
				let uplds = this;
				let newOne = {
					name,
					id: random,
					status: "Initialising ...",
					error: null,
					progress: 0,
					setStatus: function (status, error) {
						this.status = status;
						this.error = error;
						crm.setState({ progress: uplds });
						listener && listener.setStatus && listener.setStatus(status);
					},
					setProgress: function (prog) {
						this.progress = prog;
						this.setStatus.bind(this)(prog + "%")
						listener && listener.setProgress && listener.setProgress(prog);
					},
					finished: function () {
						let index = uplds.uploads.findIndex(u => u.id === random);
						uplds.uploads.splice(index, 1);
						crm.setState({ progress: uplds });
						listener && listener.finished && listener.finished(this);
					},
					cancel: function () {
						let index = uplds.uploads.findIndex(u => u.id === random);
						uplds.uploads.splice(index, 1);
						crm.setState({ progress: uplds });

						listener && listener.cancel && listener.cancel(newOne);
					}
				};
				this.uploads.push(newOne);
				crm.setState({ progress: this });
				return newOne;
			}
		}

		let leftExtension = {
			active: null,

			create: (component, header) => {
				this.active = component
				crm.setState({ leftExtensionActive: component, leftExtensionHidden: false, leftExtensionHeader: header, fullScreen: this.state.fullScreen })
			},
			remove: () => {
				this.active = null
				crm.setState({ leftExtensionActive: null, leftExtensionHidden: false })
			},
			hide: () => {
				if (crm.state.leftExtensionHidden) {
					crm.setState({ leftExtensionHidden: false })
				} else {
					crm.setState({ leftExtensionHidden: true })
				}
				//set CSS classes of leftExtension to display-none
			}
		}

		let rightExtension = {
			active: null,

			create: (component, header) => {
				this.active = component
				crm.setState({ activeExtension: "manual", rightExtExpanded: false, rightSidebarManualComponent: component, rightSidebarManualHeader: header })
			},
			remove: () => {
				this.active = null
				crm.setState({ activeExtension: null, activeExtension: false })
			},
			showing: () => {
				return crm.state.activeExtension
			}
		}


		this.state = {
			showCreateEventModal: false,
			showCreateNoteModal: false,
			showSearch: false,
			showMobileMenu: false,
			searchText: "",
			toaster: {
				toast: this.toast
			},
			toastVisible: false,
			toastMessage: "",
			modals,
			progress,
			sideNavRightExt: false,
			sideNavRightExtFixed: true,
			activeExtension: isMobile() ? "notes" : "notifications",
			leftExtensionActive: false,
			leftExtensionHidden: false,
			fullScreen: false,
			isEmailTemplate: false,
			thinLeftExtension: false
		};

		let fullScreenMode = {
			fullScreenState: this.state.fullScreen,
			toggleFullScreen: () => {
				crm.setState({ fullScreen: !this.state.fullScreen })
			},
			remove: () => crm.setState({ fullScreen: false })
		}

		let thinLeftExtensionMode = {
			thinLeftExtension: (boolean) => {
				crm.setState({ thinLeftExtension: boolean })
			},
			remove: () => crm.setState({ thinLeftExtension: true })
		}

		this.props.storage.put("crm.modals", modals);
		this.props.storage.put("crm.progress", progress);
		this.props.storage.put("crm.leftExtension", leftExtension);
		this.props.storage.put("crm.rightExtension", rightExtension);
		this.props.storage.put("crm.fullScreenMode", fullScreenMode)
		this.props.storage.put("crm.thinLeftExtensionMode", thinLeftExtensionMode)

		this.props.storage.watch("crm.showMobileMenu", val =>
			this.setState({ showMobileMenu: val })
		);
		this.props.storage.watch("crm.showSearch", val => {
			this.setState({ showSearch: val });
			if (!val) {
				this.props.storage.put("crm.searchTerm", "");
			}
		});
		this.props.storage.watch("crm.searchTerm", val =>
			this.setState({ searchText: val })
		);
		this.props.storage.watch("crm.theme", theme => {
			this.setState({ theme });
		});
		this.props.storage.watch("crm.innerContentWidth", innerContentWidth => {
			this.setState({ innerContentWidth });
		});
	}

	hot_keys = {
		'ctrl+/': {
			priority: 1,
			handler: e => { this.props.storage.put("crm.showSearch", true); return false }
		},
		'ctrl+shift+e': {
			priority: 1,
			handler: e => { this.props.storage.get("crm.modals").show(ModalTypes.CreateEvent, {}); return false }
		}
	}

	toast(message) {
		this.setState({ toastVisible: true, toastMessage: message });
		setTimeout(this.toastOff, 3000);
	}

	toastOff() {
		this.setState({ toastVisible: false, toastMessage: "" });
	}

	componentDidMount() {
	}

	handleSocketMessage(message, topic) {
		if (topic === '/topic/broadcast') {
			this.toast(message);
		} else if (topic === '/topic/updates' || topic === '/user/topic/updates') {
			this.props.storage.refreshRequired(message);
		}
	}

	authoriseSocket() {
		this.clientRef.sendMessage('/topics/auth', sessionStorage.getItem('3skye.auth.token'));
	}

	setActiveExtension(newActiveExtension, previousActiveExtension, type) {
		if (newActiveExtension === "createEvent") {
			this.setState({ activeExtension: this.state.activeExtension === newActiveExtension ? "" : newActiveExtension, previousActiveExtension, eventTypeToOpen: type ? type : null })
		} else {
			this.setState({ activeExtension: this.state.activeExtension === newActiveExtension ? "" : newActiveExtension, previousActiveExtension, eventTypeToOpen: null })
		}
	}

	openUnfinishedEventInExtension(event, previousActiveExtension) {
		this.setState({ createEventExtensionContent: event.data, activeExtension: "createEvent", eventTypeToOpen: event.data.state.eventType, previousActiveExtension: previousActiveExtension || null })
	}

	clearUnfinishedEventInExtension() {
		this.setState({ createEventExtensionContent: null })
	}

	childSetState(newState) {
		this.setState(newState)
	}

	childOpenUnfinishedEventInExtension(event, previousActiveExtension) {
		this.openUnfinishedEventInExtension(event, previousActiveExtension)
	}

	childSetActiveExtension(newActiveExtension, previousActiveExtension, type) {
		this.setActiveExtension(newActiveExtension, previousActiveExtension, type)
	}

	render() {
		var url = window.location.href;
		var protocolEnd = url.indexOf("://") + 3;
		var serverEnd = url.indexOf("/", protocolEnd);
		var app = this.props.app;
		var basename = url.substring(serverEnd, url.indexOf(app) + app.length);

		//Temp ... will come from config
		let className = this.state.theme || "dark-theme";

		const customSocketHeaders = {
			"Authorization": sessionStorage.getItem('3skye.auth.token')
		}

		const { nav, contents, logout, headers } = this.props;
		const { sideNavRightExt, sideNavRightExtFixed, activeExtension, previousActiveExtension, createEventExtensionContent, innerContentWidth, isEmailTemplate } = this.state;
		return (
			<Toaster.Provider value={this.state.toaster}>
				<Data.Provider value={this.dataImplementation}>
					<div
						id="master-grid"
						className={`${className}${this.state.fullScreen ? " full-screen" : ""}${this.state.activeExtension
							? this.state.leftExtensionActive && this.state.thinLeftExtension && !this.state.leftExtensionHidden
								? " side-nav-left-right-extensions thin-left-extension" : this.state.leftExtensionActive && !this.state.leftExtensionHidden
									? " side-nav-left-right-extensions "
									: " side-nav-right-extensions "
							: this.state.leftExtensionActive && this.state.thinLeftExtension && !this.state.leftExtensionHidden
								? " side-nav-left-extensions thin-left-extension" : this.state.leftExtensionActive && !this.state.leftExtensionHidden
									? " side-nav-left-extensions "
									: ""}${this.state.rightExtExpanded
										? " right-extension-expanded"
										: ""
							}${this.state.rightExtExpandedFull
								? " right-extension-expanded-full"
								: ""
							}`}
					>
						<BrowserRouter basename={basename}>
							<ScrollToTop />
							<ApplicationWrapper {...this.props}>
								<ToastNotification visible={this.state.toastVisible} message={this.state.toastMessage} close={this.toastOff} />
								{/* Top nav banner with nav buttons + profile etc */}
								<TopNav logout={logout} fullScreen={this.state.fullScreen} />
								<MobileMenu visible={this.state.showMobileMenu} links={nav} logout={this.props.logout} />
								{/* Side navigation with icons */}
								<SideNav nav={nav} logout={logout} fullScreen={this.state.fullScreen} />
								{/* <Navigation logout={this.props.logout}>{this.props.nav}</Navigation> */}
								{this.state.showSearch ? <OmniSearch searchText={this.state.searchText} {...this.dataImplementation} /> : ""}
								{this.state.fullScreen ? <div style={{ display: 'none' }}>{headers}</div> : headers}
								{this.state.leftExtensionActive && <LeftExtension header={this.state.leftExtensionHeader} hidden={this.state.leftExtensionHidden} leftExtension={this.state.leftExtensionActive} fullScreen={this.state.fullScreen}/>}
								<div id="content-grid">
									<div className={`content-inner ${innerContentWidth ? innerContentWidth : ""}`}>
										{contents}
									</div>
								</div>
								{/* Notifications Extension */}
								{(sideNavRightExt || sideNavRightExtFixed) &&
									<DisplayExtensions
										setState={this.childSetState}
										rightExtExpanded={this.state.rightExtExpanded}
										rightExtExpandedFull={this.state.rightExtExpandedFull}
										modals={this.state.modals}
										setActiveExtension={this.childSetActiveExtension}
										clearUnfinishedEventInExtension={this.clearUnfinishedEventInExtension}
										createEventExtensionContent={createEventExtensionContent}
										previousActiveExtension={previousActiveExtension}
										eventTypeToOpen={this.state.eventTypeToOpen}
										openUnfinishedEventInExtension={this.childOpenUnfinishedEventInExtension}
										activeExtension={activeExtension}
										rightSidebarManualComponent={this.state.rightSidebarManualComponent}
										rightSidebarManualHeader={this.state.rightSidebarManualHeader}
										isEmailTemplate={isEmailTemplate}
									/>
								}
								<SideNavRight active={activeExtension} handleIconClick={(e) => this.setActiveExtension(e, null)} fullScreen={this.state.fullScreen} />
								<Modals modals={this.state.modals} />
								<Progress progress={this.state.progress} />
								<Unfinisheds modals={this.state.modals} />
								<SockJsClient url='/ws' topics={['/topic/broadcast', '/user/topic/updates', '/topic/updates']} x
									headers={customSocketHeaders}
									onMessage={this.handleSocketMessage}
									ref={(client) => { this.clientRef = client }}
									onConnect={this.authoriseSocket} />
							</ApplicationWrapper>
						</BrowserRouter>
						{/* Button for toggling the theme */}
						{/* <div
							onClick={() => this.setState({theme: this.state.theme === "dark-theme" ? "light-theme" : "dark-theme"})}
							style={{ padding: "10px 20px", borderRadius: 8, fontSize: 12, position: "absolute", bottom: 20, right: 20, backgroundColor: "#004a8a", cursor: "pointer" }}
						>TOGGLE</div> */}
					</div>
				</Data.Provider>
			</Toaster.Provider>
		);
	}
}

// ThreeSkyeCRM.propTypes = {
//     children: function(props, propName, componentName) {
//         const children = props[propName];

//         let error = null;

//         if (children[0].type.displayName !== 'ThreeSkyeCRM.Navigation') {
//             error = new Error("First child of ThreeSkyeCRM must be Navigation");
//         } else if (children[1].type.displayName !== 'ThreeSkyeCRM.Headers') {
//             error = new Error("Second child of ThreeSkyeCRM must be Headers");
//         } else if (children[1].type.displayName !== 'ThreeSkyeCRM.Contents') {
//             error = new Error("Third child of ThreeSkyeCRM must be Contents");
//         }

//         return error;
//     }
// }

class Main extends Component {
	constructor(props) {
		super(props);

		this.state = {
			headerStyle: ""
		};

		this.initialise = this.initialise.bind(this);
		this.updateHeaderStyle = this.updateHeaderStyle.bind(this);
		this.initialise();
	}

	initialise() {
		this.props.storage.watch("crm.header.style", this.updateHeaderStyle);
	}

	updateHeaderStyle(newVal) {
		this.setState({ headerStyle: newVal });
	}

	showCreateEventModal() {
		this.setState({ showCreateEventModal: true });
	}

	render() {
		return (
			<div className={"main-content-grid " + this.state.headerStyle}>
				{/* Header content e.g top-banner and info-banner */}
				{React.cloneElement(this.props.headers, { logout: this.props.logout })}
				{/* Header content END */}
				{/* Main content views here */}
				{this.props.contents}
				{/* Main content views END */}
			</div>
		);
	}
}

const MainWithStorage = withStorage(Main);

export function withToaster(Child) {
	return function ToasterChild(props) {
		return (
			<Toaster.Consumer>
				{(toaster) => {
					return (
						<Child {...props} toaster={toaster} />
					);
				}}
			</Toaster.Consumer>
		);
	}
};

export function withData(Child) {
	return function DataChild(props) {
		return (
			<Data.Consumer>
				{(dataImplementation) => {
					return (
						<Child {...props} {...dataImplementation} />
					);
				}}

			</Data.Consumer>
		);
	}
}
export default withRemote(withStorage(DragDropContext(HTML5Backend)(hotkeys(ThreeSkyeCRM))));
