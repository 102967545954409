import React, { Component } from 'react';
import { withI18n, withRemote, withStorage } from "@threeskye/global";
import { withCookies } from 'react-cookie';
import Button from "@threeskye/buttons";

import './LoginForm.scss';
import logo from './logo.png';
import CRMErrorMessage from '../Shared/Validation/CRMErrorMessage';
import { withRouter } from 'react-router-dom';

class LoginForm extends Component {

  constructor(props) {
    super(props);

    this.state = {
      username: "",
      password: "",
      failLogin: false,
      fail2fa: false,
      show2fa: false,
      cellRecoveryNumber: "",
      twoFAKey: "",
      twoFACode: "",
      failLoginMessage: "Incorrect username or password",
      retry: 3,
      loggingIn: false,
    }

    this.onChangeUsername = this.onChangeUsername.bind(this);
    this.onChangePassword = this.onChangePassword.bind(this);
    this.onChangeCellRecovery = this.onChangeCellRecovery.bind(this);
    this.showRecoveryScreen = this.showRecoveryScreen.bind(this);
    this.showLoginScreen = this.showLoginScreen.bind(this);
    this.onLogin = this.onLogin.bind(this);
    this.onAccountRecovery = this.onAccountRecovery.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.resetByEmail = this.resetByEmail.bind(this);
    this.has2fa = this.has2fa.bind(this);
    this.do2fa = this.do2fa.bind(this);
    this.onCodeEnter = this.onCodeEnter.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.onSubmit2faCode = this.onSubmit2faCode.bind(this);
	
  }

  onChangePassword(val) {
    this.setState({ password: val });
  }

  onChangeUsername(val) {
    this.setState({ username: val });
  }

  onChangeCellRecovery(val) {
    this.setState({ cellRecoveryNumber: val });
  }

  showRecoveryScreen() {
    this.props.switchPage("forgot-password/");
  }
  showLoginScreen() {
    this.setState({ showRecovery: false });
  }

  do2fa(data) {
    this.setState({ twoFAKey: data.data.key, show2fa: true });
  }

  handleKeyPress(e, field) {
    if (e.key === 'Enter') {
      e.preventDefault();
      if (field === 'loginUsername') {
        this.refs.loginPassword.focus();
      } else if (field === 'loginPassword') {
        if (this.state.username.length > 0 && this.state.password.length > 0) {
          this.onLogin();
        } else if (this.state.username.length === 0) {
          this.refs.loginUsername.focus();
        }
      } else if (field === 'forgottenUsername') {
        this.refs.forgottenCellNumber.focus();
      } else if (field === 'forgottenCellNumber') {
        if (this.state.username.length > 0 && this.state.cellRecoveryNumber.length > 0) {
          this.onAccountRecovery();
        } else if (this.state.username.length === 0) {
          this.refs.forgottenUsername.focus();
        }
      }
    }
  }

  onAccountRecovery() {
    const data = {
      username: this.state.username,
      phone: this.state.cellRecoveryNumber
    }

    this.props.storage.put("username", this.state.username);

    this.props.remote.post("/um/password-recovery-code", data)
      .then(
        x => {
          this.props.switchPage("forgot-password/");
        },
        x => { console.log("Something went wrong that should not!") }
      );
  }

  resetByEmail() {
    this.props.switchPage("password-reset-email/");

  }

  has2fa() {
    const { cookies } = this.props;
    let username = this.state.username.replace("@", "#");
    const cookie = cookies.get('3skye.login.' + username);
    if (typeof (cookie) === 'undefined') {
      return false;
    }

    //Reject original insecure cookies
    if (cookie === true || cookie === "true" || cookie === "bypassed") {
      return false;
    }

    return cookie;
  }

//   wsLogin() {
// 	const url = window.location.href;
// 	console.log("URL is ", url);

// 	this.props.storage.getOrFetch("/configuration").then(config=> {
// 		const locale = config.defaultLocale;
// 		const app = config.defaultApp;
// 		const index = url.indexOf(locale+"/"+app);
// 		if (index < 0) {
// 			console.log("Didn't find "+(locale+"/"+app)+" so no redirect")
// 			window.location.href = "/api/um/ws-redirect?redirect=";
// 			return;
// 		} else {
// 			console.log("found "+(locale+"/"+app)+" so redirecting to "+url.substring(url.indexOf(locale+"/"+app)+locale.length+app.length+2))
// 			window.location.href = "/api/um/ws-redirect?redirect="+url.substring(url.indexOf(locale+"/"+app)+locale.length+app.length+2);
// 		}
// 	});

//   }

  onLogin() {
    const data = {
      username: this.state.username,
      password: this.state.password
    }
    this.setState({ loggingIn: true })
    const twofa = this.has2fa();
    if (!twofa) {
      this.props.remote.post("/um/validate-credentials", data)
        .then(x => {
          this.setState({ loggingIn: false });
          if (x.success) {
            this.do2fa(x);
          } else if (x.message && x.message === 'account-locked') {
            this.setState({ failLoginMessage: "This account has been locked." })
            this.setState({ failLogin: true });
          } else {
            this.setState({ failLoginMessage: "Incorrect username or password." })
            this.setState({ failLogin: true });

          }
        });
    } else {
      data.twoFAToken = twofa;

      this.props.remote.post("/um/login", data)
        .then(x => {
          this.setState({ loggingIn: false });
          if (x.success) {
            this.props.onLogin();
          } else {
            if (x.message === "2fa-failure") { this.do2fa(x); }
            else if (x.message && x.message === 'account-locked') {
              this.setState({ failLoginMessage: "This account has been locked." })
              this.setState({ failLogin: true });

            } else {
              this.setState({ failLoginMessage: "Incorrect username or password." })
              this.setState({ failLogin: true });

            }
          }
        });
    }

  }


  onCodeEnter(ref, val) {
    var code = this.state.twoFACode;
    var index = ref;

    var maxLen = this.state.twoFACode.length - index;
    var len = val.length > maxLen ? maxLen : val.length;
    if (len > 0) {
      for (var i = 0; i < len; i++) {
        code[index + i] = val[i];
      }

      if (index < this.state.twoFACode.length - 1) {
        this.refs["code" + (index + len - 1)].focus();
      }
    } else {
      code[index] = "";
    }

    this.setState({
      twoFACode: code
    });
  }

  handleKeyDown(e, idx) {
    switch (e.keyCode) {
      case 13: // enter
        this.onSubmit2faCode();
        break;
      default:
        break;
    }
  }

  onSubmit2faCode() {
    const code = this.state.twoFACode;
    if (code.length !== 6) {
      console.log("Please enter entire code");
      return;
    }

    const data = {
      username: this.state.username,
      code: code,
      key: this.state.twoFAKey
    };

    this.props.remote.post("/um/validate-credentials-2fa", data)
      .then(x => {
        if (x.status === 'valid') {

          //Cookie is now set serverside
          //   const { cookies } = this.props;
          //   let username = this.state.username.replace("@", "#");
          //   const oneYear = new Date(new Date().setFullYear(new Date().getFullYear() + 1));
          //   cookies.set('3skye.login.'+username, cookieValue, { path: '/', expires: oneYear, secure: true});

          const data2 = {
            username: this.state.username,
            password: this.state.password,
            twoFAToken: this.has2fa()
          }

          this.props.remote.post("/um/login", data2, { rawResponse: true })
            .then(x => {
              this.props.onLogin();
            }, x => {
              this.setState({ failLogin: true, show2fa: false })
            });

        }
      }, x => {
        //error
        if (this.state.retry <= 1) {
          this.props.switchPage("invalid-code");
        }
        this.setState({ retry: this.state.retry - 1, fail2fa: true });
      });


  }


  render() {
    const i = this.props.i18n.get;
    let fieldValidationMessage;
    const invalidCode = this.state.fail2fa;
    const invalidLogin = this.state.failLogin;

    if (this.state.fail2fa) {
      fieldValidationMessage = i("CodeInvalid{" + this.state.retry + "}");
    }

    const loginForm = (
      <div id="content">
        <div id="welcome-message" className="content-wrapper">
          <h1 className="mb-1">{i("Welcome_Back")}</h1>
          <p>{i("Please_sign_in")}</p>
        </div>
        <div id="login-form" className="form modal-form">
          <div className="form-row">

          </div>
          <CRMErrorMessage message={this.state.failLoginMessage} isValid={!invalidLogin} shouldShow>
            <div className="form-row">
              <div className="form-col-grow">
                <label htmlFor="user-name">Username</label>
                <input name="user-name" ref="loginUsername" type="text" placeholder={i("Username")} className="input-field" required onKeyPress={e => this.handleKeyPress(e, "loginUsername")} onChange={e => this.onChangeUsername(e.target.value)} value={this.state.username} />
              </div>
            </div>
            <div className="form-row">
              <div className="form-col-grow">
                <label htmlFor="password">{i("Password")}</label>
                <input name="password" ref="loginPassword" type="password" placeholder={i("Password")} className="input-field" required onKeyPress={e => this.handleKeyPress(e, "loginPassword")} onChange={e => this.onChangePassword(e.target.value)} />
              </div>
            </div>
          </CRMErrorMessage>
          <div className="form-row mt-3 login-button-row">
            <div className="form-col-grow forgot-pw-link" style={{gap: 10, flexWrap: "wrap"}}>
              <div className="pseudo-link" onClick={e => this.showRecoveryScreen()} >{i("Forgot_your_password")}</div>
              <div className="pseudo-link" onClick={this.props.wsLogin}>{i("OrganisationLogin")}</div>
            </div>
            <div className="form-col-grow">
              {this.state.loggingIn ?
                <Button fullWidth loading>{i("LoggingIn")}</Button> :
                <Button icon="exit_to_app" fullWidth classNames="login-btn" onClick={this.onLogin}>{i("Login")}</Button>
              }
            </div>
			
          </div>
        </div>
      </div>
    );

    const twoFaForm = (
      <div id="content">
        <div id="welcome-message" className="content-wrapper">
          <h1 className="mb-1">New device detected</h1>
          <p>A unique code has been sent to your mobile.<br />
            Enter it below to verify the account for <strong>{this.state.username}</strong></p>
        </div>
        <div className="form modal-form">
          <div className="form-row justify-center">
            <div className="form-col-grow">
              {/* <h2 className="verification-input-label" >Verification Code</h2> */}
              <CRMErrorMessage isValid={!invalidCode} message={fieldValidationMessage}>
                <div className="validation-code">
                  <input autocomplete="one-time-code" inputmode="numeric" type="text" className="twofa-code-input" placeholder="Verification Code" label="Verification Code" name="verification-code" value={this.state.code} onChange={(event) => this.setState({ twoFACode: event.target.value })} onKeyDown={(e, idx) => this.handleKeyDown(e, idx)} />
                </div>
              </CRMErrorMessage>
            </div>
          </div>
          <div className="form-row mt-3">
            <div className="form-col-grow">
              <Button fullWidth icon="exit_to_app" classNames="login-btn" onClick={this.onSubmit2faCode}>Submit</Button>
            </div>
          </div>
        </div>
      </div>
    );

//	const wsfed = this.queryString.get("failedwsfed");
//	console.log("Ws fed: ", wsfed);

    const form = this.state.show2fa ? twoFaForm : loginForm;
    return (
      <div id="master-container">
        <div id="header">
          <div className="logo-container">
            <img src={logo} alt="logo" className="logo" />
          </div>
        </div>
        {form}
      </div>);

  }
}

export default withCookies(withStorage(withRemote(withI18n(withRouter(LoginForm)))));

