import React, { useEffect } from 'react';
import ClientAccountLink from '../../../ClientAccount/ClientAccountLink';
import FlexWrapper from '../../../Shared/FlexWrapper';
import ExpandableNotification from './ExpandableNotification';
import NotificationMiniList, { MiniNotification } from './NotificationMiniList';
import "./NotificationList.scss";


export const BirthdayNotification = ({ client, onDelete, triggered, data }) => {

	return (
		<MiniNotification onDelete={onDelete} data={data}>
			<FlexWrapper gap="s" fullWidth>
				<ClientAccountLink size="small" clientAccount={client} />
			</FlexWrapper>
		</MiniNotification>
	)
}

export const GroupedBirthdayNotification = (props) => {
	const { notifications, onDelete } = props
	let triggeredDates = notifications.map(n => n.triggeredDate)
	let subTitleDate = triggeredDates.sort()[0]

	useEffect(() => {

    }, [notifications])


	return (
		notifications && (
			<ExpandableNotification onDelete={onDelete} data={notifications} type="birthday" icon="cake" title={`Birthdays (${notifications.length})`} expanded subtitle="" triggered={subTitleDate}>
				{/* List here... */}
				<NotificationMiniList>
					{notifications.map((n, idx) => {
						return (
							n && n.clientData && <BirthdayNotification key={idx} client={n.clientData} triggered={n.triggeredDate} onDelete={onDelete} data={n}/>
						)
					})}

				</NotificationMiniList>
			</ExpandableNotification>)
	);
}

export default GroupedBirthdayNotification;