import React, { Component } from "react";
import "./Topbar.scss";
import { withThreeSkyeGlobal } from "@threeskye/global";
import DropDownMenu from "../DropDownMenu/DropDownMenu.js";
import { withRouter } from "react-router-dom";
//import BookmarkList from "./BookmarkList";
//import NotificationList from "./NotificationList";
import ProfileImage from '../Shared/ProfileImage';
import SummaryValue from "../Shared/SummaryValue";
import Button from "@threeskye/buttons";
import CreateDropDownMenu from "../DropDownMenu/CreateDropDownMenu";
import { getAccountsList } from "../../utils/Utils";
import LoadingIcon from "@threeskye/loading-icon/dist/LoadingIcon";

class Topbar extends Component {
	constructor(props) {
		super(props);
		this.state = {
			userName: "",
			user: { preferredName: "_", familyName: "_" },
			searchText: "",
			totalAum: 0,
			loadingAum: true
		};
		this.initialise = this.initialise.bind(this);
		this.initialise();

		this.searchTextEntered = this.searchTextEntered.bind(this);

		this.props.storage.watch("crm.searchTerm", val => {
			this.setState({ searchText: val })
		});

	}

	initialise() {
		this.props.storage
			.getOrFetch("/users/current-user")
			.then(user => this.setState({ user: user, userName: user.fullName }));

		getAccountsList(this.props.storage)
			.then(accounts=>{
				if (accounts.length === 0) {
					//Instance-wide user
					this.props.storage
						.getOrFetch("/modules/crm/instance-access/aum")
						.then(resp => this.setState({ totalAum: resp.data, loadingAum: false }));
				} else {
					let totalAum = 0;
					accounts.forEach(account=>{
						if (account.status && account.status.id === 1 && account.includeInFum) {
							totalAum += account.value;
						}
					});
					this.setState({ totalAum: totalAum, loadingAum: false });
				}
			});
	}

	searchTextEntered(e) {
		let text = e.target.value;
		this.setState({searchText: text});
		this.props.storage.put("crm.searchTerm", text);
		if (text.length >= 2) {
			this.props.storage.put("crm.showSearch", text);
		}
	}

	render() {
		const i = this.props.i18n.get;
		const user = this.state.user;

		return (
			<div id="topbar-container">
				<div id="mobile-menu-btn">
					<i
						className="material-icons"
						onClick={()=>this.props.storage.put("crm.showMobileMenu", true)}
					>
						menu
					</i>
				</div>
				<div id="navigation-buttons">
					<i
						className="tb-back-btn material-icons"
						onClick={() => this.props.history.goBack()}
					>
						arrow_back_ios
					</i>
					<i
						className="tb-forward-btn material-icons"
						onClick={() => this.props.history.goForward()}
					>
						arrow_forward_ios
					</i>
				</div>
				<div id="searchbar">
					<input
						type="text"
						name="search"
						placeholder={i("SearchPlaceholder")}
						autoComplete="off"
						value={this.state.searchText}
						onChange={this.searchTextEntered}
					/>
					<i 
						className="material-icons"
						onClick={() => this.props.storage.put("crm.showSearch", true)}
					>
						search
					</i>
					
				</div>
				<div className="create-btn-container ml-2 d-none-mobile" id="create-btn-form">
					<Button 
						icon="add"
						id="createButtons-form-button"
						classNames="mr-2"
					>{i("Create")}</Button>
					<CreateDropDownMenu />	
				</div>
				<div id="topbar-right-content">
					<div className="total-aum-container">
						{this.state.loadingAum ? <LoadingIcon size={20} /> : <div className="total-aum-value">
							<SummaryValue value={this.state.totalAum} />
						</div>}
					</div>
					{/* <div className="notifications">
						<i className="material-icons">notifications</i>
						<NotificationList />
					</div>
					<div className="saved-accounts">
						<i className="material-icons bookmark">bookmark</i>
						<BookmarkList />
					</div> */}
					<div className="tb-divider" />
					<div className="profile-detail-container">
						<div className="profile-dropdown-container">
							<div className="profile-name profile-btn">
								<i className="material-icons">keyboard_arrow_down</i>
								{this.state.userName}{" "}
							</div>
						</div>
						<ProfileImage user={user} />
						<div className="menu-spacer" />
						<DropDownMenu logout={this.props.logout} />
					</div>
				</div>
			</div>
		);
	}
}

export default withRouter(withThreeSkyeGlobal(Topbar));
