import React from 'react';
import Tooltip from '../Popups/Tooltip';
import { Button as EditorButton } from '../Shared/Slate/components';
import { withI18n, withStorage } from '@threeskye/global'
import "./SlateAttachmentButton.scss"

const Icon = ({ children, symbolIcon }) => {
	return <i className={`material-icons${symbolIcon ? " material-symbols-outlined" : ""}`} >{children}</i>
}

const SlateAttachmentButton = ({ onLocalFileSelect, on3SkyeFileSelect, i18n, storage }) => {

	const i = i18n.get()

	const uploadRef = React.createRef();

	const _onLocalFileSelect = (event) => {
		console.log("attachment")
		onLocalFileSelect(event.target.files);
	}

	const _triggerUpload = () => {
		if (uploadRef && uploadRef.current) {
			console.log("local files modal triggered")
			uploadRef.current.click();
		}
	}

	return (
		<Tooltip
			toggleTooltip
			placement="bottom-end"
			label={
				<div className="sab-options-container">
					<div className="sab-option" onClick={_triggerUpload}>
						<div className="sab-option-content" ><i className="material-icons sab-icon">cloud_upload</i>Upload From Your Device</div>
					</div>
					<div className="sab-option" onClick={on3SkyeFileSelect}>
						<div className="sab-option-content"><i className="material-icons sab-icon">search</i>Search 3SKYE Documents</div>
					</div>
				</div>
			}
			popupElementClass="sab-options-container"
			popupOuterClass=""
		>
			<EditorButton>
				<input
                    type="file"
                    style={{display: "none"}}
                    onChange={_onLocalFileSelect}
                    ref={uploadRef}
                    multiple
                />
				<Icon>attachment</Icon>
			</EditorButton>
		</Tooltip>
	);
}

export default withStorage(withI18n(SlateAttachmentButton));