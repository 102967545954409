import React, {Component} from 'react';
import {withI18n, withRemote, withStorage} from "@threeskye/global";
import Button from "@threeskye/buttons";
import {testEmail} from './ValidationUtils';
import './ForgotPasswordPage.scss';
import './LoginForm.scss';

import logo from './logo.png';
import CRMErrorMessage from '../Shared/Validation/CRMErrorMessage';

class ForgotPasswordEmail extends Component {

	constructor(props) {
		super(props);

		this.state = {
			username: "",
			invalidUserName: false,
		}

		this.onChangeUsername = this.onChangeUsername.bind(this);
		this.resetBySMS = this.resetBySMS.bind(this);
		this.onAccountRecovery = this.onAccountRecovery.bind(this);
		this.handleKeyPress = this.handleKeyPress.bind(this);
	}


	handleKeyPress(e, field) {
		if (e.key === 'Enter') {
			e.preventDefault();
			if (this.state.username.length > 0) {
					this.onAccountRecovery();
			}
		}
	}

	onChangeUsername(val) {
		this.setState({username: val});
	}

	resetBySMS() {
		this.props.switchPage("password-reset-sms/");
	}

	goBackHome() {
		this.props.switchPage("");
	}

	onAccountRecovery() {
		if (this.state.username === '' || !testEmail(this.state.username)){
			this.setState({ invalidUserName: true })
			return
		} else {
			this.setState({invalidUserName:false})
		}

		const data = {
			username: this.state.username
		}
		this.props.storage.put("username", this.state.username);

		this.props.remote.post("/um/password-recovery-code", data)
			.then (
				x => {
					this.props.storage.put("password_change_key", x.key);

					this.props.switchPage("password-code/");
				}, x => {

				}
			);
	}

	render() {
		let fieldValidationMessage;
		const invalidUserName = this.state.invalidUserName;

		if (this.state.invalidUserName) {
			fieldValidationMessage = "Please enter a valid email address";
		}

		return <div id="master-container">
			<div id="header">
				<div className="logo-container">
					<img src={logo} alt="logo" className="logo" />
				</div>
			</div>
			<div id="content">
				<div id="welcome-message" className="content-wrapper">
					<h1 className="mb-1">Reset password</h1>
					<p>Enter the email address associated with your account, and we&rsquo;ll email you a validation code to reset your password.</p>
				</div>
				<div className="form modal-form">
					<div className="form-row">
						<div className="form-col-grow">
							<CRMErrorMessage isValid={!invalidUserName} shouldShow message={fieldValidationMessage}>
								<label htmlFor="email-address">Email Address</label>
								<input type="text" placeholder="Email address" className="input-field" required onChange={e => this.onChangeUsername(e.target.value)} onKeyPress={e => this.handleKeyPress(e)} />
							</CRMErrorMessage>
						</div>
					</div>
					<div className="form-row mt-3">
						<div className="form-col-grow">
							<Button fullWidth type="secondary" outlined icon="arrow_back" onClick={e=>this.goBackHome()}>Back to login</Button>
						</div>
						<div className="form-col-grow">
							<Button fullWidth icon="email" onClick={this.onAccountRecovery}>Send reset link</Button>
						</div>
					</div>
					<div className="form-row">
						<div className="form-col-grow">
							<div onClick={e=>this.resetBySMS()} className="pseudo-link">Reset password via SMS</div>
						</div>
					</div>
				</div>
			</div>
		</div>;

	}
}

export default withStorage(withRemote(withI18n(ForgotPasswordEmail)));
