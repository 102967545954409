import React from 'react'
import Popup from '../CRMDateRangeInput/components/Popup';
import "./Tooltip.scss"

const Tooltip = ({children, label, placement, display, disabled, delay, className, icon, padding, toggleTooltip, maxWidth, truncate, size, popupElementClass, popupOuterClass, offsetY, offsetX, onClick, closeOnOutsideClick, onOutsideClick, childStyle, popupOuterExtraStyling, stopClickEfect, popupElementStyle, keepOpen, isVisible}) => {
	return (
		<Popup
			className={`${className ? " " + className : ""}${truncate ? " truncate" : ""}`}
			delay={delay}
			hoverOnly={!toggleTooltip}
			disabled={disabled}
			popupElement={
				<div
					className={`tooltip-popup-wrapper${padding ? " padding-" + padding : ""}${size ? " size-" + size : ""}${icon ? " tooltip-with-icon" : ""}`}
					style={{ maxWidth: maxWidth ? maxWidth : "", overflowWrap: maxWidth ? "break-word" : "", ...popupElementStyle }}
				>
					{icon && icon}
					{label}
				</div>
			}
			placement={placement ? placement : "top"}
			display={display ? display : "inline-block"}
			popupElementClass={popupElementClass}
			popupOuterClass={popupOuterClass}
			offsetX={offsetX}
			offsetY={offsetY}
			onClickFunction={onClick && onClick}
			closeOnOutsideClick={closeOnOutsideClick}
			onOutsideClick={onOutsideClick}
			popupOuterStyle={childStyle}
			popupOuterExtraStyling={popupOuterExtraStyling}
			stopClickEfect={stopClickEfect && stopClickEfect}
			// keepOpen={keepOpen}
			isVisible={isVisible}
			>
			{truncate ? <div className="truncate">{children}</div> : children}
		</Popup>
	);
}

export default Tooltip;