import React, { Component } from 'react';
import "./Icons.scss";

class PdfIcon extends Component {
	render() {
		const fillA = { fill: "#C6123D" };
		const fillB = { fill: "#FF6189" };
		const fillC = { fill: "#9F0E30" };
		
		return (
			<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
				<defs></defs>
				<g id="Layer_1" data-name="Layer 1">
					<path
						id="Shape_Copy_22"
						data-name="Shape Copy 22"
						style={fillA}
						d="M20,23.43H6.71a2.14,2.14,0,0,1-2.14-2.14V2.71A2.14,2.14,0,0,1,6.71.57h9.94L22.14,6V21.29A2.14,2.14,0,0,1,20,23.43Z"
					/>
					<path id="Shape_Copy_21" data-name="Shape Copy 21" style={fillB} d="M21.39,6.6H16.17V1.43Z" />
					<rect id="Rectangle_5_Copy_16" data-name="Rectangle 5 Copy 16" style={fillC} x="1.86" y="3.79" width="11.41" height="6.42" rx="2.14" />
					<g id="PDF" className="cls-4">
						<g className="icon-text">
							<path d="M3.53,5.62h1.1a.84.84,0,1,1,0,1.66H4.05v.79H3.53Zm1,1.17c.27,0,.44-.13.44-.34s-.17-.35-.44-.35H4.05v.69Z" />
							<path d="M5.9,5.62h.88A1.27,1.27,0,0,1,8.19,6.84,1.27,1.27,0,0,1,6.78,8.07H5.9Zm.95,2a.75.75,0,1,0,0-1.49H6.43V7.59Z" />
							<path d="M9.13,6.09v.54h1.23V7.1H9.13v1H8.6V5.62h1.89v.47Z" />
						</g>
					</g>
					<path
						id="_"
						data-name=" "
						style={fillB}
						d="M9.76,20.77h0l-.29-.08-.32-.2,0,0a1.16,1.16,0,0,1,0-.18.58.58,0,0,1,0-.19,1.78,1.78,0,0,1,.35-.65,3.36,3.36,0,0,1,.91-.77l.6-.33.66-1.23a9.1,9.1,0,0,0,.42-1c.12-.34.22-.65.3-.95h0a7.94,7.94,0,0,1-.26-1.07,2.75,2.75,0,0,1,.12-1.14.67.67,0,0,1,.19-.35.42.42,0,0,1,.34-.17h.16a.57.57,0,0,1,.22,0,.5.5,0,0,1,.19.13,1.22,1.22,0,0,1,.26,1A9.19,9.19,0,0,1,13.31,15l0,.09a4.75,4.75,0,0,0,.48,1,2.9,2.9,0,0,0,.6.71l.58.39.88-.05a3.45,3.45,0,0,1,1,.12.87.87,0,0,1,.52.36.3.3,0,0,1,.08.16.34.34,0,0,1,0,.2.69.69,0,0,1,0,.25.55.55,0,0,1-.15.19.46.46,0,0,1-.23.16,1.19,1.19,0,0,1-.37.06,4.35,4.35,0,0,1-.95-.13,4.47,4.47,0,0,1-1.08-.38c-.58.07-1.09.14-1.53.22a9.83,9.83,0,0,0-1.19.29l-.13,0a8.32,8.32,0,0,1-1.11,1.58A1.32,1.32,0,0,1,9.76,20.77Zm1-1.83-.32.22a3.84,3.84,0,0,0-.58.63,1.2,1.2,0,0,0-.24.51,1.51,1.51,0,0,0,.52-.42A4.93,4.93,0,0,0,10.79,18.94Zm2.1-3.07c-.08.24-.18.5-.29.77s-.25.55-.4.84l-.34.7.07,0a11.18,11.18,0,0,1,1.24-.4c.39-.1.73-.17,1-.23l-.27-.21a3.39,3.39,0,0,1-.57-.63,4.41,4.41,0,0,1-.46-.82Zm2.81,1.85h-.08l-.07.06a5.83,5.83,0,0,0,.7.25,2.27,2.27,0,0,0,.58.1H17l0,0s0,0,0-.07L17,18a1.09,1.09,0,0,0-.31-.14,3.14,3.14,0,0,0-1-.09Zm-2.87-4.9-.05.07a1.63,1.63,0,0,0-.08.58,3.26,3.26,0,0,0,.13.81l0,0L13,13.7l0-.11a2.25,2.25,0,0,0,0-.44.67.67,0,0,0-.1-.31Z"
					/>
				</g>
			</svg>
		);
	}
}
export default PdfIcon;
