import React, { Component } from 'react';
import "./Icons.scss";
class PptIcon extends Component {
	render(){
		const fillA = { fill: "#E64E23" };
		const fillB = { fill: "#FFA389" };
		const fillC = { fill: "#BA3B18" };
		return (
			<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
				<g id="Layer_1" data-name="Layer 1">
					<path
						id="Shape_Copy_33"
						data-name="Shape Copy 33"
						style={fillA}
						d="M20.15,23.43H6.86a2.14,2.14,0,0,1-2.14-2.14V2.71A2.14,2.14,0,0,1,6.86.57H16.8L22.29,6V21.29A2.14,2.14,0,0,1,20.15,23.43Z"
					/>
					<path id="Shape_Copy_28" data-name="Shape Copy 28" style={fillB} d="M21.54,6.6H16.32V1.43Z" />
					<rect id="Rectangle_5_Copy_26" data-name="Rectangle 5 Copy 26" style={fillC} x="1.71" y="4.01" width="11.82" height="6.02" rx="2.14" />
					<g id="PPT" className="cls-4">
						<g className="icon-text">
							<path d="M4.1,5.7H5.21a.83.83,0,1,1,0,1.66H4.63v.79H4.1ZM5.15,6.87c.26,0,.44-.13.44-.34s-.18-.35-.44-.35H4.63v.69Z" />
							<path d="M6.46,5.7h1.1a.83.83,0,1,1,0,1.66H7v.79H6.46Zm1,1.17c.26,0,.44-.13.44-.34s-.18-.35-.44-.35H7v.69Z" />
							<path d="M9.45,6.18H8.68V5.7h2.06v.48H10v2H9.45Z" />
						</g>
					</g>
					<path style={fillB} d="M13.35,16.26h3.81a3.82,3.82,0,1,1-3.81-3.82h0Z" />
					<path style={fillB} d="M14.06,15.54V11.73a3.82,3.82,0,0,1,3.82,3.81H14.06Z" />
				</g>
			</svg>
		);
	}
}
export default PptIcon;
