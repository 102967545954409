import React, { Component } from 'react';
import { withI18n } from '@threeskye/global';
import ToolTip from '../components/Shared/ToolTip';
import "../components/Shared/FileUpload.scss";

class Progress extends Component {

    render() {
        let uploads = this.props.progress.uploads;

		if (uploads.length === 0)
			return "";

        let i = this.props.i18n.get;

       return <div className="file-upload-outer-container">
            <div className="file-upload-header">
                <div><span>{uploads.length} </span> {i("FileUploading")}</div>
                <i className="material-icons">expand_more</i>
            </div>
            <div className="file-upload-inner-container">
			   {uploads.map((upload, index) => {
				   let uploadClass = "file-upload-progress";
				   if (upload.error) {
					   uploadClass += " invalid-file-type"
					}
					return <div key={index} className={uploadClass}>
						<ToolTip active={upload.error} message={upload.error} className="file-upload-details">
							<div className="file-upload-file-name">{upload.name}: </div>
							<div className="file-upload-percentage">{upload.status}</div>
							<div className="file-upload-cancel" onClick={upload.cancel}><i className="material-icons">close</i></div>
						</ToolTip>
						<div className="file-upload-progress-outer">
							<div className="file-upload-progress-bar" style={{width: upload.progress + "%"}}></div>
						</div>
					</div>
                })}
            </div>
        </div>
    }

}

export default withI18n(Progress);