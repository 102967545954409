import React, { useCallback } from 'react';
import { MaximiseIcon, MinimiseIcon, NewEventIcon } from '../components/SideNav/ActionIcons/ExtensionActionIcons';
import BookmarksExtension from '../components/SideNav/Extensions/BookmarksExtension';
import CreateEventExtension from '../components/SideNav/Extensions/CreateEventExtension';
import DraftsExtension from '../components/SideNav/Extensions/DraftsExtension';
import NotesExtension from '../components/SideNav/Extensions/NotesExtension';
import NotificationsExtension from '../components/SideNav/Extensions/NotificationsExtension';
import SideNavExtension from '../components/SideNav/SideNavExtension';
import { eventTypes } from '../utils/EventUtils';
import { ModalTypes } from '../utils/ModalUtils';
import { isMobile } from '../utils/Utils';

const ExpandSidebar = ({ rightExtExpanded, rightExtExpandedFull, setState, activeExtension, isEmailTemplate }) => {
	return (
		<MaximiseIcon active={rightExtExpanded || rightExtExpandedFull} onClick={() => {
			if (activeExtension === "createEvent" && isEmailTemplate) {
				setState({rightExtExpanded: true, rightExtExpandedFull: ((rightExtExpanded && !rightExtExpandedFull) ? true : false)})
			} else setState({ rightExtExpanded: ((!rightExtExpanded && !rightExtExpandedFull) ? true : false), rightExtExpandedFull: ((rightExtExpanded && !rightExtExpandedFull) ? true : false) })}
		} />
	)
}

const DisplayExtensions = ({ setState, activeExtension, rightExtExpanded, rightExtExpandedFull, modals,
	setActiveExtension, clearUnfinishedEventInExtension, createEventExtensionContent, previousActiveExtension,
	eventTypeToOpen, openUnfinishedEventInExtension, rightSidebarManualHeader, rightSidebarManualComponent, isEmailTemplate }) => {

	const setIsEmailTemplate = useCallback((boolean) => { 
		setState({ isEmailTemplate: boolean }) 
	}, [setState])
	const setEventSidebarExpanded = useCallback(() => {
		setState({ rightExtExpanded: true })
	}, [setState])
	const setEventActiveExtension = useCallback((e) => {
		setActiveExtension(e, null)
	}, [setActiveExtension])
	const clearInitialisationData = useCallback(() => {
		clearUnfinishedEventInExtension()
	}, [clearUnfinishedEventInExtension])
	
	return (
		<>
			{activeExtension === "notifications" && <SideNavExtension
				right
				header="Notifications"
				actions={<>
					{/* <MinimiseIcon onClick={() => setActiveExtension("", "")} /> */}
					<ExpandSidebar rightExtExpanded={rightExtExpanded} rightExtExpandedFull={rightExtExpandedFull} setState={setState} />
				</>}
				content={<NotificationsExtension />}
			/>}
			{
				activeExtension === "notes" && <SideNavExtension
					right
					header="My Notes"
					actions={<>
						<NewEventIcon onClick={() => setActiveExtension("createEvent", "notes", eventTypes[3])} />
						{/* <MinimiseIcon onClick={() => setActiveExtension("", "")} /> */}
						<ExpandSidebar rightExtExpanded={rightExtExpanded} rightExtExpandedFull={rightExtExpandedFull} setState={setState} />
					</>}
					content={<NotesExtension createEvent={() => setActiveExtension("createEvent", "notes", eventTypes.find(t => t.name === "workspace-note"))} openUnfinishedEventInExtension={openUnfinishedEventInExtension} />}
				/>
			}
			{
				activeExtension === "drafts" && <SideNavExtension
					right
					header="Event Drafts"
					actions={<>
						<NewEventIcon onClick={() => setActiveExtension("createEvent", "drafts")} />
						{/* <MinimiseIcon onClick={() => setActiveExtension("", "")} /> */}
						<ExpandSidebar rightExtExpanded={rightExtExpanded} rightExtExpandedFull={rightExtExpandedFull} setState={setState} />
					</>}
					content={<DraftsExtension createEvent={() => setActiveExtension("createEvent", "meeting")} modals={modals} openUnfinishedEventInExtension={openUnfinishedEventInExtension} setActiveExtension={(e, type) => setActiveExtension(e, "drafts", type)} />}
				/>
			}
			{
				activeExtension === "bookmarks" && <SideNavExtension
					right
					header="Bookmarks"
					actions={<>
						{/* <MinimiseIcon onClick={() => setActiveExtension("", "")} /> */}
						<ExpandSidebar rightExtExpanded={rightExtExpanded} rightExtExpandedFull={rightExtExpandedFull} setState={setState} />
					</>}
					content={<BookmarksExtension />}
				/>
			}
			{
				activeExtension === "createEvent" && <SideNavExtension
					right
					header="Create Event"
					actions={<>
						<MinimiseIcon onClick={() => setActiveExtension(isMobile() ? "drafts" : (previousActiveExtension ? previousActiveExtension : "", ""))} />
						<ExpandSidebar rightExtExpanded={rightExtExpanded} rightExtExpandedFull={rightExtExpandedFull} setState={setState} activeExtension={activeExtension} isEmailTemplate={isEmailTemplate}/>
					</>}
					content={<CreateEventExtension 
						clearInitialisationData={clearInitialisationData} 
						initialisationData={createEventExtensionContent ? createEventExtensionContent : ModalTypes.CreateEvent} 
						previousActiveExtension={previousActiveExtension} 
						eventTypeToOpen={eventTypeToOpen} 
						setActiveExtension={setEventActiveExtension}  
						setSidebarExpanded={setEventSidebarExpanded}
						setIsEmailTemplate={setIsEmailTemplate}
						rightExtExpandedFull={rightExtExpandedFull}
						isMobile={isMobile()}
					/>}
					isEmailTemplate={isEmailTemplate}
					isMobile={isMobile()}
				/>
			}
			{
				activeExtension === "manual" && <SideNavExtension
					right
					header={rightSidebarManualHeader}
					actions={<>
						<MinimiseIcon onClick={() => setActiveExtension(isMobile() ? "drafts" : (previousActiveExtension ? previousActiveExtension : "", ""))} />
						<MaximiseIcon active={rightExtExpanded} onClick={() => setState({ rightExtExpanded: !rightExtExpanded })} />
					</>}
					content={rightSidebarManualComponent}
					size="large"
				/>
			}
		</>
	);
}

export default DisplayExtensions;
