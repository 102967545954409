import React, {Component} from 'react';
import {withI18n} from "@threeskye/global";
import logo from './logo.png';

class MaintenanceMode extends Component {

    render() {
        const i = this.props.i18n.get;

        return (
        <div id="master-container">
            <div id="header">
                <div className="logo-container">
                    <img src={logo} alt="logo" className="logo" />
                </div>
            </div>
            <div id="content" className="text-align-center">
                <h1>404: {i("Not_Found")}</h1>
                <p>{i("Not_Found_message")}</p>
            </div>
        </div>
        );
    }
}

export default withI18n(MaintenanceMode);