import React from 'react';
import { isMobile } from '../../utils/Utils';
import "./SideNav.scss";
import SideNavIcon from './SideNavIcon';

const SideNavRight = ({active, handleIconClick, fullScreen}) => {
	return <div id="side-nav-bar" className="right" style={fullScreen ? {display: 'none'} : null}>
		<div id="side-nav-bar-right" className="side-nav-bar-inner side-nav-links-right">
			<SideNavIcon onClick={() => handleIconClick("notifications")} active={active === "notifications"} label="Notifications" icon="notifications" className="d-none-mobile" />
			<SideNavIcon onClick={() => handleIconClick("notes")} active={active === "notes"} label="MyNotes" icon="class" />
			<SideNavIcon onClick={() => handleIconClick("drafts")} active={active === "drafts"} label="Drafts" icon="edit_calendar" />
			<SideNavIcon onClick={() => handleIconClick("bookmarks")} active={active === "bookmarks"} label="Bookmarks" icon="bookmarks" className="d-none-mobile" />
			{isMobile() && <SideNavIcon onClick={() => handleIconClick("createEvent")} active={active === "createEvent"} label="Create Event" icon="add" />}
		</div>
	</div>
}

export default SideNavRight;