import React, { Component } from "react";
import determineNotificationParser from "./NotificationParser";
import Tooltip from "../../../Popups/Tooltip";
import SimplifiedClientAccountToken from "../../../ClientAccount/SimplifiedClientAccountToken"
import AdviserToken from "../../../Tokens/AdviserToken"
import "./NotificationList.scss";

class SingleNotification extends Component {
	constructor(props) {
		super(props);

		const { parseTitle, parseSubtitle } = determineNotificationParser(this.props.data.type);

		this.onCloseClick = this.onCloseClick.bind(this);
		this.parseTitle = parseTitle.bind(this);
		this.parseSubtitle = parseSubtitle.bind(this);
		this.getReviewSubtitle = this.getReviewSubtitle.bind(this)
	}

	onCloseClick(e) {
		const { onClick, data } = this.props;
		e.stopPropagation();
		onClick(data, true);
	}

	getReviewSubtitle(title, subtitle, date) {
		const divStyle = { display: 'flex', flexDirection: 'row', gap: 5, alignItems: 'center' }

		if (title.includes("Peer review")) {
			return (
				<div style={divStyle}>
					<AdviserToken name={subtitle} nameMaxWidth={107} ellipse={true} small={true}/>
					<p className="notification-li-subtitle">{date}</p>
				</div>)
		} else {
			const simplifiedClientAccount = {
				name: subtitle,
				type: "client"
			}
			return (
				<div style={divStyle}>
					<SimplifiedClientAccountToken size="small" tdWidth={109} simplifiedClientAccount={simplifiedClientAccount} />
					<p className="notification-li-subtitle">{date}</p>
				</div>)
		}
	}

	render() {
		const { data, title, subtitle, icon, tooltip, onClick, deleteOnClick, date, pinned, unread, type, onDelete, className } = this.props;

		return (
			<li className={`notification-li${unread ? " notification-unread" : ""}${pinned ? " notification-pinned" : ""}${className ? (" " + className) : ""}`} onClick={() => onClick(data)}>
				{tooltip
					? <Tooltip label={tooltip}>{icon && <i className={"material-icons notification-type-icon " + type}>{icon}</i>}</Tooltip>
					: icon && <i className={"material-icons notification-type-icon " + type}>{icon}</i>
				}
				<div className="notification-li-content">
					<div className="notification-li-title">{this.parseTitle(title)}</div>
					{subtitle !== "" && subtitle !== " " && subtitle !== undefined &&
						<div className={`notification-li-subtitle ${type === "review" ? "review-type" : ""}`}>
							{this.parseSubtitle(type === "review" ? this.getReviewSubtitle(title, subtitle, date) : subtitle)}
						</div>
					}
					{/* <div className="notification-li-date">{isoToDDMMYYYY(data.triggeredDate)}</div> */}
				</div>
				<div className="notification-actions">
					<i className="material-icons delete-icon" onClick={(e) => onDelete(e, data)}>
						delete_forever
					</i>
					{/* <i className={`material-icons pinned-icon`} onClick={() => alert("Pin! 📌")}>
						push_pin
					</i> */}
				</div>
				{deleteOnClick && (
					<i className="material-icons delete-notification" onClick={this.onCloseClick}>
						close
					</i>
				)}
			</li>
		);
	}
}

export default SingleNotification;
