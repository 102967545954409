import React, { Component } from "react";
import SidebarPrompt from "./SidebarPrompt";

class SidebarHeader extends Component {
	render() {
		let headerText = "";

		if(this.props.headerText) {
			headerText = this.props.headerText;
		}
		
		return (
			<div className="sidebar-header">
				<i className="material-icons sidebar-close" onClick={this.props.onClose}>arrow_forward</i>
				<h4>{headerText}</h4>
				{ this.props.promptVisible ? (
					<SidebarPrompt prompt={this.props.promptText} />
				) : ""}
			</div>
		)
	}
}

export default SidebarHeader;
